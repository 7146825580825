.btn {
  // scss-docs-start btn-css-vars
  --#{$prefix}btn-padding-x: #{$btn-padding-x};
  --#{$prefix}btn-padding-y: #{$btn-padding-y};
  --#{$prefix}btn-font-weight: #{$btn-font-weight};
  --#{$prefix}btn-border-radius: #{$btn-border-radius};
  --#{$prefix}btn-border-width: #{$btn-border-width};
  --#{$prefix}btn-line-height: #{$btn-line-height};
  --#{$prefix}btn-focus-box-shadow: #{$btn-focus-box-shadow};
  @include box-shadow(none);
  &:focus-visible {
    color: var(--#{$prefix}btn-color);
    @include gradient-bg(var(--#{$prefix}btn-bg));
    border-color: var(--#{$prefix}btn-border-color);
    &:hover {
      color: var(--#{$prefix}btn-hover-color);
      text-decoration: if($link-hover-decoration == underline, none, null);
      background-color: var(--#{$prefix}btn-hover-bg);
      border-color: var(--#{$prefix}btn-hover-border-color);
    }
  }
  &.active,
  &:active {
    &:focus-visible {
      color: var(--#{$prefix}btn-active-color);
      background-color: var(--#{$prefix}btn-active-bg);
      border-color: var(--#{$prefix}btn-active-border-color);
      outline: 0;
    }
  }
  > i + *,
  > .icon + * {
    margin-left: $spacer * .5;
  }
  > * + i,
  > * + .icon {
    margin-left: $spacer * .5;
  }
}

.btn-icon {
  --#{$prefix}btn-padding-x: #{$spacer * .5};
  --#{$prefix}btn-padding-y: #{$spacer * .5};
  --#{$prefix}btn-color: #{$gray-600};
  --#{$prefix}btn-bg: none;
  --#{$prefix}btn-border-color: none;
  --#{$prefix}btn-border-radius: #{$btn-border-radius};
  --#{$prefix}btn-hover-color: #{$gray-600};
  --#{$prefix}btn-hover-bg: #{$gray-50};
  --#{$prefix}btn-hover-border-color: #{$gray-50};
  --#{$prefix}btn-active-color: #{$interactive-500};
  --#{$prefix}btn-active-bg: #{$interactive-100};
  --#{$prefix}btn-active-border-color: #{$interactive-100};
  --#{$prefix}btn-disabled-color: #{$gray-300};
  width: rfs-value(40px);
  height: rfs-value(40px);
  text-align: center;
  &:hover {
    color: var(--#{$prefix}btn-hover-color);
    text-decoration: if($link-hover-decoration == underline, none, null);
    background-color: var(--#{$prefix}btn-hover-bg);
    border-color: var(--#{$prefix}btn-hover-border-color);
  }
  &.active,
  &:active {
    &:hover {
      color: var(--#{$prefix}btn-active-color);
      background-color: var(--#{$prefix}btn-active-bg);
      border-color: var(--#{$prefix}btn-active-border-color);
    }
    &:focus-visible {
      color: var(--#{$prefix}btn-active-color);
      background-color: var(--#{$prefix}btn-active-bg);
      border-color: var(--#{$prefix}btn-active-border-color);
      @if $enable-shadows {
        box-shadow: var(--#{$prefix}btn-box-shadow), var(--#{$prefix}btn-focus-box-shadow);
      } @else {
        box-shadow: var(--#{$prefix}btn-focus-box-shadow);
      }
    }
  }
  &.btn-sm {
    width: rfs-value(32px);
    height: rfs-value(32px);
    --#{$prefix}btn-padding-x: #{$spacer * .5};
  }
  &.btn-lg {
    width: rfs-value(48px);
    height: rfs-value(48px);
    --#{$prefix}btn-padding-x: #{$spacer * .5};
  }
}

@each $color, $value in $theme-colors {
  .btn-#{$color} {
    @if $color == "interactive" {
      @include button-variant(
        $value,
        $value,
        $hover-background: tint-color($value, $btn-hover-bg-tint-amount),
        $hover-border: tint-color($value, $btn-hover-border-shade-amount),
        $active-background: shade-color($value, $btn-active-bg-shade-amount),
        $active-border: shade-color($value, $btn-active-border-shade-amount),
        $disabled-background: $gray-300,
        $disabled-border: $gray-300,
        $disabled-color: $white
      );
    }
    @else if $color == "primary" or $color == "denim" {
      @include button-variant(
        $value,
        $value,
        $hover-background: tint-color($value, 20%),
        $hover-border: tint-color($value, 20%),
        $active-background: shade-color($value, 40%),
        $active-border: shade-color($value, 40%),
        $disabled-background: $gray-300,
        $disabled-border: $gray-300,
        $disabled-color: $white
      );
    } @else if $color == "success" or $color == "jade" {
      @include button-variant(
        $value,
        $value,
        $color: $denim,
        $hover-background: tint-color($value, 20%),
        $hover-color: $denim,
        $hover-border: tint-color($value, 20%),
        $active-background: shade-color($value, 40%),
        $active-border: shade-color($value, 40%),
        $active-color: $white,
        $disabled-background: $gray-300,
        $disabled-border: $gray-300,
        $disabled-color: $white
      );
    } @else if $color == "danger" {
      @include button-variant(
        $value,
        $value,
        $hover-background: tint-color($value, 20%),
        $hover-border: tint-color($value, 20%),
        $active-background: shade-color($value, $btn-active-bg-tint-amount),
        $active-border: shade-color($value, $btn-active-border-tint-amount),
        $disabled-background: $gray-300,
        $disabled-border: $gray-300,
        $disabled-color: $white
      );
    } @else if $color == "light" or $color == "white" {
      @include button-variant(
        $value,
        $value,
        $color: $denim,
        $hover-background: $electric-50,
        $hover-border: $electric-50,
        $hover-color: $denim,
        $active-background: $electric-200,
        $active-border: $electric-200,
        $active-color: $denim,
        $disabled-background: $gray-300,
        $disabled-border: $gray-300,
        $disabled-color: $white
      );
    } @else {
      @include button-variant(
        $value,
        $value,
        $disabled-background: $gray-300,
        $disabled-border: $gray-300,
        $disabled-color: $white
      );
    }
  }
}

@each $color, $value in $theme-colors {
  .btn-outline-#{$color} {
    @if $color == "light" or $color == "white" {
      @include custom-button-outline-variant(
        $value,
        $hover-background: rgba($value, .12),
        $active-background: $electric-200,
        $active-border: $electric-200,
      );
    }
    @else if $color == "primary" or $color == "denim" {
      @include custom-button-outline-variant(
        $value,
        $active-background: $denim-700,
        $active-border: $denim-700,
      );
    } @else if $color == "success" or $color == "jade" {
      @include custom-button-outline-variant(
        $jade-700,
        $btn-border: $jade-700,
        $hover-background: $jade-100,
        $hover-border: $jade-700,
        $active-background: $jade-700,
        $active-border: $jade-700,
        $active-color: $white,
      );
    } @else if $color == "danger" {
      @include custom-button-outline-variant(
        $value,
        $active-background: $value,
        $active-border: $value,
        $active-color: $white,
      );
    } @else {
      @include custom-button-outline-variant($value);
    }
  }
}


@each $color, $value in $theme-colors {
  .btn-link-#{$color} {
    @if $color == "interactive" or $color == "secondary" or $color == "danger" {
      @include button-link-variant($value);
    } @else if $color == "primary" or $color == "denim" {
      @include button-link-variant(
        $value,
        $active-background: $denim-700,
      );
    } @else if $color == "light" or $color == "white" {
      @include button-link-variant(
        $value,
        $hover-background: rgba($value, .12),
        $active-background: $electric-200,
        $active-color: $denim,
      );
    } @else if $color == "gray" {
      @include button-link-variant(
        $gray-700,
        $hover-background: $gray-50,
        $active-background: $interactive-100,
        $active-color: $interactive-500,
      );
    }
  }
}

.btn-sm {
  @include button-size($btn-padding-y-sm, $btn-padding-x-sm, $btn-font-size-sm, $btn-border-radius-sm);
  > i + *,
  > .icon + * {
    margin-left: $spacer * .375;
  }
  > * + i,
  > * + .icon {
    margin-left: $spacer * .375;
  }
}

.btn-lg {
  @include button-size($btn-padding-y-lg, $btn-padding-x-lg, $btn-font-size-lg, $btn-border-radius-lg);
  > i + *,
  > .icon + * {
    margin-left: $spacer * .625;
  }
  > * + i,
  > * + .icon {
    margin-left: $spacer * .625;
  }
}

.btn-xl {
  @include button-size($btn-padding-y-xl, $btn-padding-x-xl, $btn-font-size-xl, $btn-border-radius-xl);
  > i + *,
  > .icon + * {
    margin-left: $spacer * .75;
  }
  > * + i,
  > * + .icon {
    margin-left: $spacer * .75;
  }
}

.btn-2xl,
.btn-xxl {
  @include button-size($btn-padding-y-xxl, $btn-padding-x-xxl, $btn-font-size-xxl, $btn-border-radius-xxl);
  > i + *,
  > .icon + * {
    margin-left: $spacer * .875;
  }
  > * + i,
  > * + .icon {
    margin-left: $spacer * .875;
  }
}
