.card-slider {
  padding: $spacer * 1.5 0;

  .slider {
    position: relative;

    .slick-list {
      overflow: hidden;
      @include border-radius($border-radius-sm);
    }

    .slick-track {
      display: flex;
    }

    .slider-single {
      position: relative;
      height: inherit;
      overflow: hidden;
      background: $white;
      @include border-radius($border-radius-sm);

      img {
        width: 100%;
        height: 680px;
        object-fit: cover;
      }

      .image-caption {
        position: absolute;
        bottom: 0;
        width: 100%;
        padding: $spacer * 1.25;
        margin: 0;
        font-style: italic;
        color: $denim;
        background: #fffffff2;
        @include font-size(16px);
      }

      h2 {
        @include font-size(32px);
        padding: $spacer * 1.5 $spacer * 1.25 $spacer * 1.25;
        color: $denim;
        text-transform: uppercase;
        border-bottom: rfs-value(1px) solid #b7b7b7;
        @include border-top-radius($border-radius-sm);
      }

      .content {
        padding: $spacer * 1.5 $spacer * 1.25 $spacer * 1.5;
        @include border-bottom-radius($border-radius-sm);

        //stylelint-disable-next-line selector-max-compound-selectors
        p {
          margin: 0;
        }
      }
    }

    .slick-dots {
      position: absolute;
      bottom: -50px;
      left: 50%;
      width: auto;
      transform: translateX(-50%);

      li {
        //stylelint-disable-next-line selector-no-qualifying-type
        &.slick-active {
          //stylelint-disable-next-line selector-max-compound-selectors
          button {
            //stylelint-disable-next-line selector-max-compound-selectors
            &::before {
              @extend .fs-5;
              background-color: $electric;
            }
          }
        }

        &:only-child {
          display: none;
        }

        //stylelint-disable-next-line selector-max-compound-selectors
        button {
          //stylelint-disable-next-line selector-max-compound-selectors
          &::before {
            top: 50%;
            left: 50%;
            width: 12px;
            height: 12px;
            //stylelint-disable-next-line declaration-no-important
            content: "" !important;
            background-color: transparent;
            border: rfs-value(1px) solid $electric;
            @include border-radius(100%);
            //stylelint-disable-next-line declaration-no-important
            opacity: 1 !important;
            transform: translate(-50%, -50%);
          }
        }
      }
    }

    .prev,
    .next {
      position: absolute;
      bottom: -50px;
      display: flex;
      align-items: center;
      cursor: pointer;

      p {
        margin: 0;
      }

      i {
        &::before {
          color: $electric;
        }
      }

      &.slick-disabled {
        pointer-events: none;
        opacity: .3;
      }
    }

    .prev {
      left: 0;

      i {
        margin-right: $spacer * .625;
      }
    }

    .next {
      right: 0;

      i {
        margin-left: $spacer * .625;
      }
    }
  }
}
