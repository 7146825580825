// stylelint-disable function-disallowed-list
.form {
  &.overlap {
    position: relative;
    //stylelint-disable-next-line declaration-no-important
    padding-top: 0 !important;
    margin-top: $spacer * -1.25;
    .container {
      &.float-form {
        // stylelint-disable-next-line selector-class-pattern
        .gform_wrapper {
          @include padding($spacer * 4.5 $spacer * 3.75 $spacer * 3);
        }
      }
    }
  }
  &.has-image-bg {
    background-repeat: no-repeat;
    background-position: center right;
    background-size: cover;
  }

  &.has-bg {
    //stylelint-disable-next-line declaration-no-important
    padding-top: 0 !important;
    @include padding-bottom($spacer * 3.75 !important);

    .container {
      background-color: $white;
      @include border-radius($border-radius-xxl);
      @include padding-top($spacer * 7.1875);
      @include padding-bottom($spacer * 5.5);
    }

    // stylelint-disable-next-line selector-class-pattern
    .gform_title {
      width: 70%;
      color: $denim;
      @include margin(0 auto $spacer * 1.5);
    }

    //stylelint-disable-next-line selector-no-qualifying-type
    button[type="submit"] {
      display: block;
      width: fit-content;
      margin: 0 auto;
    }
  }

  &.lazy-bg {
    background-position: center;
    background-size: cover;
  }

  .container {
    // stylelint-disable-next-line selector-class-pattern
    .gform_wrapper {
      @include make-row();
      justify-content: center;
    }

    &.float-form {
      background-color: unset;
      // stylelint-disable-next-line selector-class-pattern
      .gform_wrapper {
        text-align: center;
        background-color: $white;
        @include border-radius($border-radius-xxl);
        @include margin(0 $spacer * 6.25);
        @include padding($spacer * 6.25 $spacer * 3.75);
        @include media-breakpoint-down(md) {
          @include margin(0);
        }

        // stylelint-disable-next-line selector-class-pattern
        .gform_heading,
        form {
          width: 100%;
        }

        // stylelint-disable-next-line selector-class-pattern
        .gform_title {
          @include font-size(40px);
        }

        // stylelint-disable-next-line selector-class-pattern
        .ginput_address_zip {
          @include margin-top(0 !important);
          width: 100%;
        }
      }
    }

    &.end {
      // stylelint-disable-next-line selector-class-pattern
      .gform_wrapper {
        @extend .justify-content-end;
      }
    }

    &.start {
      // stylelint-disable-next-line selector-class-pattern
      .gform_wrapper {

        @extend .justify-content-start;
      }
    }

    // stylelint-disable-next-line selector-class-pattern
    .gform_heading {
      text-align: center;
    }

    &.end,
    &.start, {
      // stylelint-disable-next-line selector-class-pattern
      .gform_heading {
        text-align: left;
        @include make-col(12);

        h2 {
          @include font-size(32px);
        }
      }

      // stylelint-disable-next-line selector-class-pattern
      .gform_wrapper {
        @include make-col-ready();
        @include make-col(6);
        background-color: $white;
        @include border-radius($border-radius-xxl);
        @include padding($spacer * 3);
        @include media-breakpoint-down(xl) {
          @include make-col(10);
          margin: 0 auto;
        }
        @include media-breakpoint-down(md) {
          @include make-col(12);
          margin: 0 auto;
        }
      }

      form {
        @include make-col(12);

      }
    }
  }

  // stylelint-disable-next-line selector-class-pattern
  .gform_heading {
    @include make-col-ready();
    @include make-col(8);
    @include media-breakpoint-down(md) {
      @include make-col(12);
    }

    h2 {
      @extend .display-3;
      @include margin-bottom($spacer * 1.5);
    }

    p {
      margin-bottom: 0;
    }
  }

  form {
    @include make-col-ready();
    @include make-col(6);
    @include margin-top($spacer * 2.5);
    @include media-breakpoint-down(lg) {
      @include make-col(8);
    }
    @include media-breakpoint-down(md) {
      @include make-col(12);
    }
  }

  // stylelint-disable-next-line selector-class-pattern
  .gfield_radio {
    .form-check {
      display: inline-block;
      width: calc(33.33333% - 3px);
      @include media-breakpoint-down(lg) {
        width: 100%;
      }
    }
  }

  .legal {
    @include margin-top($spacer * 2.5);
    color: $gray-700;
    text-align: center;
  }

  .legend-space {
    legend {
      @include margin-bottom($spacer * 1.5);
    }
  }

  // stylelint-disable-next-line selector-class-pattern
  .gform-button--width-full {
    display: block;
    width: fit-content;
    margin: 0 auto;
    @include padding($spacer * .6875 $spacer * 3.53125);
    @include media-breakpoint-down(sm) {
      width: 100%;
    }
  }

  .gsection {
    height: rfs-value(1px);
    background-color: $gray-200;

    h3 {
      @extend .d-none;
    }
  }

  // stylelint-disable-next-line selector-class-pattern
  .gfield--type-total {
    label {
      display: inline;
      @include margin-right($spacer * .5);
    }

    // stylelint-disable-next-line selector-class-pattern
    .ginput_container_total {
      display: inline-block;
    }

    input {
      font-weight: $font-weight-bold;
      color: $jade-600;
      border: 0;
      outline: 0;
    }
  }

  &.qualify-form {
    //stylelint-disable-next-line selector-class-pattern
    .gform_confirmation_wrapper {
      display: none;
    }
    .results-column {
      text-align: center;
      background-color: $white;
      @include border-radius($border-radius-xxxl);
      @include padding($spacer * 5);
      img {
        display: block;
        width: auto;
        height: 48px;
        @include margin(0 auto $spacer * 1.5);
      }
      .result-text {
        @extend .fs-4;
        @include margin-bottom($spacer * 2);
      }
      .result-disclaimer {
        @include margin-top($spacer * 4);
        text-align: center;
        p {
          margin-bottom: 0;
        }
      }
    }
    .disclaimer-holder {
      @include margin-top($spacer * 2);
    }
  }
}
