//stylelint-disable selector-no-qualifying-type
input::-webkit-date-and-time-value,
input::-webkit-datetime-edit {
  display: flex;
  color: $black;
  text-align: left;
}
//stylelint-disable
input[type="date"]:empty:not(.has-value)::before {
  position: absolute;
  display: flex;
  color: $gray-600;
  content: attr(placeholder);
}
@include media-breakpoint-up(lg) {
@supports (-webkit-hyphens:none) {
  input[type="date"]:empty:not(.has-value)::before {
    content: "";
  }
}
  }

@media all and (-webkit-min-device-pixel-ratio:0) and (min-resolution: .001dpcm) and (not (pointer: coarse)) and (not (hover: none)) {
  input[type="date"]:empty:not(.has-value)::before {
    content: "";
  }
}

@supports (-webkit-overflow-scrolling: touch) {
  input[type="date"]:empty:not(.has-value)::before {
    content: attr(placeholder);
  }
}
