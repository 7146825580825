.bulleted-list {
  padding: $spacer * 1.5 0;

  .bulleted-list-content {
    @include border-radius($border-radius-sm);

    &.takeaway {
      padding: $spacer * 2.5;
      background-color: $white;
      border-top: $spacer * .5 solid $electric;

      @include media-breakpoint-down(sm) {
        padding: $spacer * 1.25;
      }

      h2 {
        padding-left: $spacer * .625;
        margin-bottom: $spacer * 2.5;
        margin-left: $spacer * 3.125;
        color: $denim;
        text-transform: uppercase;

        @include media-breakpoint-down(sm) {
          padding-top: $spacer * 3.125;
          padding-left: 0;
          margin-bottom: $spacer * .875;
          margin-left: 0;

          &::before {
            //stylelint-disable-next-line declaration-no-important
            top: 0 !important;
            //stylelint-disable-next-line declaration-no-important
            left: 0 !important;
            //stylelint-disable-next-line declaration-no-important
            transform: none !important;
          }
        }

        &::before {
          position: absolute;
          top: 50%;
          left: $spacer * -3.125;
          width: $spacer * 2.25;
          height: $spacer * 2.25;
          content: "";
          background-image: url("../images/takeaway.svg");
          background-repeat: no-repeat;
          background-size: cover;
          transform: translateY(-50%);
        }
      }
    }

    &.simple {
      padding: $spacer * 2.5;
      border: rfs-value(1px) solid $electric;

      @include media-breakpoint-down(sm) {
        padding: $spacer * 1.25;
      }
      h2 {
        @include font-size(20px);
        margin-bottom: $spacer * .875;
        color: $denim;
        text-transform: uppercase;
        letter-spacing: normal;
      }

      .list {
        margin-bottom: 0;
        li {
          margin-bottom: $spacer * .875;

          //stylelint-disable-next-line selector-max-compound-selectors
          p {
            //stylelint-disable-next-line
            span {
              color: $denim;
            }
          }
        }
      }
    }

    &.checklist {
      .list {
        padding-left: $spacer * 2.125;

        li {
          &::before {
            top: 5px;
            left: -40px;
            width: 30px;
            height: 30px;
            background-color: transparent;
            background-image: url("../images/checkmark.svg");
            background-size: cover;
          }
        }
      }
    }

    &.white-bg {
      background-color: $white;
      border: 0;
    }

    h2 {
      position: relative;
      margin-bottom: $spacer * 1.25;
    }

    .description {
      margin-bottom: $spacer * 1.5;
    }

    .list {
      padding-left: $spacer * 1.5;
      list-style-type: none;

      li {
        position: relative;
        margin-bottom: $spacer * 1.5;

        &:last-child {
          margin-bottom: 0;
        }

        &::before {
          position: absolute;
          top: 10px;
          left: -30px;
          width: 13px;
          height: 13px;
          content: "";
          background-color: $denim;
          @include border-radius(1px);
        }

        //stylelint-disable-next-line selector-max-compound-selectors
        p {
          margin-bottom: 0;
          //stylelint-disable-next-line
          span {
            margin-bottom: 0;
            font-weight: $font-weight-bold;
          }
        }
      }
    }
  }
}
