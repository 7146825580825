.three-icon-text {
  text-align: center;

  .section-content {
    @include margin-bottom($spacer * 4);

    p {
      @include margin-top($spacer * 1.5);
    }
  }

  .tiles {
    background-color: $white;
    @include border-radius($border-radius-xxxl);
    @include padding($spacer * 3);
    @include media-breakpoint-down(sm) {
      --#{$prefix}gutter-x: 0;
    }

    .tile-holder {
      text-align: center;
      border-right: rfs-value(1px) solid $dove-400;
      @include media-breakpoint-down(md) {
        border-right: 0;
        border-bottom: rfs-value(1px) solid $dove-400;
      }

      &:first-child {
        @include padding-right($spacer * 3);
        @include media-breakpoint-down(md) {
          // stylelint-disable-next-line function-disallowed-list
          padding-right: calc(var(--#{$prefix}gutter-x) * .5);
          @include padding-bottom($spacer * 3);
        }
      }

      &:not(:last-child):not(:first-child) {
        @include padding(0 $spacer * 3);
        @include media-breakpoint-down(md) {
          // stylelint-disable-next-line function-disallowed-list
          padding-right: calc(var(--#{$prefix}gutter-x) * .5);
          // stylelint-disable-next-line function-disallowed-list
          padding-left: calc(var(--#{$prefix}gutter-x) * .5);
          @include padding-top($spacer * 3);
          @include padding-bottom($spacer * 3);
        }
      }

      &:last-child {
        border-right: 0;
        @include padding-left($spacer * 3);
        @include media-breakpoint-down(md) {
          // stylelint-disable-next-line function-disallowed-list
          padding-left: calc(var(--#{$prefix}gutter-x) * .5);
          border-bottom: 0;
          @include padding-top($spacer * 3);
        }
      }

      .icon-container {
        @include margin-bottom($spacer * 2);
        // stylelint-disable-next-line selector-max-compound-selectors
        i {
          font-size: rfs-value(48px);
        }
        //stylelint-disable-next-line selector-max-compound-selectors
        img {
          display: block;
          max-height: 24px;
          margin: 0 auto;
        }
      }

      h4 {
        @include margin-bottom($spacer);
      }

      p {
        margin-bottom: 0;
      }
    }
  }

  .legal {
    @include margin-top($spacer * 3);
    margin-bottom: 0;
  }

  &.section-overlap {
    position: relative;
    //stylelint-disable-next-line declaration-no-important
    padding-top: 0 !important;
    margin-top: -$spacer * 3;
  }
}
