.blog-video {
  padding: $spacer * 1.5 0;

  .blog-video-content {

    &.fullwidth,
    &.smaller {
      .video {
        &.embed-container {
          position: relative;
          max-width: 100%;
          height: auto;
          padding-bottom: 56.25%;
          overflow: hidden;
          @include border-radius($border-radius-sm);
        }

        &.embed-container iframe,
        &.embed-container object,
        &.embed-container embed {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }
    }

    &.smaller {
      .video {
        width: 70%;
        margin: 0 auto $spacer * 1.5;

        @include media-breakpoint-down(sm) {
          width: 100%;
        }
      }
    }

    &.textwrapped {
      .video {
        float: right;
        padding: 0 0 $spacer * 1.5 $spacer * 1.5;
        margin: 0;

        @include media-breakpoint-down(md) {
          float: none;
          margin-bottom: $spacer * 1.5;
        }

        &.embed-container {
          @include media-breakpoint-down(md) {
            position: relative;
            max-width: 100%;
            height: auto;
            padding-bottom: 56.25%;
            overflow: hidden;
          }
        }

        &.embed-container iframe,
        &.embed-container object,
        &.embed-container embed {
          @include media-breakpoint-down(md) {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
          }
        }

        iframe {
          width: 550px;
          height: 350px;
          @include border-radius($border-radius-sm);
        }
      }
    }

    .video {
      margin-bottom: $spacer * 1.5;
    }

    .content {
      p {
        margin-bottom: $spacer * 1.5;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}
