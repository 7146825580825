.downloadable-file {
  padding: $spacer * 1.5 0;

  &.newsletter {
    .downloadable-file-content {
      padding: $spacer * 1.5 $spacer * 2.25 $spacer * 2.75;

      @include media-breakpoint-down(md) {
        padding-bottom: $spacer 1.5;
      }
    }
  }

  &.free-badge {
    padding-top: $spacer * 5;

    @include media-breakpoint-down(sm) {
      padding-top: $spacer * 3.125;
    }

  }

  .downloadable-file-content {
    padding: $spacer * 1.5 $spacer * 1.5 $spacer * 2.5;
    background-color: $electric-50;
    @include border-radius($border-radius-sm);

    @include media-breakpoint-down(md) {
      padding-bottom: $spacer * 2;
    }

    .text {
      padding-top: $spacer * 1.5;
      padding-bottom: $spacer * 1.5;
      text-align: center;

      @include media-breakpoint-down(md) {
        order: 2;
        padding-bottom: 0;
      }

      .text-content {
        padding-left: $spacer * 3.125;

        @include media-breakpoint-down(md) {
          padding-left: 0;
        }
        //stylelint-disable-next-line selector-max-compound-selectors
        h2 {
          @include font-size(40px);
          margin-bottom: $spacer * 1.25;
        }

        //stylelint-disable-next-line selector-max-compound-selectors
        a {
          width: 175px;
          //stylelint-disable-next-line declaration-no-important
          padding-right: $spacer !important;
          //stylelint-disable-next-line declaration-no-important
          padding-left: $spacer !important;
          margin-top: $spacer;
          //stylelint-disable-next-line declaration-no-important
          font-weight: $font-weight-light !important;
          //stylelint-disable-next-line declaration-no-important
          color: $white !important;
        }

        //stylelint-disable-next-line selector-max-compound-selectors
        .form {
          padding-top: $spacer;
          //stylelint-disable-next-line
          .gform_wrapper {
            margin: 0;
            //stylelint-disable-next-line
            form {
              display: flex;
              gap: $spacer;
              align-items: center;
              justify-content: center;

              @include media-breakpoint-down(sm) {
                flex-direction: column;
                gap: $spacer * .625;
              }
              //stylelint-disable-next-line
              .gform_body {
                flex: auto;

                @include media-breakpoint-down(sm) {
                  width: 100%;
                }
                //stylelint-disable-next-line
                .gform_fields {
                  display: block;
                  //stylelint-disable-next-line declaration-no-important
                  margin: 0 !important;
                  //stylelint-disable-next-line
                  .gfield {
                    margin: 0;
                    //stylelint-disable-next-line
                    input {
                      @extend .fs-1;
                      height: auto;
                      //stylelint-disable-next-line declaration-no-important
                      padding: $spacer !important;
                      //stylelint-disable-next-line declaration-no-important
                      font-weight: $font-weight-light !important;
                      @include media-breakpoint-down(sm) {
                        text-align: center;
                      }
                    }

                    //stylelint-disable-next-line
                    label {
                      display: none;
                    }
                  }
                }
              }

              //stylelint-disable-next-line
              .gform_footer {
                flex: 1;
                padding: 0;

                @include media-breakpoint-down(sm) {
                  width: 100%;
                }

                //stylelint-disable-next-line
                button[type="submit"] {
                  @extend .fs-1;
                  width: 100%;
                  //stylelint-disable-next-line declaration-no-important
                  padding: $spacer * .875 !important;
                  //stylelint-disable-next-line declaration-no-important
                  font-weight: 100 !important;
                  //stylelint-disable-next-line declaration-no-important
                  color: $white !important;
                }
              }
            }
          }
        }
      }
    }

    .photo {
      @include media-breakpoint-down(md) {
        order: 1;
      }

      .image {
        position: relative;
        z-index: 1;
        width: fit-content;
        margin: 0 auto;
        text-align: center;
        //stylelint-disable-next-line selector-max-compound-selectors
        .free-badge {
          position: absolute;
          top: $spacer * -5;
          right: $spacer * -4;
          z-index: -1;
          width: 140px;
          height: 140px;
          background-image: url("../images/free-badge.png");
          background-repeat: no-repeat;
          background-size: contain;

          @include media-breakpoint-down(sm) {
            top: $spacer * -3.5;
            right: $spacer * -3.125;
            width: 100px;
            height: 100px;
          }
        }

        //stylelint-disable-next-line selector-max-compound-selectors
        img {
          width: 180px;
          @include border-radius($border-radius);

          @include media-breakpoint-down(md) {
            height: auto;
          }

          @include media-breakpoint-down(sm) {
            width: 150px;
          }
        }
      }
    }
  }
}
