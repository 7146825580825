.link-tiles {
  h1 {
    text-align: center;
    @include margin-bottom($spacer * 1.5);
  }

  p {
    font-size: $font-size-lg;
    @include margin-bottom($spacer * 4.5);
    text-align: center;
  }

  ul {
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
  }

  .legal {
    @include margin-top($spacer * 3);
    margin-bottom: 0;
  }

  .row.even:not(:first-child) {
    @include margin-top($spacer * 1.5);
  }

  .tile {
    align-items: center;
    @include make-col-ready();
    @include media-breakpoint-up(xl) {
      @include make-col(4);
    }
    @include media-breakpoint-down(lg) {
      @include make-col(6);
    }
    @include media-breakpoint-down(md) {
      @include make-col(8);
    }
    @include media-breakpoint-down(sm) {
      @include make-col(12);
    }

    &:nth-child(n+4) {
      @include margin-top($spacer * 1.5);
    }

    a,
    div {
      display: block;
      align-items: center;
      width: 100%;
      font-size: rfs-value(18px);
      font-weight: $font-weight-bold;
      color: $gray-900;
      text-decoration: none;
      background-color: $white;
      border: rfs-value(2px) solid $white;
      @include border-radius($border-radius-xxxl);
      @include padding($spacer * 2.5);
      @include box-shadow(0 rfs-value(2px) rfs-value(32px) rgba($black, .02));
      @include transition(box-shadow .15s ease-in-out, border-color .15s ease-in-out);

      span {
        word-wrap: anywhere;
        //stylelint-disable-next-line selector-max-type, selector-max-compound-selectors
        span {
          display: block;
          font-size: $font-size-sm;
          font-weight: $font-weight-medium;
          //stylelint-disable-next-line selector-max-type, selector-max-compound-selectors, selector-no-qualifying-type
          &.linked {
            font-weight: $font-weight-bold;
            color: $interactive;
          }

          //stylelint-disable-next-line selector-max-type, selector-max-compound-selectors, selector-no-qualifying-type
          &.non-linked {
            font-weight: $font-weight-normal;
          }
        }
      }

      &:hover {
        border-color: $interactive;
        @include box-shadow($box-shadow-xxxl);
      }

      i {
        font-size: rfs-value(24px);
        color: $interactive;
        @include margin-right($spacer * 2);
      }

      img {
        max-height: 24px;
        @include margin-right($spacer * 2);
      }
    }

    &.long-content {
      div {
        align-items: baseline;

        i {
          position: relative;
          top: $spacer * .5;
        }
      }
    }

    &:not(:last-child) {
      @include media-breakpoint-down(xl) {
        @include margin-bottom($spacer * 1.5);
      }
    }
  }
}

//stylelint-disable-next-line selector-no-qualifying-type
body.page-contact-us {
  //stylelint-disable-next-line selector-no-qualifying-type
  .tile {
    @include media-breakpoint-up(lg) {
      @include make-col(6);
    }
    @include media-breakpoint-down(md) {
      @include make-col(12);
    }

    &:not(:last-child) {
      @include margin-bottom($spacer * 1.5);
    }
  }
}
