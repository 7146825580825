.page-get-started {
  .lead-form {
    position: relative;
  }
  .contact-phone input {
    @include media-breakpoint-up(md) {
      margin-top: $spacer * 2;
    }
  }
}
