.description-holder {
  nav {
    @include margin-bottom($spacer * 2);
  }
  h1 {
    @include margin-bottom($spacer * 1.5);
  }
  a {
    @include margin-top($spacer);
  }
}
