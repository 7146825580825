.breadcrumb {
  @extend .fs-4;
  font-weight: $font-weight-medium;
  .breadcrumb-item {
    color: $interactive;
    a {
      text-decoration: none;
    }
    &::before {
      color: $interactive;
    }
    &.current {
      color: $gray-500;
    }
  }
}
