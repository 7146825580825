.accordion-block {
  h1 {
    text-align: center;
    @include margin-bottom($spacer * 4);
  }

  .btn {
    display: block;
    width: fit-content;
    @include margin($spacer * 4 auto 0);
  }

  .legal-holder {
    .legal {
      text-align: center;
      @include padding($accordion-padding);
      @include margin-top($spacer);
    }
  }
}

