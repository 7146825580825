.modal-dialog-form {
  h1,
  p {
    text-align: center;
  }

  h1 {
    @include margin-bottom($spacer * 1.5);
  }

  p:not(.legal) {
    @include margin-bottom($spacer * 2);
  }

  // stylelint-disable-next-line selector-class-pattern
  .gform_heading {
    text-align: center;
    @include margin-bottom($spacer * 2);

    h2 {
      @extend .h1;
      @include margin-bottom($spacer * 1.5);
    }

    p {
      margin-bottom: 0;
    }
  }

  // stylelint-disable-next-line selector-class-pattern
  .gform-button--width-full {
    display: block;
    width: fit-content;
    margin: 0 auto;
    @include padding($spacer * .6875 $spacer * 3.53125);
    @include media-breakpoint-down(sm) {
      width: 100%;
    }
  }
}

.modal-dialog-mixed {
  .closing-copy {
    margin-bottom: 0;
  }
}

.modal-button-holder {
  color: $interactive;
  text-align: center;

  > i + *,
  > .icon + * {
    margin-left: $spacer * .5;
  }

  > * + i,
  > * + .icon {
    margin-left: $spacer * .5;
  }

  .modal-link {
    position: relative;
    display: inline-block;
    width: fit-content;
    padding: 0;
    overflow: hidden;
    color: $interactive;
    text-decoration: none;
    background: none;
    border: 0;

    &::after {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: rfs-value(1px);
      content: "";
      background-color: $interactive;
      opacity: 1;
    }

    &:hover {
      &::after,
      &:focus {
        opacity: 1;
        transform: translate3d(0, 0, 0);
      }
    }
  }
}
