// stylelint-disable selector-class-pattern
// stylelint-disable selector-no-qualifying-type
.gform_wrapper {
  margin-bottom: $spacer;
  text-align: left;

  /* Layout
  -------------------------------------------------------------- */
  .gform_fields {
    @extend .row;

    .gsection {
      @include margin-top($spacer !important);
      @include margin-bottom($spacer * 2 !important);
    }

    .gfield {
      @include make-col-ready();
      margin-top: 0;
      @extend .mb-3;

      &.after {
        display: none;
      }
    }

    //.age {
    //  //stylelint-disable-next-line
    //  .text-danger.small.validation_message {
    //    //stylelint-disable-next-line
    //    font-size: $spacer * .875 !important;
    //  }
    //}
    //
    //.large.first_name {
    //  //stylelint-disable-next-line
    //  .name_first.col-md-6 {
    //    //stylelint-disable-next-line
    //    width: 100% !important;
    //  }
    //}
    //
    //.large.last_name {
    //  //stylelint-disable-next-line
    //  .name_last.col-md-6 {
    //    //stylelint-disable-next-line
    //    width: 100% !important;
    //  }
    //}

    .ginput_container_address {
      .gform-grid-col:not(:first-child) {
        @extend .mt-3;
      }
    }

    .gfield--width-quarter {
      @include media-breakpoint-up(sm) {
        @include make-col(6);
      }
      @include media-breakpoint-up(md) {
        @include make-col(3);
      }
    }

    .gfield--width-third {
      @include media-breakpoint-up(md) {
        @include make-col(4);
      }
    }

    .gfield--width-five-twelfths {
      @include media-breakpoint-up(md) {
        @include make-col(5);
      }
    }

    .gfield--width-half {
      @include media-breakpoint-up(md) {
        @include make-col(6);
      }
    }

    .gfield--width-seven-twelfths {
      @include media-breakpoint-up(md) {
        @include make-col(7);
      }
    }

    .gfield--width-two-thirds {
      @include media-breakpoint-up(md) {
        @include make-col(8);
      }
    }

    .gfield--width-three-quarter {
      @include media-breakpoint-up(md) {
        @include make-col(9);
      }
    }

    .gfield--width-five-sixths {
      @include media-breakpoint-up(md) {
        @include make-col(10);
      }
    }

    .gfield--width-eleven-twelfths {
      @include media-breakpoint-up(md) {
        @include make-col(11);
      }
    }

    .gfield--width-full {
      @include media-breakpoint-up(md) {
        @include make-col(12);
      }
    }
  }

  .form-select.empty {
    color: $gray-600;
  }

  /* Heading
  -------------------------------------------------------------- */
  .gform_heading {
    .gform_description {
      display: block;

      &.instruction {
        display: none;
      }

      &.validation_message {
        display: none;
      }
    }

    .gform_required_legend {
      @extend .fs-4;
      display: none;
    }
  }

  &.show-required_wrapper {
    .gform_heading {
      .gform_required_legend {
        display: block;
      }
    }
  }

  .gform_body {
    margin-bottom: 1rem;
  }

  // Custom Ajax Spinner.
  @keyframes ajax-spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .form-label {
    font-size: $font-size-base;
    font-weight: $font-weight-medium;
  }

  .field_sublabel_above,
  .field_sublabel_below {
    .gform-field-label--type-sub {
      font-size: $font-size-sm;
    }
  }

  .hidden_label {
    .form-label {
      @extend .visually-hidden;
    }

    &.field_sublabel_above {
      .gform-field-label--type-sub {
        margin-bottom: $spacer * .5;
        font-size: $font-size-base;
        font-weight: $font-weight-medium;
      }
    }

    &.field_sublabel_below {
      .gform-field-label--type-sub {
        font-size: $font-size-sm;
      }
    }
  }

  .field_sublabel_hidden_label {
    .gform-field-label--type-sub {
      @extend .visually-hidden;
    }
  }

  .gform_ajax_spinner {
    @extend .spinner-border;
    @extend .spinner-border-sm;
    margin-left: $spacer;

    background-color: $interactive;
  }

  // Hidden
  .gform_validation_container, // anti-spam honeypot
  .gform_hidden,
  .gfield_visibility_hidden,
  .gfield--type-aqJornayaHiddenField,
  .gfield--type-aqGoogleAnalytics {
    @extend .visually-hidden;
    @extend .d-none;
  }

  // Validation
  .gfield_required {
    color: $danger;
  }

  .gform_validation_errors {
    @extend .alert;
    @extend .alert-danger;
    font-size: $font-size-sm;

    .gform_submission_error {
      font-size: $font-size-sm;
    }

    ol {
      margin: 0;
    }

    .gform_validation_error_link {
      @extend .alert-link;
      font-weight: $font-weight-normal;
    }
  }

  .gform_validation_warning {
    @extend .alert;
    @extend .alert-warning;
    font-size: $font-size-sm;

    .gform_submission_warning {
      font-size: $font-size-sm;
    }

    ol {
      margin: 0;
    }

    .gform_validation_warning_link {
      @extend .alert-link;
      font-weight: $font-weight-normal;
    }
  }

  .validation-button {
    display: block;
    width: fit-content;
    margin: 0 auto $spacer;
  }

  select[aria-invalid="true"],
  textarea[aria-invalid="true"],
  input[aria-invalid="true"] {
    border-color: rgba($form-feedback-invalid-color, 1);
  }

  .gfield_error {
    input {
      border-color: rgba($form-feedback-invalid-color, 1);
    }
  }
}

.gform_anchor::before {
  display: block;
  width: 0;
  height: 50px;
  margin: -50px 0 0;
  content: "";
}

/* Widget
-------------------------------------------------------------- */
.gform_widget {
  textarea,
  input[type="text"],
  input[type="email"],
  input[type="url"],
  input[type="search"],
  input[type="number"],
  input[type="tel"] {
    @extend .form-control-sm;
  }

  .gform_button {
    @extend .btn-sm;
  }
}

/* Reset :disabled when viewing the form with shortcode preview
-------------------------------------------------------------- */
.mce-content-body {
  .form-control,
  .form-select {
    &:disabled {
      background-color: $input-bg;
    }
  }
}

/* Confirmation
-------------------------------------------------------------- */
.gform_confirmation_wrapper {
  @extend .alert;
  @extend .alert-secondary;
}

.small {
  .form-check {
    @extend .form-check-sm;
  }

  .ginput_container_address {
    .form-control {
      @extend .form-control-sm;
    }

    .form-select {
      @extend .form-control-sm;
    }
  }

  .ginput_container_name {
    .form-control {
      @extend .form-control-sm;
    }
  }
}

.small-description {
  .gfield_description:not(.text-danger) {
    @extend .fs-5;
  }
}

.large {
  .ginput_container_address {
    .form-control {
      @extend .form-control-lg;
    }

    .form-select {
      @extend .form-control-lg;
    }
  }

  .ginput_container_name {
    .form-control {
      @extend .form-control-lg;
    }
  }

  .ginput_container_date {
    .form-control {
      @extend .form-control-lg;
    }
  }
}

.normal-sub-label {
  .gform-field-label--type-sub {
    @extend .fs-3;
    font-weight: $font-weight-medium;
  }
}

.encapsulated {
  &.large {
    .form-check {
      @extend .form-check-encapsulated-lg;
    }
  }

  .form-check {
    @extend .form-check-encapsulated;
  }
}


.single-address {
  .ginput_container_address {
    .address_line_2,
    .address_city,
    .address_state,
    .address_zip {
      display: none;
    }
  }
}

.ginput_complex {
  span:first-child {
    @include media-breakpoint-down(md) {
      margin-bottom: 0;
    }
  }
}

.instruction.validation_message {
  margin-top: $spacer * .25;
  margin-left: $spacer * .25;
  font-size: $font-size-sm;
  color: $gray-600;
}

.small-submit {
  button[type="submit"] {
    @extend .btn-sm;
  }
}

.large-submit {
  button[type="submit"] {
    @extend .btn-lg;
  }
}

.center-submit {
  button[type="submit"] {
    display: block;
    width: 200px;
    margin: 0 auto;
  }
}

.xl-submit {
  button[type="submit"] {
    @extend .btn-xl;
  }
}

.arrow-right-submit {
  button[type="submit"] {
    &::after {
      @extend %fa-icon;
      @extend .fa-regular;
      margin-left: $spacer * .5;
      content: fa-content($fa-var-arrow-right);
    }
  }
}

.outline-white-submit {
  button[type="submit"] {
    @extend .btn-outline-light;
    background-color: transparent;

    &:hover {
      background-color: var(--bs-btn-hover-bg);
    }
  }
}

.hide-asterisk,
.hide-asterisk_wrapper {
  .gfield_required_asterisk {
    @extend .visually-hidden;
  }
}

.full-width {
  .col-md-6 {
    @include make-col(12);
  }
}

.form-select.ts-wrapper {
  &.form-select-sm {
    .ts-control {
      @extend .form-select-sm;
    }
  }

  &.form-select-lg {
    .ts-control {
      @extend .form-select-lg;
    }
  }

  //&.focus .ts-control {
  //  box-shadow: none;
  //}

  .ts-control {
    input {
      &::placeholder {
        color: $input-placeholder-color;
        opacity: 1;
      }
    }
  }

  .ts-dropdown {
    @include border-radius($border-radius-lg);
    border: rfs-value(1px) solid $gray-100;
    @include box-shadow($box-shadow);

    .ts-dropdown-content {
      @include padding($spacer * .5);

      .option {
        @include padding($spacer * .5 $spacer);
        font-size: $font-size-sm;
        font-weight: $font-weight-medium;
        color: $denim;
        @include transition(color .15s ease-in-out, background-color .15s ease-in-out);
        //stylelint-disable-next-line selector-max-class
        &.active {
          color: $interactive;
          background-color: $interactive-50;
          @include border-radius($border-radius-sm);
        }
      }
    }
  }
}

.gform_page_footer {

  .gform_next_button {
    float: right;
    width: fit-content;

    &::after {
      @extend %fa-icon;
      @extend .fa-regular;
      margin-left: $spacer * .5;
      content: fa-content($fa-var-arrow-right);
    }
  }

  .gform_previous_button {
    width: fit-content;

    &::before {
      @extend %fa-icon;
      @extend .fa-regular;
      margin-right: $spacer * .5;
      content: fa-content($fa-var-arrow-left);
    }
  }

  button[type="submit"] {
    float: right;
  }

  input {
    display: block;
    width: 100%;
  }
}

.gform_footer {
  @include media-breakpoint-down(md) {
    button:only-of-type {
      width: 100%;
    }
  }
}

.gform_page {
  .gform_fields {
    .gfield {
      @extend .mb-3;
    }
  }
}

.gf_page_steps {
  color: $gray-700;
  text-align: center;
  @include margin-bottom($spacer);
  @extend .fs-4;

  .gf_step {
    width: fit-content;
    padding: $spacer * .25 $spacer * .75;
    margin: 0 auto;
    background-color: $white;
    @include border-radius($border-radius-pill);
  }

  .gf_step_pending,
  .gf_step_hidden,
  .gf_step_previous,
  .gf_step_completed {
    @extend .d-none;
  }
}

.gf_progressbar_wrapper {
  margin-bottom: $spacer * 2;

  .progress {
    height: $spacer * .5;
  }

  .gf_progressbar_title {
    @extend .d-none;
  }

  .progress_percentage span {
    @extend .d-none;
  }
}

.checkbox-tiles {
  .form-label {
    font-size: $spacer * 1;
    font-weight: $font-weight-medium;
    text-align: center;

    .gfield_required {
      display: none;
    }
  }

  .gfield_checkbox {
    @include make-row();
  }

  .form-check {
    @include make-col-ready();
    @include make-col(6);
    position: relative;
    padding-right: rfs-value(12px);
    padding-left: rfs-value(12px);

    &:last-child {
      @include make-col(12);
      margin-bottom: 0;

      .form-check-label {
        text-align: left;

        i {
          display: inline-block;
          margin-right: $spacer * .5;
          margin-bottom: 0;
        }

        &::before {
          //stylelint-disable-next-line function-disallowed-list
          top: calc(50% - 12px);
        }
      }
    }

    input:checked {
      label {
        border-color: $interactive;
      }
    }

    .form-check-input {
      position: absolute;
      width: 1px;
      height: 1px;
      overflow: hidden;
      clip: rect(0 0 0 0);
      clip-path: inset(100%);
      white-space: nowrap;

      &:checked {
        ~ .form-check-label {
          border-color: $interactive;

          &::before {
            background-color: $interactive;
            border-color: $interactive;
            opacity: 1;
            transform: scale(1);
          }
        }
      }
    }

    .form-check-label {
      @extend .fs-2;
      width: 100%;
      text-align: center;
      cursor: pointer;
      border: rfs-value(2px) solid $gray-300;
      @include border-radius($border-radius-sm);
      @include transition(all .15s ease-in-out);
      @include padding($spacer);

      &::before {
        position: absolute;
        top: 12px;
        right: 24px;
        width: 24px;
        height: 24px;
        content: "";
        background-image: escape-svg(url("data:image/svg+xml, <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512' fill='#{$white}'><path d='M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z'/></svg>"));
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 14px;
        border: rfs-value(2px) solid $gray-400;
        opacity: 0;
        transform: scale(0);
        @include border-radius($border-radius-pill);
        @include transition(.25s ease);
      }

      i {
        @extend .fs-1;
        display: block;
        margin-bottom: $spacer * .5;
      }

      &:hover {
        border-color: $interactive;

        &::before {
          opacity: 1;
          transform: scale(1);
        }
      }
    }
  }
}
