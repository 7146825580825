.customer-stories-quote {
  &.overlap {
    .image-container {
      max-width: 100%;
      height: 100%;
      margin: 0;
      @include media-breakpoint-down(md) {
        margin: 0 auto;
        @include padding-top($spacer * 4);
      }

      img {
        width: 100%;
      }
    }
  }

  .order-1 {
    .image-container {
      .icon-container {
        left: rfs-value(-40px);
      }
    }
  }

  .image-container {
    position: relative;
    height: 100%;

    img {
      @include border-radius($border-radius-xxxl);
      height: 100%;
      overflow: hidden;
      object-fit: cover;
    }
  }

  .text-content {
    display: flex;
    align-items: center;
    @include media-breakpoint-down(md) {
      margin: 0 auto;
      @include margin-top($spacer * 4);
    }

    &.overlap {
      &.order-2 {
        .content-container {
          margin-right: -16.66666667%;
          margin-left: 0;
          @include media-breakpoint-down(md) {
            margin-right: 0;
          }
        }
      }

      .content-container {
        position: relative;
        z-index: 1;
        margin-left: -16.66666667%;
        background-color: $white;
        @include padding($spacer * 3);
        @include box-shadow($box-shadow-xxl);
        @include border-radius($border-radius-xxxl);
        @include margin-right($spacer * 0);
        @include media-breakpoint-down(md) {
          margin-top: -18.66666667%;
          margin-left: 0;
        }
        @include media-breakpoint-down(sm) {
          margin-top: -23.66666667%;
        }
      }
    }

    .content-container {
      @include padding($spacer * 3);
      @include media-breakpoint-down(sm) {
        padding-top: 0;
      }
      h4 {
        font-weight: $font-weight-medium;
        line-height: 1.6;
      }
      .desc {
        text-align: right;
      }
    }
  }
}
