.product-steps {
  .content-holder {
    @include margin-bottom($spacer * 3);

    p {
      margin-bottom: 0;
    }
  }

  .row {
    .col-6 {
      @include media-breakpoint-down(lg) {
        display: none;
      }
    }
  }

  .steps-image {
    position: relative;
    width: 100%;
    height: 100%;
    padding-left: 0;
    list-style: none;

    &.move {
      .image-holder {
        width: 85%;
      }
    }

    > li {
      position: absolute;
      width: 100%;
      height: 100%;
      opacity: 0;
      @include transition($transition-base);

      .image-holder {
        position: relative;
        display: block;
        width: 80%;
        height: 100%;
        margin: 0 auto;
        overflow: hidden;
        @include border-radius($border-radius-xxxl);
        @include transition($transition-base);
        //stylelint-disable-next-line selector-max-compound-selectors
        img {
          object-fit: cover;
        }

        &::after {
          position: absolute;
          //stylelint-disable-next-line function-disallowed-list
          top: calc(50% - 32px);
          //stylelint-disable-next-line function-disallowed-list
          right: calc(50% - 200px);
          width: 400px;
          height: 64px;
          content: "";
          @include transition($transition-base);
        }

        &.homesafe {
          &::after {
            background-image: url("../images/homesafe-logo.svg");
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
          }
        }

        &.homesafe-second {
          &::after {
            height: 64px;
            background-image: url("../images/homesafe-second-logo.svg");
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
          }
        }

        &.hecm {
          &::after {
            background-image: url("../images/hecm-logo.svg");
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
          }
        }

        &.equity {
          &::after {
            background-image: url("../images/equity-logo.svg");
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
          }
        }
      }

      //stylelint-disable-next-line selector-no-qualifying-type
      &.active {
        opacity: 1;
      }
    }
  }

  .steps-content {
    .btn {
      margin-top: $spacer * 1.875;
    }
  }

  .steps-list {
    width: 100%;
    padding-left: 0;
    margin-left: 0;
    list-style: none;

    li {
      position: relative;
      display: flex;
      list-style: none;
      cursor: pointer;

      .icon {
        @include transition($transition-base);
        position: relative;
        float: left;
        color: $gray-300;
        @include margin-right($spacer * 2);
        //stylelint-disable-next-line selector-max-compound-selectors
        i {
          width: 24px;
          font-size: rfs-value(24px);
          text-align: center;
        }

        &::after {
          position: absolute;
          @include rfs(40px, top);
          left: 11.5px;
          width: 1px;
          //stylelint-disable-next-line function-disallowed-list
          height: calc(100% - 55px);
          content: "";
          background: $gray-300;
          @include transition($transition-base);
          @include media-breakpoint-down(md) {
            height: 35%;
          }
        }
      }

      .content {
        display: flex;
        flex-direction: column;
        color: $gray-300;
        @include transition($transition-base);
        //stylelint-disable-next-line selector-max-compound-selectors
        .image-holder {
          display: none;
          @include media-breakpoint-down(lg) {
            position: relative;
            display: block;
            width: 100%;
            height: 0;
            overflow: hidden;
            object-fit: contain;
            opacity: 0;
            @include transition($transition-base);
            @include border-radius($border-radius-xl);
            @include transition($transition-base);
            //stylelint-disable-next-line selector-max-compound-selectors, selector-max-combinators
            img {
              width: 100%;
            }
          }
        }

        //stylelint-disable-next-line selector-max-compound-selectors
        .title {
          margin-bottom: 0;
          font-weight: $font-weight-bold;
          color: $gray-300;
          @include transition($transition-base);
          @extend .fs-1;
        }

        //stylelint-disable-next-line selector-max-compound-selectors
        .description {
          @include transition($transition-base);
          @extend .fs-4;
          height: 0;
          color: $gray-300;
          opacity: 0;
          @include margin-bottom($spacer * 3);
        }
      }

      &:hover {
        .icon {
          color: $interactive;
        }

        .title {
          @include transition($transition-base);
          color: $gray-900;
        }
      }

      //stylelint-disable selector-no-qualifying-type
      &.active {
        opacity: 1;

        .icon {
          color: $interactive;

          &::after {
            //stylelint-disable-next-line function-disallowed-list
            height: calc(100% - 55px);
            background: $interactive;
            @include transition($transition-base);
          }
        }

        .content {
          @include transition($transition-base);
          //stylelint-disable-next-line selector-max-compound-selectors
          .image-holder {
            @include media-breakpoint-down(lg) {
              @include margin-bottom($spacer * 1.5);
              position: relative;
              display: block;
              width: 100%;
              height: 250px;
              overflow: hidden;
              opacity: 1;
              @include transition($transition-base);
              //stylelint-disable-next-line selector-max-compound-selectors, selector-max-combinators
              img {
                object-fit: contain;
              }
              //stylelint-disable-next-line selector-max-compound-selectors
              &::after {
                position: absolute;
                //stylelint-disable-next-line function-disallowed-list
                top: calc(50% - 16px);
                //stylelint-disable-next-line function-disallowed-list
                right: calc(50% - 100px);
                width: 200px;
                height: 32px;
                content: "";
              }
              //stylelint-disable-next-line selector-max-compound-selectors, selector-max-class
              &.homesafe {
                //stylelint-disable-next-line selector-max-compound-selectors, selector-max-class
                &::after {
                  background-image: url("../images/homesafe-logo.svg");
                  background-repeat: no-repeat;
                  background-position: center;
                  background-size: contain;
                }
              }
              //stylelint-disable-next-line selector-max-compound-selectors, selector-max-class
              &.homesafe-second {
                //stylelint-disable-next-line selector-max-compound-selectors, selector-max-class
                &::after {
                  height: 40px;
                  background-image: url("../images/homesafe-second-logo.svg");
                  background-repeat: no-repeat;
                  background-position: center;
                  background-size: contain;
                }
              }
              //stylelint-disable-next-line selector-max-compound-selectors, selector-max-class
              &.hecm {
                //stylelint-disable-next-line selector-max-compound-selectors, selector-max-class
                &::after {
                  background-image: url("../images/hecm-logo.svg");
                  background-repeat: no-repeat;
                  background-position: center;
                  background-size: contain;
                }
              }
              //stylelint-disable-next-line selector-max-compound-selectors, selector-max-class
              &.equity {
                //stylelint-disable-next-line selector-max-compound-selectors, selector-max-class
                &::after {
                  background-image: url("../images/equity-logo.svg");
                  background-repeat: no-repeat;
                  background-position: center;
                  background-size: contain;
                }
              }
            }
          }

          //stylelint-disable-next-line selector-max-compound-selectors
          .title {
            @include transition($transition-base);
            margin-bottom: $spacer;
            color: $gray-900;
          }

          //stylelint-disable-next-line selector-max-compound-selectors
          .description {
            @include transition($transition-base);
            height: fit-content;
            color: $gray-800;
            opacity: 1;
          }
        }
      }

      //stylelint-enable selector-no-qualifying-type
      &:last-child {
        .description {
          margin-bottom: 0;
        }
      }
    }
  }

  .legal {
    @include margin-top($spacer * 3);
    margin-bottom: 0;
  }
}
