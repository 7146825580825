.cta-big-text {
  .cta-row {
    position: relative;
    justify-content: space-between;
    @include padding($spacer * 1.5 $spacer * 2);
    @include border-radius($border-radius-xl);
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
    background-size: cover;
    @include padding($spacer * 5 $spacer * 2);
    @include border-radius($border-radius-xl);
    @include media-breakpoint-down(md) {
      @include padding($spacer * 3 $spacer * 1.25);
    }
    @include media-breakpoint-down(md) {
      @include padding($spacer * 1 $spacer * 1.25);
    }
    .cta-content {
      width: 100%;
      max-width: $spacer * 26.938;
      h4 {
        color: $white;
      }
      .cta-text {
        @extend .fs-1;
        @include margin-bottom($spacer * 1);
        text-align: left;

        @include media-breakpoint-down(lg) {
          width: 70%;
        }

        @include media-breakpoint-down(md) {
          width: 90%;
        }
      }
    }
    .cta-img {
      text-align: center;
      @include media-breakpoint-down(sm) {
        display: none;
      }
      img {
        min-height: $spacer * 10.75;
      }
    }
    .cta-button {
      text-align: center;
    }
  }
  @include media-breakpoint-down(md) {
    .list-active {
      flex: 0 0 100%;
      max-width: 100%;
      height: 180px;
      margin-bottom: $spacer * 1.5;
    }
    .cta-buttons-list {
      position: relative;
      top: 45px;
      bottom: initial;
      flex-wrap: wrap;
      gap: 10px;
      //stylelint-disable-next-line selector-no-qualifying-type
      a.btn {
        bottom: initial;
        flex: 0 0 48%;
        max-width: 48%;
        padding: $spacer * .5 0 $spacer * .25;
        i {
          font-size: rfs-value(28px);
        }
      }
    }
  }
}
.cta-buttons-list {
  @include margin-top($spacer * 2);
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  gap: 16px;
  justify-content: center;
  width: 100%;
  //stylelint-disable-next-line selector-no-qualifying-type
  a.btn {
    position: relative;
    left: initial;
    transform: initial;
    i {
      font-size: rfs-value(35px);
      color: $white;
    }
  }
}
