.blog-table {
  padding: $spacer * 1.5 0;
  margin-bottom: $spacer * 1.5;

  p {
    margin-bottom: $spacer * 1.5;
  }

  @include media-breakpoint-down(sm) {
    display: none;
  }

  thead {
    th {
      padding: $spacer * 1.25;
      color: $white;
      vertical-align: middle;
      background-color: $denim;
      border-top: 0;

      &:first-child {
        border-right: rfs-value(1px) solid $electric;
        border-left: 0;
      }

      &:last-child {
        border-right: 0;
        border-left: rfs-value(1px) solid $electric;
      }
    }
  }

  tbody {
    tr {
      @include media-breakpoint-down(sm) {
        display: block;
      }

      &:last-child {
        //stylelint-disable-next-line selector-max-type
        td {
          border-bottom: 0;
        }
      }

      //stylelint-disable-next-line selector-max-type
      td {
        padding: $spacer * 1.25;
        color: $denim;
        background-color: $white;
        border: rfs-value(1px) solid $electric;

        //stylelint-disable-next-line selector-max-type
        &:first-child {
          border-left: 0;
        }

        //stylelint-disable-next-line selector-max-type
        &:last-child {
          border-right: 0;
        }
      }
    }
  }

  .mobile {
    display: none;

    @include media-breakpoint-down(sm) {
      display: block;
    }

    .accordion {
      background: $white;

      .card {
        //stylelint-disable-next-line selector-max-compound-selectors
        .card-header {
          padding: 0;

          //stylelint-disable-next-line
          button {
            position: relative;
            width: 100%;
            padding: $spacer * 1.25;
            //stylelint-disable-next-line declaration-no-important
            font-weight: $font-weight-bold !important;
            color: $white;
            //stylelint-disable-next-line declaration-no-important
            text-align: center !important;
            cursor: pointer;
            background: $denim;
            border: 0;
            border-bottom: rfs-value(1px) solid $electric;
            @include font-size(20px);

            @include media-breakpoint-down(sm) {
              padding-right: $spacer * 3.125;
            }

            //stylelint-disable-next-line
            &:not(.collapsed) {
              //stylelint-disable-next-line
              &::after {
                transform: translateY(-50%) rotate(180deg);
              }
            }

            //stylelint-disable-next-line
            &::after {
              @extend %fa-icon;
              @extend .fa-solid;
              position: absolute;
              top: 50%;
              right: $spacer * 1.25;
              @include font-size(14px);
              font-weight: 700;
              color: $white;
              content: fa-content($fa-var-arrow-down);
              @include transition(all .2s ease-in-out);
              transform: translateY(-50%);
            }
          }
        }

        //stylelint-disable-next-line selector-max-compound-selectors
        .card-body {
          padding: 0;
          //stylelint-disable-next-line
          p {
            padding: $spacer * 1.25;
            margin: 0;
            color: $denim;
            border-bottom: rfs-value(1px) solid $electric;

            //stylelint-disable-next-line
            &:last-child {
              border-bottom: none;
            }

            //stylelint-disable-next-line
            span {
              display: block;
              font-weight: $font-weight-bold;
            }
          }
        }
      }
    }
  }
}
