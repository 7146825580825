.blog-category {
  @include padding(0 $spacer * 1.5);

  .category-header {
    display: flex;
    align-items: center;
    @include margin(0 0 $spacer * 5);

    @include media-breakpoint-down(sm) {
      justify-content: space-between;
    }

    .category-title {
      position: relative;
      display: flex;
      flex: 0 0 90%;
      align-items: center;
      justify-content: space-between;
      margin: 0;

      @include media-breakpoint-down(sm) {
        flex: auto;
      }

      &::after {
        flex: 1 1 auto;
        height: 1px;
        @include margin-right($spacer * 2.5);
        @include margin-left($spacer * 2.5);
        content: "";
        background: $gray-400;
      }
    }

    .category-heading {
      position: relative;
      display: flex;
      flex: 0 99%;
      align-items: center;
      justify-content: space-between;

      @include media-breakpoint-down(sm) {
        flex: auto;
      }

      &::after {
        flex: 1 1 auto;
        height: 1px;
        @include margin-right($spacer * 2.5);
        @include margin-left($spacer * 2.5);
        content: "";
        background: $gray-400;
      }
    }
  }

  .category-posts {
    @include media-breakpoint-down(lg) {
      padding: 0;
    }
  }

  .sub-cat-row {
    padding-right: $spacer * 1.25;
  }
}

.category-post,
.article-single {
  @include media-breakpoint-down(lg) {
    @include margin-bottom($spacer * 1.25);
  }

  &.subcategory {
    padding: 0 $spacer * 1.25;
    margin: $spacer * 1.25 0;
    background-image: linear-gradient(to left, rgba($white, 1) 80%, rgba($white, 0) 10%);
    @include border-radius(0 $border-radius $border-radius 0);

    img {
      @include border-radius($border-radius 0 0 $border-radius);
      @include media-breakpoint-down(sm) {
        width: 100%;
        min-height: 550px;
      }
    }

    .col-6 {
      @include media-breakpoint-down(sm) {
        padding-right: 0;
      }
    }
  }

  img {
    width: 100%;
    height: 280px;
    object-fit: cover;
    @include border-top-radius($border-radius-xxl);

    @include media-breakpoint-down(lg) {
      margin-bottom: 0;
    }

    @include media-breakpoint-down(sm) {
      max-height: 150px;
    }
  }

  .cat-post-container {
    @include padding($spacer * 2.5);
    background-color: $light;
    @include border-bottom-radius($border-radius-xxl);
  }

  .cat-post-title {
    @extend .h5;
    display: inline-block;
    text-decoration: none;
    @include margin-bottom($spacer);

    a {
      color: var(--#{$prefix}heading-color);
    }
  }

  .post-excerpt {
    @include margin-bottom($spacer * 1.5);
  }
}


.post-disclaimer {
  .disclaimer {
    width: 100%;
    text-align: center;
    @extend .legal;
    @include media-breakpoint-up(lg) {
      width: 80%;
    }
  }
}
