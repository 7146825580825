.springboard-tiles {
  h1 {
    text-align: center;
    @include margin-bottom($spacer * 1.5);
    @include media-breakpoint-down(sm) {
      font-size: rfs-fluid-value(5rem);
    }
  }
  ul {
    padding-left: 0;
    list-style: none;
  }
  p {
    text-align: center;
    @include margin-bottom($spacer * 4);
  }

  .tile-holder {
    @include margin-bottom($spacer * 1.5);
  }

  a {
    text-decoration: none;

    .tile {
      height: 100%;
      font-weight: $font-weight-normal;
      color: $gray-800;
      text-align: center;
      text-decoration: none;
      background-color: $white;
      border: rfs-value(2px) solid transparent;
      @include padding($spacer * 3.5 $spacer * 2);
      @include border-radius($border-radius-xxxl);
      @include box-shadow($box-shadow-xxl);
      @include transition(box-shadow .3s ease-in-out, border-color .15s ease-in-out);

      img {
        display: block;
        width: 100%;
        max-width: 155px;
        max-height: 48px;
        margin: 0 auto;
        object-fit: contain;
        @include margin-bottom($spacer * 1.5);
      }

      .description {
        @include margin-bottom($spacer * 2.25);
      }

      .link {
        margin-bottom: 0;
        color: $gray-600;
        //stylelint-disable-next-line selector-max-compound-selectors
        i {
          margin-left: $spacer * .5;
          color: $interactive;
        }
      }

      &:hover {
        border-color: $interactive;
        @include box-shadow($box-shadow-xxxl);
      }
    }
  }
}
