.error-404 {
  position: relative;
  text-align: center;
  background-position: center center;
  background-size: cover;
  @include padding-top($spacer * 18.8125);
  @include padding-bottom($spacer * 18.8125);
  &::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: "";
    background-color: rgba($black, .1);

  }
  .row.h-100 {
    position: relative;
    z-index: 2;
  }
  h1 {
    @include margin-bottom($spacer * 1.5625);
    color: $white;
  }
  p {
    @include margin-bottom($spacer * 1.75);
    font-size: $font-size-lg;
    font-weight: $font-weight-medium;
    color: $white;
  }
  a {
    &:not(:last-child) {
      @include margin-right($spacer * 1.5);
      @include media-breakpoint-down(sm) {
        margin-right: 0;
        @include margin-bottom($spacer * 1.5);

      }
    }
    @include media-breakpoint-down(sm) {
      display: block;
    }
  }
}
