.wp-block-pullquote,
.pullquote-inset {
  padding: $spacer * 1.5 0;

  blockquote {
    position: relative;
    padding-left: $spacer * 2.25;

    @include media-breakpoint-down(md) {
      //stylelint-disable-next-line declaration-no-important
      float: none !important;
      //stylelint-disable-next-line declaration-no-important
      width: 100% !important;
      padding: 0;
      //stylelint-disable-next-line declaration-no-important
      margin: $spacer * 3 0 $spacer * 2 !important;
    }

    &::before {
      @extend %fa-icon;
      @extend .fa-solid;
      position: absolute;
      top: 0;
      left: $spacer * -1.25;
      width: $spacer * 3.125;
      color: $electric;
      content: "“";
      background-color: transparent;
      @include font-size(56px);

      @include media-breakpoint-down(md) {
        top: $spacer * -3.125;
        left: 0;
        width: 35px;
        height: 35px;
      }
    }

    p {
      margin-bottom: $spacer * 1.5;
      font-weight: $font-weight-light;
      color: $denim;
      @include font-size(40px);
      @include media-breakpoint-down(sm) {
        //stylelint-disable-next-line declaration-no-important
        padding: 0 !important;
      }
    }

    cite {
      @extend .fs-1;
      font-style: normal;
      color: $denim;
    }
  }
}

.pullquote-inset {
  blockquote {
    float: right;
    width: 500px;
    margin-top: 0;
    margin-bottom: $spacer * 3;
    margin-left: $spacer * 1.5;

    cite {
      position: relative;
    }
  }

  .text {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      @include font-size(40px);
      font-weight: $font-weight-bold;
    }
    p {
      @extend .fs-1;
    }
  }
}
