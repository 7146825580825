.single-post,
.single-stories {
  .blog-header {
    position: relative;
    @include padding($spacer * 7.5 0 $spacer * 8);

    .blog-header-info {
      z-index: 1;
      @include media-breakpoint-up(lg) {
        align-content: end;
      }

      .previous-button {
        @include media-breakpoint-down(lg) {
          @include margin-bottom($spacer * 1.5);
        }
      }

      .blog-text {
        @include padding($spacer * 3);
        @include margin-top($spacer * 4.5);
        margin-right: -16.66666667%;
        @include margin-bottom($spacer * -3);
        background-color: $white;
        @include border-radius($border-radius);
        @include media-breakpoint-down(xl) {
          margin: 0;
        }
        @include media-breakpoint-down(lg) {
          margin: 0;
          @include border-bottom-radius(0);
        }
        //stylelint-disable-next-line selector-max-compound-selectors
        .blog-info {
          @include margin-bottom($spacer * 1.5);
          display: flex;
          justify-content: space-between;

          @include media-breakpoint-down(sm) {
            margin-bottom: $spacer;
          }

          //stylelint-disable-next-line selector-max-compound-selectors, selector-max-combinators, selector-max-class
          .date-time {
            @extend .fs-4;
            color: $gray-800;
          }

          //stylelint-disable-next-line selector-max-compound-selectors, selector-max-combinators, selector-max-class
          .reading-time {
            font-weight: $font-weight-bold;
            color: $gray-800;

            //stylelint-disable-next-line selector-max-compound-selectors, selector-max-combinators, selector-max-class
            i {
              margin-right: $spacer * .625;
              color: $interactive;
            }

            @include media-breakpoint-down(sm) {
              display: none;
            }
          }
        }
        //stylelint-disable-next-line selector-max-compound-selectors
        .blog-header-btm {
          display: flex;
          justify-content: space-between;
          margin-top: $spacer * 1.875;
          @include media-breakpoint-down(sm) {
            margin-top: $spacer * 1.25;
          }

          //stylelint-disable-next-line selector-max-compound-selectors, selector-max-combinators, selector-max-class
          .reading-time-mobile {
            display: none;
            font-weight: $font-weight-bold;
            color: $gray-800;

            //stylelint-disable-next-line selector-max-compound-selectors, selector-max-combinators, selector-max-class
            i {
              margin-right: $spacer * .625;
              color: $interactive;
            }

            @include media-breakpoint-down(sm) {
              display: block;
            }
          }
        }
      }
    }

    .blog-image {
      img {
        width: 100%;
        height: 500px;
        @include border-radius($border-radius);
        object-fit: cover;
        @include media-breakpoint-down(lg) {
          @include border-top-radius(0);
        }
        @include media-breakpoint-down(md) {
          height: 400px;
        }
      }
    }
  }

  .blog-content-body {
    .wp-block-image {
      img {
        @include border-radius($border-radius);
      }
    }

    .cta-big-text {
      @include margin-top($spacer * 4.5);
    }

    .cta {
      @include padding-top($spacer * 6.25);
      @include padding-bottom($spacer * 6.25);

      .cta-row {
        position: relative;
        padding: $spacer * 3.75;
        background-color: $denim;
        @include border-radius($border-radius-lg);
        @include media-breakpoint-down(md) {
          padding: $spacer * 2.5 $spacer * 1.25 $spacer * 2.5;
        }
        //stylelint-disable-next-line selector-max-compound-selectors
        h3 {
          @extend .display-3;
          color: $white;
          text-align: center;
        }
        //stylelint-disable-next-line selector-max-compound-selectors
        a {
          position: absolute;
          bottom: -30px;
          left: 50%;
          width: 260px;
          transform: translateX(-50%);
        }
      }
    }

    .wp-block-heading {
      @include margin-top($spacer * 4.5);
      @include margin-bottom($spacer * 1.25);
    }
  }

  .blog-content-footer {
    .tags {
      @include margin-top($spacer * 2);
      padding-left: 0;
      list-style: none;

      li {
        @extend .fs-4;
        display: inline-block;
        background-color: $gray-200;
        @include border-radius($border-radius-pill);
        @include padding($spacer * .375 $spacer);
        @include margin-bottom($spacer * .75);

        &:not(:last-child) {
          margin-right: $spacer;
        }
      }
    }
  }

  .blog-related-articles {
    padding: $spacer * 3.125 0;
  }


  .similar-article-section {
    @include padding-top($spacer * 7.5);
    @include padding-bottom($spacer * 7.5);
    h1 {
      @include margin-bottom($spacer * 3.5);
    }

    .similar-article-posts {
      margin-top: $spacer * 1.25;

      .article-single {
        //stylelint-disable-next-line selector-max-compound-selectors
        .featured-blog-title {
          @extend .h5;
          //stylelint-disable-next-line selector-max-compound-selectors, selector-max-combinators
          a {
            color: var(--#{$prefix}heading-color);
            text-decoration: none;
          }
        }
        //stylelint-disable-next-line selector-max-compound-selectors
        h5 {
          @extend .h4;
        }
        //stylelint-disable-next-line selector-max-compound-selectors
        .blog-author {
          @extend .fs-4;
        }

        @include media-breakpoint-down(lg) {
          margin-bottom: $spacer * 1.5;
        }
        //stylelint-disable-next-line selector-max-compound-selectors
        img {
          width: 100%;
          height: 300px;
          @include border-radius($border-radius $border-radius 0 0);
          object-fit: cover;
          @include media-breakpoint-down(sm) {
            height: 200px;
          }
        }
        //stylelint-disable-next-line selector-max-compound-selectors
        .btn-link {
          //stylelint-disable-next-line declaration-no-important
          color: $electric !important;
        }
        //stylelint-disable-next-line selector-max-compound-selectors
        .featured-blog-text {
          max-width: 100%;
          padding: $spacer * 2;
          background-color: $light;
          @include border-radius(0 0 $border-radius $border-radius);
        }
        //stylelint-disable-next-line selector-max-compound-selectors
        .reading-time {
          @extend .fs-3;
          margin: $spacer * .25 0;
          font-weight: $font-weight-medium;
          color: #8d8d8d;
        }
      }
    }
  }

  .social-holder {
    z-index: 1;
  }

  .module {
    outline: 0;
  }

  .toc-mobile {
    display: none;

    @include media-breakpoint-down(lg) {
      display: block;
      background-color: $white;
    }

    &.toc-active {
      .blog-table-of-contents {
        opacity: 1;
      }

      .toc-mobile-trigger {
        &::after {
          transform: translateY(-50%) rotate(180deg);
        }
      }
    }

    .toc-mobile-trigger {
      position: relative;
      width: 100%;
      padding: $spacer * 1.25 $spacer * 1.5;
      font-weight: 500;
      line-height: 1;
      color: $black;
      text-align: left;
      text-transform: uppercase;
      background: $white;
      border: 0;
      @extend .fs-3;

      &::after {
        position: absolute;
        top: 50%;
        right: 20px;
        font-family: $fa-style-family;
        @extend .fs-4;
        font-weight: $font-weight-bold;
        color: #8b8b8b;
        content: "\f078";
        @include transition(all .2s ease-in-out);
        transform: translateY(-50%);
      }
    }


    .blog-table-of-contents {
      padding: 0 $spacer * 1.5;

      .toc-headline {
        display: none;
      }

      .toc-menu {
        margin-bottom: $spacer * 1.5;
        //stylelint-disable-next-line selector-max-compound-selectors
        li {
          //stylelint-disable-next-line selector-max-compound-selectors, selector-max-combinators
          a {
            //stylelint-disable-next-line declaration-no-important
            display: block !important;
            //stylelint-disable-next-line declaration-no-important
            text-align: left !important;
            //stylelint-disable-next-line declaration-no-important
            opacity: 1 !important;

            //stylelint-disable-next-line selector-max-compound-selectors, selector-max-combinators
            &:hover {
              //stylelint-disable-next-line declaration-no-important
              background: none !important;
            }
          }
        }
        //stylelint-disable-next-line selector-max-compound-selectors
        .progress-line {
          //stylelint-disable-next-line selector-max-compound-selectors
          &::before {
            bottom: -8px;
            background-color: $white;
          }
        }
      }
    }
  }

  .toc-lock {
    //stylelint-disable-next-line declaration-no-important
    overflow: hidden !important;
    touch-action: none;
  }

  .single-post .toc-mobile {
    //stylelint-disable-next-line declaration-no-important
    overflow: hidden !important;
  }

  .toc-desktop {
    height: auto;

    @include media-breakpoint-down(lg) {
      position: fixed;
      top: 999999px;
      left: 999999px;
      visibility: hidden;
    }
  }

  .blog-table-of-contents {
    position: sticky;
    top: 150px;
    width: 100%;

    @include media-breakpoint-down(lg) {
      top: 0;
      padding-bottom: $spacer * 2.5;
    }

    .toc-headline {
      margin-bottom: $spacer * 1.25;
      @extend .fs-2;
      font-weight: $font-weight-bold;
      text-transform: uppercase;
    }

    .toc-menu {
      position: relative;
      padding-left: $spacer * 1.375;
      margin-bottom: 0;
      list-style-type: none;

      &::after {
        position: absolute;
        top: 15px;
        left: 10px;
        z-index: 0;
        width: 2px;
        height: 4px;
        content: "";
        background: $electric;
        @include transition(all .4s ease);
      }

      &.last-elem-active {
        &::after {
          //stylelint-disable-next-line function-disallowed-list
          height: calc(100% + -30px);
        }
      }

      &::before {
        position: absolute;
        top: 50%;
        left: 10px;
        width: 2px;
        //stylelint-disable-next-line function-disallowed-list
        height: calc(100% - 30px);
        content: "";
        background: $gray-100;
        transform: translateY(-50%);
      }

      .progress-line {
        position: absolute;
        top: 15px;
        left: 10px;
        z-index: 1;
        width: 2px;
        height: 0;
        background: $electric;
        @include transition(all .25s ease);

        &::before {
          position: absolute;
          bottom: -10px;
          left: -4px;
          width: 10px;
          height: 30px;
          content: "";
          background-color: $gray-50;
          background-image: url("../images/toc-icon.svg");
          background-repeat: no-repeat;
          background-position: center;
          background-size: contain;
        }
      }

      li {
        position: relative;
        padding: 15px 0;
        line-height: 1;

        &::before {
          position: absolute;
          top: 0;
          left: -10px;
          width: 2px;
          height: 0;
          content: "";
          background: $electric;
          @include transition(all .25s ease);
        }

        //stylelint-disable-next-line selector-no-qualifying-type
        &.active,
        &.passed {
          //stylelint-disable-next-line selector-max-compound-selectors
          a {
            font-weight: $font-weight-bold;
          }
        }

        //stylelint-disable-next-line selector-no-qualifying-type
        &.active {
          //stylelint-disable-next-line selector-max-compound-selectors
          a {
            pointer-events: none;
          }
        }
        //stylelint-disable-next-line selector-max-compound-selectors
        a {
          font-weight: $font-weight-normal;
          @extend .fs-2;
          color: $black;
          text-decoration: none;
          user-select: none;
        }
      }
    }
  }
}
