.wp-block-image {
  position: relative;
  overflow: hidden;
  @include border-radius($border-radius);
  img {
    @include border-radius($border-radius);
    display: block;
    width: 100%;
    margin: 0 auto;
  }
  &.is-resized {
    figcaption {
      position: relative;
      @include border-bottom-radius($border-radius);
    }
  }
  figcaption {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: $spacer * 1.5;
    margin: 0;
    font-style: italic;
    color: $denim;
    background-color: #fffffff2;
    @include font-size(16px);
  }
}
