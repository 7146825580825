.modal-header {
  padding-bottom: 0;
  border-bottom: 0;
  .btn-close {
    @extend .btn;
    @extend .btn-sm;
    @extend .btn-icon;
    padding: 0;
  }
}
.modal-footer {
  padding: var(--#{$prefix}modal-padding);
  padding-top: 0;
  border-top: 0;
}
.modal-title {
  @extend .fs-1;
  font-weight: $font-weight-bold;
}
.modal-dialog-start {
  align-items: start;
}
.modal-dialog-end {
  align-items: end;
}
.modal-dialog-media {
  .modal-content {
    padding: 0;
    @include border-radius($border-radius-lg);
  }
  .modal-header {
    position: relative;
    padding: 0;
    border-bottom: none;
    .btn-close {
      position: absolute;
      right: -.5em;
      width: $btn-close-media-width;
      height: $btn-close-media-height;
      background: transparent var(--#{$prefix}btn-close-media-bg) center / $btn-close-media-width 100% no-repeat; // include transparent for button elements
      &:hover {
        opacity: 1;
        --fa-secondary-color: #{$interactive-425};
      }
      @include media-breakpoint-down(xl) {
        right: 0;
        width: rfs-value(25px);
        height: rfs-value(25px);
      }
    }
  }
  @include media-breakpoint-down(sm) {
    @include margin-right($spacer * 1.25);
    @include margin-left($spacer * 1.25);
  }
}

.modal-dialog-scrollable {
  .modal-footer {
    padding-top: var(--#{$prefix}modal-padding);
    border-top: var(--#{$prefix}modal-header-border-width) solid var(--#{$prefix}modal-header-border-color);
  }
  .modal-header {
    padding-bottom: var(--#{$prefix}modal-padding);
    border-bottom: var(--#{$prefix}modal-header-border-width) solid var(--#{$prefix}modal-header-border-color);
  }
}
