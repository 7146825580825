html {
  scroll-behavior: smooth;
}

body {
  background-color: $gray-50;
  //stylelint-disable selector-no-qualifying-type
  &.blur {
    .site {
      position: relative;

      &::after {
        position: absolute;
        top: 0;
        z-index: 9;
        width: 100%;
        height: 100%;
        content: "";
        background-color: rgba($black, .1);
        backdrop-filter: blur(3px) opacity(1);
      }
    }
  }

  &.modal-open {
    .sticky-top {
      z-index: 8;
    }
  }

  //stylelint-enable selector-no-qualifying-type
}

main {
  position: relative;
  overflow: clip;
}

// stylelint-disable selector-max-id
#content-wrapper,
#contact-content-wrapper,
.blog-content-footer {
  > section:not(.hero) {
    @include padding-top($spacer * 7.5);
    @include padding-bottom($spacer * 3.75);
    //stylelint-disable-next-line selector-no-qualifying-type
    &[class*="bg-"],
    &[class*="has-bg"],
    &[class*="has-image-bg"] {
      @include margin-top($spacer * 7.5);
      @include padding-top($spacer * 7.5);
      @include padding-bottom($spacer * 7.5);
      @include margin-bottom($spacer * 3.75);
      //stylelint-disable-next-line selector-no-qualifying-type
      &.spacer-top-half {
        @include padding-top($spacer * 7.5);
        @include padding-bottom($spacer * 7.5);
        @include margin-top($spacer * 1.875);
      }

      //stylelint-disable-next-line selector-no-qualifying-type
      &.spacer-top-half-bg {
        @include padding-top($spacer * 3.75);
        @include margin-top($spacer * 3.75);
        @include margin-bottom($spacer * 3.75);
      }

      //stylelint-disable-next-line selector-no-qualifying-type
      &.spacer-bottom-half {
        @include padding-top($spacer * 7.5);
        @include padding-bottom($spacer * 7.5);
        @include margin-bottom($spacer * 1.875);
      }

      //stylelint-disable-next-line selector-no-qualifying-type
      &.spacer-bottom-half-bg {
        @include padding-bottom($spacer * 3.75);
        @include margin-top($spacer * 3.75);
        @include margin-bottom($spacer * 3.75);
      }

      //stylelint-disable-next-line selector-no-qualifying-type
      &.spacer-half {
        @include padding-top($spacer * 7.5);
        @include padding-bottom($spacer * 7.5);
        @include margin-top($spacer * 1.875);
        @include margin-bottom($spacer * 1.875);
      }

      //stylelint-disable-next-line selector-no-qualifying-type
      &.spacer-half-bg {
        @include padding-top($spacer * 3.75);
        @include padding-bottom($spacer * 3.75);
        @include margin-top($spacer * 3.75);
        @include margin-bottom($spacer * 3.75);
      }

      //stylelint-disable-next-line selector-no-qualifying-type
      &.no-spacer-top {
        @include padding-top($spacer * 7.5);
        @include padding-bottom($spacer * 7.5);
        margin-top: 0;
      }

      //stylelint-disable-next-line selector-no-qualifying-type
      &.no-spacer-top-bg {
        padding-top: 0;
        @include margin-top($spacer * 3.75);
        @include margin-bottom($spacer * 3.75);
      }

      //stylelint-disable-next-line selector-no-qualifying-type
      &.no-spacer-bottom {
        @include padding-top($spacer * 7.5);
        @include padding-bottom($spacer * 7.5);
        margin-bottom: 0;
      }

      //stylelint-disable-next-line selector-no-qualifying-type
      &.no-spacer-bottom-bg {
        padding-bottom: 0;
        @include margin-top($spacer * 3.75);
        @include margin-bottom($spacer * 3.75);
      }

      //stylelint-disable-next-line selector-no-qualifying-type
      &.no-spacer-top-half-bg {
        @include padding-top($spacer * 3.75);
        @include padding-bottom($spacer * 3.75);
        @include margin-top($spacer * 3.75);
        margin-bottom: 0;
      }

      //stylelint-disable-next-line selector-no-qualifying-type
      &.no-spacer-bottom-half-bg {
        @include padding-top($spacer * 3.75);
        @include padding-bottom($spacer * 3.75);
        margin-top: 0;
        @include margin-bottom($spacer * 3.75);
      }

      //stylelint-disable-next-line selector-no-qualifying-type
      &.no-spacer-half-bg {
        @include padding-top($spacer * 3.75);
        @include padding-bottom($spacer * 3.75);
        margin-top: 0;
        margin-bottom: 0;
      }

      //stylelint-disable-next-line selector-no-qualifying-type
      &.no-spacer {
        @include padding-top($spacer * 7.5);
        @include padding-bottom($spacer * 7.5);
        margin-top: 0;
        margin-bottom: 0;
      }

      //stylelint-disable-next-line selector-no-qualifying-type
      &.no-spacer-bg {
        padding-top: 0;
        padding-bottom: 0;
        @include margin-top($spacer * 3.75);
        @include margin-bottom($spacer * 3.75);
      }
    }

    //stylelint-disable-next-line selector-no-qualifying-type
    &.image-background-tile {
      @include padding-bottom($spacer * 7.5);
    }

    //stylelint-disable-next-line selector-no-qualifying-type
    &.spacer-top-half {
      @include padding-top($spacer * 3.75);
    }

    //stylelint-disable-next-line selector-no-qualifying-type
    &.spacer-bottom-half {
      @include padding-bottom($spacer * 1.875);
    }

    //stylelint-disable-next-line selector-no-qualifying-type
    &.spacer-half {
      @include padding-top($spacer * 3.75);
      @include padding-bottom($spacer * 1.875);
    }

    //stylelint-disable-next-line selector-no-qualifying-type
    &.no-spacer-top {
      padding-top: 0;
    }

    //stylelint-disable-next-line selector-no-qualifying-type
    &.no-spacer-bottom {
      padding-bottom: 0;
    }

    //stylelint-disable-next-line selector-no-qualifying-type
    &.no-spacer {
      padding-top: 0;
      padding-bottom: 0;
    }

    ~ section {
      @include padding-top($spacer * 3.75);
      //stylelint-disable-next-line selector-no-qualifying-type
      &[class*="bg-"],
      &[class*="has-bg"],
      &[class*="has-image-bg"] {
        @include padding-top($spacer * 7.5);
        @include padding-bottom($spacer * 7.5);
        @include margin-top($spacer * 3.75);
        @include margin-bottom($spacer * 3.75);
        //stylelint-disable-next-line selector-no-qualifying-type
        &.image-background-tile {
          @include padding-bottom($spacer * 8.75);
        }

        //stylelint-disable-next-line selector-no-qualifying-type
        &.spacer-top-half {
          @include padding-top($spacer * 7.5);
          @include padding-bottom($spacer * 7.5);
          @include margin-top($spacer * 1.875);
        }

        //stylelint-disable-next-line selector-no-qualifying-type
        &.spacer-top-half-bg {
          @include padding-top($spacer * 3.75);
          @include margin-top($spacer * 3.75);
          @include margin-bottom($spacer * 3.75);
        }

        //stylelint-disable-next-line selector-no-qualifying-type
        &.spacer-bottom-half {
          @include padding-top($spacer * 7.5);
          @include padding-bottom($spacer * 7.5);
          @include margin-bottom($spacer * 1.875);
        }

        //stylelint-disable-next-line selector-no-qualifying-type
        &.spacer-bottom-half-bg {
          @include padding-bottom($spacer * 3.75);
          @include margin-top($spacer * 3.75);
          @include margin-bottom($spacer * 3.75);
        }

        //stylelint-disable-next-line selector-no-qualifying-type
        &.spacer-half {
          @include padding-top($spacer * 7.5);
          @include padding-bottom($spacer * 7.5);
          @include margin-top($spacer * 1.875);
          @include margin-bottom($spacer * 1.875);
        }

        //stylelint-disable-next-line selector-no-qualifying-type
        &.spacer-half-bg {
          @include padding-top($spacer * 3.75);
          @include padding-bottom($spacer * 3.75);
          @include margin-top($spacer * 3.75);
          @include margin-bottom($spacer * 3.75);
        }

        //stylelint-disable-next-line selector-no-qualifying-type
        &.no-spacer-top {
          @include padding-top($spacer * 7.5);
          @include padding-bottom($spacer * 7.5);
          margin-top: 0;
        }

        //stylelint-disable-next-line selector-no-qualifying-type
        &.no-spacer-top-bg {
          padding-top: 0;
          @include margin-top($spacer * 3.75);
          @include margin-bottom($spacer * 3.75);
        }

        //stylelint-disable-next-line selector-no-qualifying-type
        &.no-spacer-bottom {
          @include padding-top($spacer * 7.5);
          @include padding-bottom($spacer * 7.5);
          margin-bottom: 0;
        }

        //stylelint-disable-next-line selector-no-qualifying-type
        &.no-spacer-bottom-bg {
          padding-bottom: 0;
          @include margin-top($spacer * 3.75);
          @include margin-bottom($spacer * 3.75);
        }

        //stylelint-disable-next-line selector-no-qualifying-type
        &.no-spacer-top-half-bg {
          @include padding-top($spacer * 3.75);
          @include padding-bottom($spacer * 3.75);
          @include margin-top($spacer * 3.75);
          margin-bottom: 0;
        }

        //stylelint-disable-next-line selector-no-qualifying-type
        &.no-spacer-bottom-half-bg {
          @include padding-top($spacer * 3.75);
          @include padding-bottom($spacer * 3.75);
          margin-top: 0;
          @include margin-bottom($spacer * 3.75);
        }

        //stylelint-disable-next-line selector-no-qualifying-type
        &.no-spacer-half-bg {
          @include padding-top($spacer * 3.75);
          @include padding-bottom($spacer * 3.75);
          margin-top: 0;
          margin-bottom: 0;
        }

        //stylelint-disable-next-line selector-no-qualifying-type
        &.no-spacer {
          @include padding-top($spacer * 7.5);
          @include padding-bottom($spacer * 7.5);
          margin-top: 0;
          margin-bottom: 0;
        }

        //stylelint-disable-next-line selector-no-qualifying-type
        &.no-spacer-bg {
          padding-top: 0;
          padding-bottom: 0;
          @include margin-top($spacer * 3.75);
          @include margin-bottom($spacer * 3.75);
        }
      }

      //stylelint-disable-next-line selector-no-qualifying-type
      &.spacer-top-half {
        @include padding-top($spacer * 1.875);
      }

      //stylelint-disable-next-line selector-no-qualifying-type
      &.spacer-bottom-half {
        @include padding-bottom($spacer * 1.875);
      }

      //stylelint-disable-next-line selector-no-qualifying-type
      &.spacer-half {
        @include padding-top($spacer * 1.875);
        @include padding-bottom($spacer * 1.875);
      }

      //stylelint-disable-next-line selector-no-qualifying-type
      &.no-spacer-top {
        padding-top: 0;
      }

      //stylelint-disable-next-line selector-no-qualifying-type
      &.no-spacer-bottom {
        padding-bottom: 0;
      }

      //stylelint-disable-next-line selector-no-qualifying-type
      &.no-spacer {
        padding-top: 0;
        padding-bottom: 0;
      }
    }

    &:nth-last-child(-n+1 of :not([class*="d-none"])) {
      @include padding-bottom($spacer * 7.5);
      //stylelint-disable-next-line selector-no-qualifying-type
      &[class*="bg-"],
      &[class*="has-bg"],
      &[class*="has-image-bg"] {
        margin-bottom: 0;
        //stylelint-disable-next-line selector-no-qualifying-type
        &.spacer-top-half {
          @include padding-top($spacer * 7.5);
          @include padding-bottom($spacer * 7.5);
          @include margin-top($spacer * 1.875);
        }

        //stylelint-disable-next-line selector-no-qualifying-type
        &.spacer-top-half-bg {
          @include padding-top($spacer * 3.75);
          @include margin-top($spacer * 3.75);
        }

        //stylelint-disable-next-line selector-no-qualifying-type
        &.spacer-bottom-half {
          @include padding-top($spacer * 7.5);
          @include padding-bottom($spacer * 7.5);
        }

        //stylelint-disable-next-line selector-no-qualifying-type
        &.spacer-bottom-half-bg {
          @include padding-bottom($spacer * 3.75);
          @include margin-top($spacer * 3.75);
        }

        //stylelint-disable-next-line selector-no-qualifying-type
        &.spacer-half {
          @include padding-top($spacer * 7.5);
          @include padding-bottom($spacer * 7.5);
          @include margin-top($spacer * 1.875);
        }

        //stylelint-disable-next-line selector-no-qualifying-type
        &.spacer-half-bg {
          @include padding-top($spacer * 3.75);
          @include padding-bottom($spacer * 3.75);
          @include margin-top($spacer * 3.75);
        }

        //stylelint-disable-next-line selector-no-qualifying-type
        &.no-spacer-top {
          @include padding-top($spacer * 7.5);
          @include padding-bottom($spacer * 7.5);
          margin-top: 0;
        }

        //stylelint-disable-next-line selector-no-qualifying-type
        &.no-spacer-top-bg {
          padding-top: 0;
          @include margin-top($spacer * 3.75);
        }

        //stylelint-disable-next-line selector-no-qualifying-type
        &.no-spacer-bottom {
          @include padding-top($spacer * 7.5);
          @include padding-bottom($spacer * 7.5);
        }

        //stylelint-disable-next-line selector-no-qualifying-type
        &.no-spacer-bottom-bg {
          padding-bottom: 0;
          @include margin-top($spacer * 3.75);
        }

        //stylelint-disable-next-line selector-no-qualifying-type
        &.no-spacer-top-half-bg {
          @include padding-top($spacer * 3.75);
          @include padding-bottom($spacer * 3.75);
          @include margin-top($spacer * 3.75);
          margin-bottom: 0;
        }

        //stylelint-disable-next-line selector-no-qualifying-type
        &.no-spacer-bottom-half-bg {
          @include padding-top($spacer * 3.75);
          @include padding-bottom($spacer * 3.75);
          margin-top: 0;
          @include margin-bottom($spacer * 3.75);
        }

        //stylelint-disable-next-line selector-no-qualifying-type
        &.no-spacer-half-bg {
          @include padding-top($spacer * 3.75);
          @include padding-bottom($spacer * 3.75);
          margin-top: 0;
          margin-bottom: 0;
        }

        //stylelint-disable-next-line selector-no-qualifying-type
        &.no-spacer {
          @include padding-top($spacer * 7.5);
          @include padding-bottom($spacer * 7.5);
          margin-top: 0;
        }

        //stylelint-disable-next-line selector-no-qualifying-type
        &.no-spacer-bg {
          padding-top: 0;
          padding-bottom: 0;
          @include margin-top($spacer * 3.75);
        }
      }

      //stylelint-disable-next-line selector-no-qualifying-type
      &.image-background-tile {
        @include padding-bottom($spacer * 8.75);
      }

      //stylelint-disable-next-line selector-no-qualifying-type
      &[class*="bg-"] {
        margin-bottom: 0;
      }
    }
  }

  hr {
    @include margin-top($spacer * 3.75);
    @include margin-bottom($spacer * 3.75);
    opacity: 1;
    //stylelint-disable-next-line selector-no-qualifying-type
    &.spacer-top-half {
      @include margin-top($spacer * 1.875);
    }

    //stylelint-disable-next-line selector-no-qualifying-type
    &.spacer-bottom-half {
      @include margin-bottom($spacer * 1.875);
    }

    //stylelint-disable-next-line selector-no-qualifying-type
    &.spacer-half {
      @include margin-top($spacer * 1.875);
      @include margin-bottom($spacer * 1.875);
    }

    //stylelint-disable-next-line selector-no-qualifying-type
    &.no-spacer-top {
      margin-top: 0;
    }

    //stylelint-disable-next-line selector-no-qualifying-type
    &.no-spacer-bottom {
      margin-bottom: 0;
    }

    //stylelint-disable-next-line selector-no-qualifying-type
    &.no-spacer {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
}

//stylelint-disable-next-line selector-no-qualifying-type
section.move {
  @include padding-top($spacer * 7.5);
  @include padding-bottom($spacer * 7.5);
  //stylelint-disable-next-line selector-no-qualifying-type
  &.image-background-tile {
    @include padding-bottom($spacer * 8.75);
  }
}

// stylelint-enable selector-max-id
.legal {
  //stylelint-disable-next-line declaration-no-important
  font-size: $sub-sup-font-size !important;
  font-weight: $font-weight-normal;
}

// stylelint-disable-next-line selector-max-id
#wpadminbar {
  position: inherit;
  display: flow-root;
}

a {
  font-weight: $font-weight-medium;
  color: $interactive;
  @include transition(color .15s ease-in-out);
}

.legal-links {
  padding-left: 0;
  //stylelint-disable-next-line declaration-no-important
  font-size: $sub-sup-font-size !important;
  font-weight: $font-weight-normal;
  text-align: center;
  list-style: none;

  li {
    display: inline-block;

    &:not(:last-child) {
      padding-right: $spacer * .25;
      margin-right: $spacer * .25;
      border-right: rfs-value(1px) solid $body-color;
    }
  }
}

.modal-dialog-media .modal-header .btn-close {
  @include border-radius($border-radius-pill);
  //stylelint-disable-next-line declaration-no-important
  background: transparent var(--bs-btn-close-media-bg) center/2.1875rem 100% no-repeat !important;
}
