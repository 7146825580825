.single-employees {
  .header-buttons {
    a {
      @include transition($transition-base);
    }
  }

  .attached-nav {
    a {
      display: block;
      @include border-radius(0);
      width: 100%;
    }
  }

  .subpage-banner {
    position: relative;
    height: 400px;
    overflow: hidden;
    background-color: $primary;

    &.default {
      background-image: url("../images/employees-banner.jpg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
    }

    @include media-breakpoint-down(sm) {
      display: none;
    }
  }

  .loan-officer-info {
    @include margin-top(- $spacer * 2.5);
    position: relative;
    z-index: 2;
    // stylelint-disable-next-line declaration-no-important
    padding-top: 0 !important;

    .content-holder {
      padding: $spacer * 3;
      background-color: $white;
      @include border-radius($border-radius-xxxl);
    }

    .headshot {
      display: block;
      width: 235px;
      height: 210px;
      margin: 0 auto;
      object-fit: cover;
      object-position: center;
      @include border-radius($border-radius-xxl);
      @include media-breakpoint-down(lg) {
        margin: 0 0 $spacer;
      }
    }

    .location {
      @extend .fs-4;
      margin-bottom: $spacer * .75;

      i {
        @extend .fs-3;
        width: 16px;
        height: 16px;
        margin-right: $spacer * .5;
        color: $interactive;
        text-align: center;
      }
    }

    a, {
      @extend .fs-4;
      display: block;
      width: fit-content;

      &:not(:last-child) {
        margin-bottom: $spacer * .75;
      }

      i {
        @extend .fs-3;
        width: 16px;
        height: 16px;
        margin-right: $spacer * .5;
        text-align: center;
      }
    }

    .nmls {
      @extend .fs-5;
      display: inline-block;
      width: fit-content;
      font-weight: $font-weight-bold;

      a {
        display: inline-block;
        width: fit-content;
        margin-bottom: 0;
      }

      i {
        @extend .fs-5;
        width: 14px;
        height: 14px;
        margin-left: $spacer * .25;
        color: $interactive;
        text-align: center;
      }
    }

    .officer-info,
    .more-info {
      border-top: rfs-value(1px) solid $gray-200;
      @include margin-top($spacer * 2);
      @include padding-top($spacer * 2);
    }
  }

  .borrower-testimonials {
    background-color: $denim;

    h1 {
      color: $white;
      text-align: center;
      @include margin-bottom($spacer * 2);
    }

    .testimonial-single {
      height: 100%;
      padding: $spacer * 2.5;
      background-color: $white;
    }

    .name {
      @extend .fs-1;
      margin-bottom: 0;
      font-weight: $font-weight-bold;
    }
  }
}
