.testimonials-cards {
  background-color: $denim-500;

  .slick-track {
    //stylelint-disable-next-line declaration-no-important
    display: flex !important;
  }

  .slick-slide {
    @include make-col-ready();
    //stylelint-disable-next-line declaration-no-important
    height: inherit !important;

    .card {
      height: 100%;
      padding: $spacer * 2.5;
    }
  }

  .slick-next,
  .slick-prev {
    z-index: 9;
    width: 64px;
    height: 64px;
    background-color: $interactive-500;
    opacity: 1;
    @include border-radius($border-radius-pill);
    @include transition(background-color .15s ease-in-out);
    @include media-breakpoint-down(sm) {
      width: 48px;
      height: 48px;
    }
    &:hover,
    &:focus {
      background-color: $interactive-425;
    }
    &::before {
      font-size: 24px;
      color: $white;
      opacity: 1;
      @extend %fa-icon;
      @extend .fa-solid;
      @include border-radius($border-radius-pill);
    }
  }

  .slick-next {
    &::before {
      content: fa-content($fa-var-arrow-right);
    }
  }

  .slick-prev {
    &::before {
      content: fa-content($fa-var-arrow-left);
    }
  }

  .rating-date {
    div {
      float: left;
    }
    p {
      float: right;
    }
  }
  .name {
    @extend .fs-4;
    font-weight: $font-weight-bold;
  }

  .disclaimer {
    @include padding-top($spacer * 3);
    text-align: center;

    p {
      margin-bottom: 0;
      color: $light;
    }
  }
}
