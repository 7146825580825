.alert-banner {
  position: relative;
  top: 0;
  width: 100%;
  padding: $spacer * .75 0;
  color: $denim;
  background: #f4f4f4;
  //stylelint-disable-next-line
  transition: top .3s;
  .icon {
    display: none;
    margin-right: $spacer * 1;
    font-size: $spacer * 2;
  }
  .content {
    display: inline-block;
    width: 100%;
    margin: 0;
    font-size: $spacer * .75;
    text-align: center;
    @include media-breakpoint-down(sm){
      font-size: $spacer * .688;
    }
    @media screen and (max-width: 375px){
      font-size: $spacer * .65;
    }
    a {
      color: $interactive;
    }
  }
  .alert-close {
    display: none;
    margin-left: $spacer * 1;
    color: $electric;
    background-color: transparent;
    border: 0;
    &:hover {
      color: $white;
      //stylelint-disable-next-line
      transition: color ease .3s;
    }
  }
}
