.customer-stories-intro {
  position: relative;
  z-index: 2;
  @include margin-top($spacer * -2);
  .customer-stories-intro-box {
    @include border-radius($border-radius-xxxl);
    @include padding(24px);
    background: $white;

    .intro-box-content {
      @include padding(0 $spacer * 2 0 $spacer * 2.25);
      @include media-breakpoint-down(md) {
        @include padding($spacer * 2 0 0);
      }
      p {
        margin-bottom: 0;
      }
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .image-container {
      position: relative;
      height: 100%;

      img {
        @include border-radius($border-radius-lg);
        height: 100%;
        overflow: hidden;
        object-fit: cover;
      }

      button {
        position: relative;
        width: 100%;
        height: 100%;
        padding: 0;
        appearance: none;
        background: transparent;
        border: 0;
        outline: 0;

        //stylelint-disable-next-line selector-max-compound-selectors
        i {
          position: absolute;
          //stylelint-disable function-disallowed-list
          top: calc(50% - 36px);
          left: calc(50% - 36px);
          //stylelint-enable function-disallowed-list
          font-size: rfs-value(72px);
          color: $white;
          @include box-shadow($box-shadow-xxl);
          @include transition(all .15s ease-in-out);
          @include border-radius($border-radius-pill);
        }

        &:hover {
          //stylelint-disable-next-line selector-max-compound-selectors
          i {
            @include box-shadow($box-shadow-xxxl);
            //stylelint-disable function-disallowed-list
            top: calc(50% - 40px);
            left: calc(50% - 40px);
            //stylelint-enable function-disallowed-list
            font-size: rfs-value(80px);
          }
        }
      }
    }
  }
}
