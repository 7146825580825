.video-slider {
  position: relative;
  h1 {
    @include margin-bottom($spacer * 1.5);
  }

  .heading {
    p {
      @include margin(auto);
      @include padding(0 $spacer * 1.5);
      max-width: 860px;
    }
  }

  .image-container {
    @include box-shadow($box-shadow-xxl);
    @include border-radius($border-radius-xl);
    background-color: $white;
    @include padding($spacer * 2);

    img {
      max-height: 600px;
    }
  }

  //stylelint-disable-next-line selector-no-qualifying-type
  button.video {
    position: relative;
    padding: 0;
    background: transparent;
    border: 0;

    &::after {
      @extend %fa-icon;
      @extend .fa-solid;
      position: absolute;
      //stylelint-disable function-disallowed-list
      top: calc(50% - 36px);
      left: calc(50% - 36px);
      //stylelint-enable function-disallowed-list
      font-size: rfs-value(72px);
      color: $white;
      content: fa-content($fa-var-circle-play);
      @include box-shadow($box-shadow-xxl);
      @include transition(all .15s ease-in-out);
      @include border-radius($border-radius-pill);

      @include media-breakpoint-down(sm) {
        font-size: rfs-value(56px);
      }
    }

    &:hover {
      &::after {
        @include box-shadow($box-shadow-xxxl);
        //stylelint-disable function-disallowed-list
        top: calc(50% - 40px);
        left: calc(50% - 40px);
        //stylelint-enable function-disallowed-list
        font-size: rfs-value(80px);
        @include media-breakpoint-down(sm) {
          font-size: rfs-value(72px);
        }
      }
    }
  }

  .carousel-controls-holder {
    position: absolute;
    bottom: $spacer * -2.5;
    //stylelint-disable-next-line function-disallowed-list
    left: calc(50% - 80px);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 160px;

    .carousel-count {
      text-align: center;
    }

    button {
      opacity: 1;

      i {
        width: rfs-value(48px);
        height: rfs-value(48px);
        padding: rfs-value(12px) rfs-value(14px);
        font-size: rfs-value(24px);
        color: $interactive;
        @include border-radius($border-radius-pill);
        @include transition(all ease .2s);
      }

      &:hover {
        i {
          background-color: $interactive-100;
          @include transition(all ease .2s);
        }
      }
    }
  }
  .carousel-controls {
    i {
      cursor: pointer;
    }

    .fa-arrow-left::before,
    .fa-arrow-right::before {
      color: $interactive-500;
      @include padding($spacer * .75 $spacer * .844);
      @include transition(all .15s ease-in-out);
      @include border-radius($border-radius-pill);
    }

    .fa-arrow-left:hover::before,
    .fa-arrow-right:hover::before {
      background-color: $interactive-100;
    }
  }
}
