.wp-block-media-text {
  display: grid;
  grid-template-columns: 50% auto;
  column-gap: 50px;
  //stylelint-disable-next-line declaration-no-important
  width: 100% !important;
  //stylelint-disable-next-line declaration-no-important
  max-width: 100% !important;
  padding: $spacer * 1.5 0;
  //stylelint-disable-next-line declaration-no-important
  margin-right: 0 !important;
  //stylelint-disable-next-line declaration-no-important
  margin-left: 0 !important;
  &.is-stacked-on-mobile {
    @include media-breakpoint-down(md) {
      display: inherit;
      grid-template-columns: 100%;
    }
  }
  &.has-media-on-the-right {
    //stylelint-disable-next-line selector-class-pattern
    .wp-block-media-text__media {
      grid-row: 1;
      grid-column: 2;
    }
    //stylelint-disable-next-line selector-class-pattern
    .wp-block-media-text__content {
      grid-row: 1;
      grid-column: 1;
    }
  }
  //stylelint-disable-next-line scss/selector-no-union-class-name
  &__media {
    position: relative;
    overflow: hidden;
    @include border-radius($border-radius);
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    figcaption {
      position: absolute;
      bottom: 0;
      width: 100%;
      padding: $spacer * 1.5;
      margin: 0;
      font-style: italic;
      color: $denim;
      background-color: #fffffff2;
      @include font-size(16px);
    }
  }
  &.header-bg {
    h2 {
      position: relative;
      display: inline-block;
      padding: 0 $spacer * .625 $spacer * .625;
      margin-bottom: $spacer * .875;
      &::after {
        position: absolute;
        bottom: -4px;
        left: 0;
        z-index: -1;
        //stylelint-disable-next-line function-disallowed-list
        width: calc(100% + 15px);
        height: 35px;
        content: "";
        background: #00a9e033;
        @include border-radius($border-radius-sm);

        @include media-breakpoint-down(sm) {
          display: none;
          height: 30px;
        }
      }
    }
  }
}
