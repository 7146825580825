.accordion {
  --#{$prefix}accordion-box-shadow: #{$accordion-box-shadow};
  --#{$prefix}accordion-btn-icon-color: #{$accordion-button-icon-color};

  background-color: var(--#{$prefix}accordion-bg);
  @include padding($accordion-padding);
  @include box-shadow(var(--#{$prefix}accordion-box-shadow));
  @include border-radius(var(--#{$prefix}accordion-border-radius));
}
.accordion-button {
  @extend .fs-1;
  font-weight: $font-weight-bold;
  &:not(.collapsed) {
    @include box-shadow(none);
  }
  &:focus {
    z-index: 3;
    border-color: transparent;
    outline: 0;
    @include box-shadow(none);
  }
  &:focus-visible {
    z-index: 3;
    border-color: var(--#{$prefix}accordion-btn-focus-border-color);
    outline: 0;
    @include box-shadow(var(--#{$prefix}accordion-btn-focus-box-shadow));
  }
}
.accordion-body {
  padding-top: 0;
  p:last-child {
    margin-bottom: 0;
  }
  .row {
    .image {
      padding-right: percentage(divide(.5, 12));
      padding-left: percentage(divide(.5, 12));
      @include media-breakpoint-down(lg) {
        @include margin($spacer * 2 auto 0);
      }
    }
  }
}

