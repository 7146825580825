//stylelint-disable selector-max-id
#education-nav {
  margin-top: $spacer * 1.5;
  @include padding-top($spacer * 2.5);
  @include padding-bottom($spacer * 2);
  &.accordion {
    .accordion-item {
      border: 0;
    }
    .accordion-button {
      @include padding($spacer * .5 0);
      @extend .fs-3;
      font-weight: $font-weight-medium;
      color: $denim;
      &::after {
        width: 16px;
        height: 16px;
        background-size: 16px;
      }
    }
    .accordion-body {
      padding-bottom: $spacer * .5;
      ul {
        padding-left: 0;
        margin-bottom: 0;
        list-style: none;
        li:not(:last-child) {
          @include margin-bottom($spacer * .6875);
        }
        li {
          //stylelint-disable-next-line selector-no-qualifying-type
          &.selected {
            //stylelint-disable-next-line selector-max-type, selector-max-compound-selectors
            a {
              font-weight: $font-weight-bold;
            }
          }
          //stylelint-disable-next-line selector-max-compound-selectors, selector-max-type
          a {
            @extend .fs-4;
            text-decoration: none;
          }
        }
      }
    }
  }
}
//stylelint-enable selector-max-id
