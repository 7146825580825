.post-type-archive-faq {
  .faq-header {
    position: relative;
    background-color: $denim;
    @include padding-top($spacer * 7.5);
    &::after {
      position: absolute;
      top: 0;
      right: 0;
      width: 68%;
      height: 100%;
      content: "";
      background-image: url($denim-pixel-bg);
      background-repeat: no-repeat;
      background-position: right bottom;
      background-size: 100%;
      @include media-breakpoint-down(lg) {
        width: 100%;
        background-size: cover;
      }
    }
    .row {
      position: relative;
      z-index: 2;
      justify-content: center;
    }
    h1 {
      color: $white;
      text-align: center;
      @include margin-bottom($spacer * 3);
    }
    .search-wrapper {
      @include margin-bottom($spacer * 4);
      position: relative;
    }
    nav {
      ul {
        display: block;
        width: fit-content;
        margin: 0 auto;
        //stylelint-disable-next-line selector-max-type, selector-max-compound-selectors
        li {
          display: inline-block;
          width: fit-content;
          font-weight: $font-weight-medium;
          color: $white;
          cursor: pointer;
          @include padding($spacer * 1 $spacer * 2);
          border-bottom: rfs-value(8px) solid transparent;
          @include transition($transition-base);
          @extend .fs-2;
          //stylelint-disable-next-line
          &.active {
            border-color: $electric;
          }
          //stylelint-disable-next-line
          &:hover {
            border-color: $electric;
          }
        }
      }
    }
  }
  .faqs-wrapper {
    @include transition(all .25s ease-in-out);
    &.hide {
      height: 0;
      //stylelint-disable-next-line declaration-no-important
      padding: 0 !important;
      overflow: hidden;
      visibility: hidden;
      opacity: 0;
      transform: scale(0);
    }
    .category-header {
      h1 {
        text-align: center;
        @include margin-bottom($spacer * 4);
      }
    }
  }
}
