.three-image-background-tile {
  h1 {
    @include margin-bottom($spacer * 1.25);
  }

  .heading {
    p {
      @include margin($spacer * 1.25);
    }
  }

  .tile-wrapper {
    display: flex;
    min-height: 480px;

    @include media-breakpoint-down(md) {
      min-height: 400px;
    }
  }

  .tile-holder {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    color: $white;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    @include border-radius($border-radius-xxxl);

    @include media-breakpoint-down(md) {
      @include margin-bottom($spacer * 3.75);
    }

    .tile-content {
      position: absolute;
      bottom: 0;
      @include padding($spacer * 4);

      @include media-breakpoint-down(lg) {
        @include padding($spacer * 3);
      }
      @include media-breakpoint-down(md) {
        @include padding($spacer * 4);
      }

      h3 {
        color: $white;
        @include margin-bottom($spacer);
      }

      img {
        max-height: 60px;
        @include margin-bottom($spacer);
      }

      p {
        @include margin-bottom($spacer * 2.5);
      }
    }
  }

  .legal {
    @include margin-top($spacer * 3);
    margin-bottom: 0;
  }
}
