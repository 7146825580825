blockquote {
  position: relative;
  padding-left: $spacer * 2.5;
  margin: $spacer * 1.5 0;
  color: $denim;
  @include font-size(40px);
  &::before {
    position: absolute;
    top: 0;
    left: rfs-value(5px);
    width: 4px;
    height: 100%;
    content: "";
    background-color: $electric;
  }
}

.blockquote {
  padding: $spacer * 1.5 0;

  .text {
    p {
      @extend .fs-1;
      color: $denim;
    }
  }

  &.normal {
    .text {
      position: relative;
      @include font-size(40px);
      padding-left: $spacer * 2.5;

      &::after {
        position: absolute;
        top: 0;
        left: rfs-value(5px);
        width: 4px;
        height: 100%;
        content: "";
        background-color: $electric;
      }

      p {
        @include font-size(40px);
        margin: 0;
      }
    }
  }

  &.inset-number {
    blockquote {
      position: relative;
      float: left;
      width: 330px;
      padding-top: $spacer * .3125;
      padding-left: $spacer * 2.5;
      margin: 0 $spacer * 1.5 $spacer * 1.25 0;
      color: $denim;

      @include media-breakpoint-down(sm) {
        float: none;
        width: 100%;
        padding-top: 0;
      }

      p {
        @extend .fs-1;
        margin: 0;
        font-weight: $font-weight-bold;
        color: $denim;
      }
    }

    .text {
      p {
        color: $black;
      }
    }
  }

  &.inset-text {
    blockquote {
      @include font-size(40px);
      position: relative;
      float: right;
      width: 50%;
      padding-left: $spacer * 2.5;
      margin: $spacer * .3125 0 $spacer * 1.5 $spacer * 1.5;
      color: $denim;

      @include media-breakpoint-down(sm) {
        float: none;
        width: 100%;
        margin: 0 0 $spacer * 1.5;
        word-break: break-word;
      }
    }

    p {
      color: $black;
    }
  }
}
