.form-control {
  @include box-shadow(none);
  &:focus {
    @if $enable-shadows {
      box-shadow: $input-focus-box-shadow;
    } @else {
      // Avoid using mixin so we can pass custom focus shadow properly
      box-shadow: $input-focus-box-shadow;
    }
  }
}

.form-control-lg {
  @include font-size($input-font-size-lg);
}
