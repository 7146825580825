.content-block {
  h1 {
    @include margin-bottom($spacer);
  }

  p {
    margin-bottom: 0;
  }
}

.parent-page-privacy-policy {
  .content-block {
    > .container {
      display: none;
    }
  }
}
