.page-springboard {
  .navbar-brand {
    .custom-text-logo {
      @include media-breakpoint-down(sm) {
        width: 0;
        opacity: 0;
      }
    }
  }
  .status-holder {
    margin-right: rfs-value(4px);
    .status {
      margin-right: $spacer;
      font-size: $font-size-sm;
    }
    a {
      font-size: $font-size-sm;
      color: $interactive;
      text-decoration: none;
      @include transition(color .15s ease-in-out);
      i {
        margin-right: $spacer * .5;
      }
      &:hover {
        color: $interactive-400;
      }
    }
  }
}
