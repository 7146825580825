.table {
  margin-bottom: 0;

  h1 {
    @include margin-bottom($spacer * 2);
    text-align: center;
  }

  p {
    @include margin-bottom($spacer * 4.5);
    text-align: center;
  }

  table {
    width: 100%;
    border-spacing: 0;
    //stylelint-disable declaration-no-important
    border-collapse: separate !important;

    thead {
      //stylelint-disable selector-max-type
      th {
        padding: $spacer;
        color: $white;
        text-align: center;
        background-color: $denim;
        border: rfs-value(1px) solid $black;
        border-right: 0;
        border-left: 0;

        &:first-child {
          @include border-top-start-radius($border-radius-lg);
          border-left: rfs-value(1px) solid $black;
        }

        &:last-child {
          @include border-top-end-radius($border-radius-lg);
          border-right: rfs-value(1px) solid $black;
        }
      }

      //stylelint-enable selector-max-type
    }

    tbody {
      border: rfs-value(1px) solid $gray-200;
      //stylelint-disable selector-max-type
      tr {
        border-bottom: 0;

        &:last-child {
          //stylelint-disable-next-line selector-max-compound-selectors
          td {
            border-bottom: rfs-value(1px) solid $gray-200;
          }
        }

        &:nth-child(odd) {
          background-color: $gray-100;
        }

        //stylelint-disable selector-max-compound-selectors
        td {
          color: $gray-800;
          text-align: center;
          border: rfs-value(1px) solid $gray-200;
          border-bottom: 0;
          border-left: 0;
          @include padding($spacer);

          &:first-child {
            border-left: rfs-value(1px) solid $gray-200;
          }

          &:not(:last-child) {
            border-right: rfs-value(1px) solid $gray-200;
          }
        }
      }

      //stylelint-enable selector-max-type
    }

    //stylelint-disable-next-line selector-no-qualifying-type
    &.has-header {
      tbody {
        //stylelint-disable selector-max-type
        tr {
          &:first-child {
            td {
              border-top: 0;
            }
          }
        }

        //stylelint-enable selector-max-type
      }
    }
  }
}
