.listicles {
  margin-bottom: $spacer;
  clear: both;

  &.fullwidth {
    display: flex;
    flex-direction: row;
    gap: $spacer * .625;

    big {
      display: block;
      flex: $spacer * 6.25;
      font-weight: $font-weight-light;
      line-height: 1;
      color: $electric;
      text-align: center;
      @include font-size(119px);

      @include media-breakpoint-down(sm) {
        flex: $spacer * 3.125;
        @include font-size(60px);
      }
    }

    .text {
      flex: 100%;
      h2 {
        padding-left: $spacer * .875;
      }
    }
  }


  h2 {
    position: relative;
    display: inline;
    align-items: center;
    margin-bottom: $spacer * 1.25;
    font-weight: $font-weight-bold;
    line-height: 1.5em;
    background-image: linear-gradient(to bottom, transparent 50%, rgba($electric, .2) 0);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    @include font-size(40px);

    @include media-breakpoint-down(sm) {
      align-items: flex-start;
    }

    .count {
      position: relative;
      margin: 0 $spacer * .625 0 $spacer * .25;
      font-weight: $font-weight-bold;
      color: $electric;
      @include font-size(36px);
    }
  }


  img {
    width: 350px;
    height: 350px;
    margin-bottom: $spacer * 1.5;
    object-fit: cover;
    @include border-radius($border-radius-sm);

    @include media-breakpoint-down(md) {
      //stylelint-disable-next-line declaration-no-important
      float: none !important;
      width: 100%;
      //stylelint-disable-next-line declaration-no-important
      margin: 0 0 $spacer * 1.5 !important;
    }

    @include media-breakpoint-down(sm) {
      height: 300px;
    }

    //stylelint-disable-next-line selector-no-qualifying-type
    &.right {
      float: right;
      margin-left: $spacer * 1.5;
    }

    //stylelint-disable-next-line selector-no-qualifying-type
    &.left {
      float: left;
      margin-right: $spacer * 1.5;
    }
  }
}
