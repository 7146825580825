.text-image-collage {
  min-height: 200px;

  .container {
    position: relative;
  }

  .text-content {
    .content-container {
      @include padding($spacer * 3);

      h1 {
        @include margin-bottom($spacer * 2);
      }

      p {
        @include font-size(18px);
        margin-bottom: 0;
        //stylelint-disable-next-line selector-no-qualifying-type
        &.legal {
          margin-bottom: 0;
          @include margin-top($spacer * 2);
        }
      }

      .button-holder {
        @include margin-top($spacer * 2.5);
        //stylelint-disable selector-max-compound-selectors
        a,
        button {
          &:not(:last-child) {
            @include margin-right($spacer * 1.5);
            @include media-breakpoint-down(md) {
              margin-right: 0;
              @include margin-bottom($spacer * 1.5);
            }
          }

          @include media-breakpoint-down(md) {
            display: block;
            width: 100%;
          }
        }
        //stylelint-enable selector-max-compound-selectors
      }
    }
  }

  .collage-holder {
    position: relative;
    height: 100%;
    @include media-breakpoint-down(lg) {
      @include padding-left($spacer * 3);
      @include padding-right($spacer * 3);
    }
  }

  .three-image {
    @include media-breakpoint-down(lg) {
      @include margin-top($spacer * 2);
    }

    button,
    .single-image {
      @include border-radius($border-radius-xxxl);
      margin-right: 2%;
      margin-left: 2%;
      //stylelint-disable-next-line selector-no-qualifying-type
      &[class*="bg-"] {
        @include padding($spacer * 4 $spacer * 2);
        @include box-shadow($box-shadow-xxl);
      }

      &:nth-child(1) {
        position: relative;
        top: 0;
        width: 45%;
        margin-left: 0;
        @include media-breakpoint-down(sm) {
          width: 50%;
        }
        img {
          @include border-radius($border-radius-xxxl);
        }
      }

      &:nth-child(2) {
        position: relative;
        width: 35%;
        margin-top: -20%;
        margin-left: 47%;
        @include media-breakpoint-down(sm) {
          width: 45%;
          margin-top: -25%;
          margin-left: 55%;
        }
        img {
          @include border-radius($border-radius-xxxl);
        }
      }

      &:nth-child(3) {
        position: relative;
        width: 40%;
        margin-top: -23%;
        margin-left: 5%;
        @include media-breakpoint-down(sm) {
          width: 45%;
          margin-top: -27%;
        }
        img {
          @include border-radius($border-radius-xxxl);
        }
      }

      .image-container {
        position: relative;
        display: block;
        // stylelint-disable-next-line selector-max-compound-selectors
        .image-text {
          position: absolute;
          bottom: 0;
          left: 16px;
          z-index: 1;
          text-align: left;
          // stylelint-disable-next-line selector-max-combinators, selector-max-compound-selectors
          h5 {
            margin-bottom: 0;
            font-weight: $font-weight-bold;
            color: $light;
            @include media-breakpoint-down(xxl) {
              @include font-size(14px);
            }
            @include media-breakpoint-down(md) {
              @include font-size(12px);
            }
          }
          // stylelint-disable-next-line selector-max-combinators, selector-max-compound-selectors
          p {
            color: $light;
            @include font-size(14px);
            @include media-breakpoint-down(xxl) {
              @include font-size(14px);
            }
            @include media-breakpoint-down(md) {
              @include font-size(10px);
            }
          }
        }
      }

      .image-text {
        position: absolute;
        bottom: 0;
        left: 16px;
        z-index: 1;
        text-align: left;
        // stylelint-disable-next-line selector-max-compound-selectors
        h5 {
          margin-bottom: 0;
          font-weight: $font-weight-bold;
          color: $light;
          @include media-breakpoint-down(xxl) {
            @include font-size(14px);
          }
          @include media-breakpoint-down(md) {
            @include font-size(12px);
          }
        }
        // stylelint-disable-next-line selector-max-compound-selectors
        p {
          color: $light;
          @include font-size(14px);
          @include media-breakpoint-down(xxl) {
            @include font-size(12px);
          }
          @include media-breakpoint-down(md) {
            @include font-size(10px);
          }
        }
      }
    }

    button {
      position: relative;
      padding: 0;
      background: transparent;
      border: 0;

      &::after {
        @extend %fa-icon;
        @extend .fa-solid;
        position: absolute;
        //stylelint-disable function-disallowed-list
        top: calc(50% - 36px);
        left: calc(50% - 36px);
        //stylelint-enable function-disallowed-list
        font-size: rfs-value(72px);
        color: $white;
        content: fa-content($fa-var-circle-play);
        @include box-shadow($box-shadow-xxl);
        @include transition(all .15s ease-in-out);
        @include border-radius($border-radius-pill);

        @include media-breakpoint-down(sm) {
          font-size: rfs-value(56px);
        }
      }

      &:hover {
        &::after {
          @include box-shadow($box-shadow-xxxl);
          //stylelint-disable function-disallowed-list
          top: calc(50% - 40px);
          left: calc(50% - 40px);
          //stylelint-enable function-disallowed-list
          font-size: rfs-value(80px);
          @include media-breakpoint-down(sm) {
            font-size: rfs-value(72px);
          }
        }
      }

      img {
        //stylelint-disable declaration-no-important
        position: inherit !important;
        top: 0 !important;
        width: 100% !important;
        margin: 0 !important;
        @include border-radius($border-radius-xxxl);
        //stylelint-enable declaration-no-important
      }
    }
  }

  .four-image {
    @include media-breakpoint-down(lg) {
      @include margin-top($spacer * 2);
    }

    button,
    .single-image {
      @include border-radius($border-radius-xxxl);
      margin-right: 2%;
      margin-left: 2%;
      //stylelint-disable-next-line selector-no-qualifying-type
      &[class*="bg-"] {
        @include padding($spacer * 4 $spacer * 2);
        @include box-shadow($box-shadow-xxl);
      }

      &:nth-child(1) {
        position: relative;
        top: 0;
        width: 45%;
        margin-left: 0;
        @include media-breakpoint-down(sm) {
          width: 50%;
        }
      }

      &:nth-child(2) {
        position: relative;
        width: 35%;
        margin-top: -40%;
        margin-left: 47%;
        @include media-breakpoint-down(sm) {
          width: 45%;
          margin-top: -45%;
          margin-left: 55%;
        }
        img {
          @include border-radius($border-radius-xxxl);
        }
      }

      &:nth-child(3) {
        position: relative;
        width: 40%;
        margin-top: -3%;
        margin-left: 5%;
        @include media-breakpoint-down(sm) {
          width: 45%;
          margin-top: -7%;
          margin-left: 5%;
        }
        img {
          @include border-radius($border-radius-xxxl);
        }
      }

      &:nth-child(4) {
        position: relative;
        width: 41.5%;
        margin-top: -24%;
        margin-left: 47%;
        @include media-breakpoint-down(sm) {
          width: 45%;
          margin-top: -19%;
          margin-left: 55%;
        }
        img {
          @include border-radius($border-radius-xxxl);
        }
      }

      .image-container {
        position: relative;
        display: block;
        // stylelint-disable-next-line selector-max-compound-selectors
        .image-text {
          position: absolute;
          bottom: 0;
          left: 16px;
          z-index: 1;
          text-align: left;
          // stylelint-disable-next-line selector-max-combinators, selector-max-compound-selectors
          h5 {
            margin-bottom: 0;
            font-weight: $font-weight-bold;
            color: $light;
            @include media-breakpoint-down(xxl) {
              @include font-size(18px);
            }
            @include media-breakpoint-down(sm) {
              @include font-size(14px);
            }
          }
          // stylelint-disable-next-line selector-max-combinators, selector-max-compound-selectors
          p {
            color: $light;
            @include font-size(14px);
            @include media-breakpoint-down(sm) {
              @include font-size(14px);
            }
          }
        }
      }

      .image-text {
        position: absolute;
        bottom: 0;
        left: 16px;
        z-index: 1;
        text-align: left;
        // stylelint-disable-next-line selector-max-compound-selectors
        h5 {
          margin-bottom: 0;
          font-weight: $font-weight-bold;
          color: $light;
          @include media-breakpoint-down(xxl) {
            @include font-size(18px);
          }
          @include media-breakpoint-down(sm) {
            @include font-size(14px);
          }
        }
        // stylelint-disable-next-line selector-max-compound-selectors
        p {
          color: $light;
          @include font-size(14px);
          @include media-breakpoint-down(sm) {
            @include font-size(14px);
          }
        }
      }
    }

    button {
      position: relative;
      padding: 0;
      background: transparent;
      border: 0;

      &::after {
        @extend %fa-icon;
        @extend .fa-solid;
        position: absolute;
        //stylelint-disable function-disallowed-list
        top: calc(50% - 36px);
        left: calc(50% - 36px);
        //stylelint-enable function-disallowed-list
        font-size: rfs-value(72px);
        color: $white;
        content: fa-content($fa-var-circle-play);
        @include box-shadow($box-shadow-xxl);
        @include transition(all .15s ease-in-out);
        @include border-radius($border-radius-pill);
        @include media-breakpoint-down(sm) {
          font-size: rfs-value(56px);
        }
      }

      &:hover {
        &::after {
          @include box-shadow($box-shadow-xxxl);
          //stylelint-disable function-disallowed-list
          top: calc(50% - 40px);
          left: calc(50% - 40px);
          //stylelint-enable function-disallowed-list
          font-size: rfs-value(80px);
          @include media-breakpoint-down(sm) {
            font-size: rfs-value(72px);
          }
        }
      }

      img {
        //stylelint-disable declaration-no-important
        position: inherit !important;
        top: 0 !important;
        width: 100% !important;
        margin: 0 !important;
        @include border-radius($border-radius-xxxl);
        //stylelint-enable declaration-no-important
      }
    }
  }
}
