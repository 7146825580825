//stylelint-disable selector-max-compound-selectors, selector-no-qualifying-type, selector-max-id, selector-max-class, selector-max-combinators, selector-max-type, declaration-no-important
$color1: #003b5c;
$color5: #666;
$font-family1: inherit;
$border-color2: rgba($black, 0);
#wrapper-navbar {
  background-color: $white;
  @include box-shadow($box-shadow-sm);
}

.navbar-brand {
  @include media-breakpoint-down(lg) {
    margin-left: $spacer * .25;
  }

  img {
    @include transition(height .15s ease-in-out, opacity .15s ease-in-out);
    height: 34px;
    opacity: 1;
    @include media-breakpoint-down(lg) {
      height: 30px;
    }

    &:last-child {
      @include margin-left($spacer * .75);
    }
  }
}

#main-nav {
  @include transition(padding .15s ease-in-out);
  @include media-breakpoint-down(lg) {
    padding: rfs-value(8.5px) 0;
  }

  &.shrink-nav {
    padding: rfs-value(5px) 0;
    @include transition(padding .15s ease-in-out);

    .navbar-brand {
      img {
        height: 30px;
      }
    }

    #mega-menu-wrap-primary {
      #mega-menu-primary {
        li.mega-menu-item {
          > ul.mega-sub-menu {
            margin-top: rfs-value(12px);
          }
        }
      }

      @include media-breakpoint-down(lg) {
        .mega-menu-toggle {
          + {
            #mega-menu-primary {
              margin-top: rfs-value($spacer * .375);
            }
          }
        }
      }
    }

    #mega-menu-wrap-utility {
      #mega-menu-utility {
        li.mega-menu-item {
          > ul.mega-sub-menu {
            margin-top: rfs-value(12px);
          }
        }
      }

      @include media-breakpoint-down(lg) {
        .mega-menu-toggle {
          + {
            #mega-menu-utility {
              margin-top: rfs-value($spacer * .375);
            }
          }
        }
      }
    }
  }
}

#mega-menu-wrap-primary {
  position: relative;

  #mega-menu-primary {
    padding-left: 0;
    margin: 0;
    list-style-type: none;

    ul.mega-sub-menu {
      list-style: none;

      &::before {
        display: none;
      }

      &::after {
        display: none;
      }
    }

    li.mega-menu-item {
      display: inline-block;

      &::before {
        display: none;
      }

      &::after {
        display: none;
      }

      .mega-menu-row::marker,
      .mega-menu-column::marker {
        content: none;
      }

      .mega-sub-menu {
        padding-left: 0;
      }

      > ul.mega-sub-menu {
        position: absolute;
        left: 0;
        z-index: 999;
        display: block;
        width: 100%;
        max-width: none;
        padding: $spacer * 1.5 0;
        margin-top: $spacer * 1.875;
        pointer-events: auto;
        visibility: hidden;
        background: $white;
        border: rfs-value(1px) solid $gray-100;
        opacity: 1;
        @include border-radius($border-radius);
        @include box-shadow($box-shadow);

        .mega-menu-item {
          float: left;
          clear: none;
          color: $primary;

          vertical-align: top;

          &:last-child {
            border-right: none;
          }

          > a.mega-menu-link:not(.btn) {
            @include padding-top(35px);
            @include padding-bottom(35px);
            @include padding-right(62px);
            @include padding-left(62px);
          }
        }
      }

      a.mega-menu-link:not(.btn) {
        @include padding(32px 30px);
        @include media-breakpoint-down(xl) {
          @include padding(32px 15px);
        }
        font-weight: $font-weight-medium;
        color: $denim;
        vertical-align: middle;

        &::before {
          position: static;
          top: auto;
          display: inline-block;
          width: auto;
          height: auto;
          margin: 0 rfs-value(15px) 0 0;
          font: inherit;
          vertical-align: top;
          background: transparent;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
        }
      }
    }

    a.mega-menu-link:not(.btn) {
      text-decoration: none;

      &::before {
        display: none;
      }

      &::after {
        display: none;
      }

      .mega-description-group {
        display: inline-block;
        vertical-align: middle;
        @include transition(none);

        .mega-menu-title {
          display: block;
          text-transform: initial;
          @include transition(none);
        }

        .mega-menu-description {
          display: block;
          font-size: $font-size-sm;
          font-style: normal;
          font-weight: $font-weight-normal;
          text-transform: none;
          @include transition(none);
        }
      }

      > img.wpml-ls-flag {
        display: inline;
        margin-right: 8px;
      }

      > img.iclflag {
        display: inline;
        margin-right: 8px;
      }
    }

    li.mega-menu-megamenu {
      li.mega-menu-item.mega-icon-left.mega-has-description.mega-has-icon {
        > a.mega-menu-link:not(.btn) {
          display: flex;
          align-items: center;

          &::before {
            flex: 0 0 auto;
            align-self: flex-start;
          }
        }
      }

      li.mega-disable-link {
        > a.mega-menu-link:not(.btn) {
          cursor: inherit;
        }
      }

      > li.mega-menu-item-has-children.mega-disable-link {
        > a.mega-menu-link:not(.btn) {
          cursor: pointer;
        }
      }

      > ul.mega-sub-menu {
        @include media-breakpoint-down(lg) {
          padding: 0;
        }

        > li.mega-menu-row {
          float: left;
          width: 100%;

          .mega-menu-column {
            @include media-breakpoint-down(lg) {
              &:first-child {
                > ul.mega-sub-menu {
                  > li.mega-menu-item {
                    &:first-child {
                      > a.mega-menu-link:not(.btn) {
                        padding-top: 0;
                      }
                    }
                  }
                }
              }
            }

            &:not(:last-child) {
              border-right: rfs-value(1px) solid $dove-400;
              @include media-breakpoint-down(lg) {
                border-right: 0;
              }
            }

            float: left;
            min-height: rfs-value(1px);

            &.cta {
              display: flex;
              align-items: center;
              justify-content: center;
              margin: 0 $spacer * 1.5;
              @include border-radius($border-radius-sm);
              @include media-breakpoint-down(lg) {
                @include border-radius(0);
                padding: $spacer * 2 0;
                margin: 0;
                background: none !important;
                border-bottom: rfs-value(1px) solid $dove-400;
              }

              > ul.mega-sub-menu {
                @include media-breakpoint-down(lg) {
                  @include border-radius($border-radius-sm);
                  width: 100%;
                }

                > li.mega-menu-item {
                  border-bottom: 0;

                  > a.mega-menu-link:not(.btn) {
                    display: inherit;
                    text-align: center;
                    @include padding-top($spacer * 2);
                    @include padding-bottom($spacer * 2);
                    @include padding-right($spacer * 2.5);
                    @include padding-left($spacer * 2.5);

                    .mega-menu-title {
                      @extend .h4;
                      color: $white;
                      @include margin-bottom($spacer * 1.5);

                      &:hover {
                        color: $white;
                      }
                    }

                    .mega-menu-description {
                      @extend .btn;
                      @extend .btn-outline-white;
                      @extend .btn-sm;
                      //color: $interactive;
                      //font-weight: $font-weight-medium;
                      &::after {
                        margin-left: $spacer * .375;
                        font-family: $fa-style-family;
                        font-weight: $font-weight-normal;
                        color: $white;
                        text-align: center;
                        content: fa-content($fa-var-arrow-right);
                        @extend %fa-icon;
                        @extend .fa-regular;
                        @extend .fs-4;
                      }
                    }
                  }
                }
              }

              &.find-solutions {
                background: linear-gradient(0deg, rgba(0, 0, 0, .4) 0%, rgba(0, 0, 0, .4) 100%), url("../images/find-solutions.jpg") $gray-200 no-repeat center;
                background-size: cover;

                > .mega-sub-menu {
                  @include media-breakpoint-down(lg) {
                    background: linear-gradient(0deg, rgba(0, 0, 0, .4) 0%, rgba(0, 0, 0, .4) 100%), url("../images/find-solutions.jpg") $gray-200 no-repeat center;
                    background-size: cover;
                  }
                }
              }

              &.request-info {
                background: linear-gradient(0deg, rgba(0, 0, 0, .4) 0%, rgba(0, 0, 0, .4) 100%), url("../images/request-info.jpg") $gray-200 no-repeat;
                background-size: cover;

                > .mega-sub-menu {
                  @include media-breakpoint-down(lg) {
                    background: linear-gradient(0deg, rgba(0, 0, 0, .4) 0%, rgba(0, 0, 0, .4) 100%), url("../images/request-info.jpg") $gray-200 no-repeat;
                    background-size: cover;
                  }
                }
              }

              &.support-contact {
                background: linear-gradient(0deg, rgba(0, 0, 0, .4) 0%, rgba(0, 0, 0, .4) 100%), url("../images/support-contact.jpg") $gray-200 no-repeat;
                background-size: cover;

                > .mega-sub-menu {
                  @include media-breakpoint-down(lg) {
                    background: linear-gradient(0deg, rgba(0, 0, 0, .4) 0%, rgba(0, 0, 0, .4) 100%), url("../images/support-contact.jpg") $gray-200 no-repeat;
                    background-size: cover;
                  }
                }
              }
            }

            > ul.mega-sub-menu {
              > li.mega-menu-item {
                @include media-breakpoint-down(lg) {
                  width: 100%;
                  border-bottom: rfs-value(1px) solid $dove-400;
                }

                > a.mega-menu-link:not(.btn) {
                  display: flex;
                  align-items: center;
                  @include padding-top($spacer * 2);
                  @include padding-bottom($spacer * 2);
                  @include padding-right($spacer * 2.5);
                  @include padding-left($spacer * 2.5);
                  @include media-breakpoint-down(lg) {
                    @include padding($spacer * 1.25 0);
                  }
                }
              }
            }
          }
        }
      }

      &:not(.mega-menu-tabbed) {
        li.mega-menu-item-has-children {
          &:not(.mega-collapse-children) {
            > a.mega-menu-link:not(.btn) {
              > span.mega-indicator {
                display: none;
              }
            }
          }
        }
      }
    }

    li.mega-menu-tabbed.mega-menu-megamenu {
      > ul.mega-sub-menu {
        > li.mega-menu-item.mega-icon-left.mega-has-description.mega-has-icon {
          > a.mega-menu-link:not(.btn) {
            display: block;
          }
        }
      }
    }

    li.mega-menu-item.mega-icon-top {
      > a.mega-menu-link:not(.btn) {
        display: table-cell;
        line-height: initial;
        vertical-align: middle;

        &::before {
          display: block;
          margin: 0 0 $spacer * .375;
          text-align: center;
        }

        > span.mega-title-below {
          display: inline-block;
          @include transition(none);
        }
      }
    }

    li.mega-menu-item.mega-icon-right {
      > a.mega-menu-link:not(.btn) {
        &::before {
          float: right;
          margin: 0 0 0 $spacer * .375;
        }
      }
    }

    > li.mega-animating {
      > ul.mega-sub-menu {
        pointer-events: none;
      }
    }

    li.mega-disable-link {
      > a.mega-menu-link:not(.btn) {
        cursor: inherit;
      }
    }

    li.mega-menu-item-has-children.mega-disable-link {
      > a.mega-menu-link:not(.btn) {
        cursor: pointer;
      }
    }

    p {
      margin-bottom: 10px;
    }

    input {
      max-width: 100%;
    }

    img {
      max-width: 100%;
    }

    li.mega-menu-item.mega-menu-megamenu {
      ul.mega-sub-menu {
        li.mega-collapse-children {
          > ul.mega-sub-menu {
            display: none;
          }
        }

        li.mega-collapse-children.mega-toggle-on {
          > ul.mega-sub-menu {
            display: block;
          }
        }

        ul.mega-sub-menu {
          display: block;
          visibility: inherit;
          opacity: 1;
        }

        li.mega-1-columns {
          > ul.mega-sub-menu {
            > li.mega-menu-item {
              float: left;
              width: 100%;
            }
          }
        }

        li.mega-2-columns {
          > ul.mega-sub-menu {
            > li.mega-menu-item {
              float: left;
              width: 50%;
            }
          }
        }

        li.mega-3-columns {
          > ul.mega-sub-menu {
            > li.mega-menu-item {
              float: left;
              width: 33.33333%;
            }
          }
        }

        li.mega-4-columns {
          > ul.mega-sub-menu {
            > li.mega-menu-item {
              float: left;
              width: 25%;
            }
          }
        }

        li.mega-5-columns {
          > ul.mega-sub-menu {
            > li.mega-menu-item {
              float: left;
              width: 20%;
            }
          }
        }

        li.mega-6-columns {
          > ul.mega-sub-menu {
            > li.mega-menu-item {
              float: left;
              width: 16.66667%;
            }
          }
        }
      }
    }

    li.mega-menu-item.mega-toggle-on {
      > ul.mega-sub-menu {
        visibility: visible;
      }
    }

    li.mega-menu-item.mega-hide-text {
      a.mega-menu-link:not(.btn) {
        &::before {
          margin: 0;
        }
      }

      li.mega-menu-item {
        a.mega-menu-link:not(.btn) {
          &::before {
            margin: 0 $spacer * .375 0 0;
          }
        }
      }
    }

    li.mega-align-bottom-left.mega-toggle-on {
      > a.mega-menu-link:not(.btn) {
        @include border-radius(0);
      }
    }

    li.mega-align-bottom-right {
      > ul.mega-sub-menu {
        right: 0;
      }
    }

    li.mega-align-bottom-right.mega-toggle-on {
      > a.mega-menu-link:not(.btn) {
        @include border-radius(0);
      }
    }

    > li.mega-menu-megamenu.mega-menu-item {
      position: static;
    }

    > li.mega-menu-item.mega-item-align-right {
      float: right;
    }

    > li.mega-menu-item.mega-toggle-on {
      > a.mega-menu-link:not(.btn) {
        color: $interactive;
        text-decoration: none;
      }
    }

    > li.mega-menu-item.mega-current-menu-item {
      > a.mega-menu-link:not(.btn) {
        color: $interactive;
        text-decoration: none;
      }
    }

    > li.mega-menu-item.mega-current-menu-ancestor {
      > a.mega-menu-link:not(.btn) {
        color: $interactive;
        text-decoration: none;
      }
    }

    > li.mega-menu-item.mega-current-page-ancestor {
      > a.mega-menu-link:not(.btn) {
        color: $interactive;
        text-decoration: none;
      }
    }

    > li.mega-menu-megamenu {
      > ul.mega-sub-menu {

        .mega-description-group {
          .mega-menu-description {
            margin: 5px 0;
          }
        }

        > li.mega-menu-item {
          ul.mega-sub-menu {
            clear: both;

            li.mega-menu-item {
              ul.mega-sub-menu {
                margin-left: 10px;
              }
            }
          }

          h4.mega-block-title {
            display: block;
            padding: 0 0 5px;
            margin: 0;
            font-family: $font-family1;
            font-size: 1 $spacer * .375;
            font-weight: $font-weight-bold;
            color: $color1;
            text-align: left;
            text-decoration: none;
            text-transform: uppercase;
            vertical-align: top;
            visibility: inherit;
            border: 0;

            &:hover {
              border-color: $border-color2;
            }
          }

          > a.mega-menu-link:not(.btn) {
            font-weight: $font-weight-bold;
            color: $primary;
            text-align: left;
            text-decoration: none;
            text-transform: initial;
            vertical-align: top;

            &:hover {
              font-weight: $font-weight-bold;
              color: $interactive;
              text-decoration: none;
              border-color: $border-color2;

              > span.mega-title-below {
                text-decoration: none;
              }
            }

            &:focus {
              font-weight: $font-weight-bold;
              color: $interactive;
              text-decoration: none;
              background: rgba($black, 0);

              > span.mega-title-below {
                text-decoration: none;
              }
            }
          }

          li.mega-menu-item {
            > a.mega-menu-link:not(.btn) {
              display: block;
              padding: 0;
              margin: 0;
              font-family: $font-family1;
              font-size: 14px;
              color: $denim;
              text-align: left;
              text-decoration: none;
              text-transform: none;
              vertical-align: top;
              border: 0;

              &:hover {
                color: $interactive;
                text-decoration: none;
                background: rgba($black, 0);
                border-color: $border-color2;
              }

              &:focus {
                color: $interactive;
                text-decoration: none;
                background: rgba($black, 0);
              }
            }
          }

          li.mega-menu-item.mega-icon-left.mega-has-description.mega-has-icon {
            > a.mega-menu-link:not(.btn) {
              display: flex;
            }
          }
        }

        li.mega-menu-column {
          > ul.mega-sub-menu {
            ul.mega-sub-menu {
              ul.mega-sub-menu {
                margin-left: 10px;
              }
            }

            > li.mega-menu-item {
              display: block;
              float: left;
              padding: 0;
              clear: none;
              font-family: $font-family1;
              font-size: 14px;
              color: $color1;
              vertical-align: top;

              h4.mega-block-title {
                display: block;
                padding: 0 0 5px;
                margin: 0;
                font-family: $font-family1;
                font-size: 1 $spacer * .375;
                font-weight: 700;
                color: $color1;
                text-align: left;
                text-decoration: none;
                text-transform: uppercase;
                vertical-align: top;
                visibility: inherit;
                border: 0;

                &:hover {
                  border-color: $border-color2;
                }
              }

              > a.mega-menu-link:not(.btn) {
                display: block;
                padding: 0;
                margin: 0;
                font-family: $font-family1;
                font-size: $font-size-base;
                font-weight: 700;
                color: $color1;
                text-align: left;
                text-decoration: none;
                text-transform: uppercase;
                vertical-align: top;
                border: 0;

                &:hover {
                  font-weight: 700;
                  color: $interactive;
                  text-decoration: none;
                  background: rgba($black, 0);
                  border-color: $border-color2;

                  > span.mega-title-below {
                    text-decoration: none;
                  }
                }

                &:focus {
                  font-weight: 700;
                  color: $interactive;
                  text-decoration: none;
                  background: rgba($black, 0);

                  > span.mega-title-below {
                    text-decoration: none;
                  }
                }
              }

              li.mega-menu-item {
                > a.mega-menu-link:not(.btn) {
                  display: block;
                  padding: 0;
                  margin: 0;
                  font-family: $font-family1;
                  font-size: 14px;
                  font-weight: 400;
                  color: $color1;
                  text-align: left;
                  text-decoration: none;
                  text-transform: none;
                  vertical-align: top;
                  border: 0;

                  &:hover {
                    font-weight: 400;
                    color: $interactive;
                    text-decoration: none;
                    background: rgba($black, 0);
                    border-color: $border-color2;
                  }

                  &:focus {
                    font-weight: 400;
                    color: $interactive;
                    text-decoration: none;
                    background: rgba($black, 0);
                  }
                }
              }

              li.mega-menu-item.mega-icon-left.mega-has-description.mega-has-icon {
                > a.mega-menu-link:not(.btn) {
                  display: flex;
                }
              }
            }

            > li.mega-menu-item.mega-menu-clear {
              clear: left;
            }
          }
        }

        > li.mega-menu-item.mega-menu-clear {
          clear: left;
        }
      }
    }

    > li.mega-menu-megamenu.mega-no-headers {
      > ul.mega-sub-menu {
        > li.mega-menu-item {
          > a.mega-menu-link:not(.btn) {
            display: block;
            padding: 0;
            margin: 0;
            font-family: $font-family1;
            font-size: 14px;
            font-weight: 400;
            color: $color1;
            text-decoration: none;
            text-transform: none;
            vertical-align: top;
            border: 0;

            &:hover {
              font-weight: 400;
              color: $interactive;
              text-decoration: none;
              background: rgba($black, 0);
            }

            &:focus {
              font-weight: 400;
              color: $interactive;
              text-decoration: none;
              background: rgba($black, 0);
            }
          }
        }

        li.mega-menu-column {
          > ul.mega-sub-menu {
            > li.mega-menu-item {
              > a.mega-menu-link:not(.btn) {
                display: block;
                padding: 0;
                margin: 0;
                font-family: $font-family1;
                font-size: 14px;
                font-weight: 400;
                color: $color1;
                text-decoration: none;
                text-transform: none;
                vertical-align: top;
                border: 0;

                &:hover {
                  font-weight: 400;
                  color: $interactive;
                  text-decoration: none;
                  background: rgba($black, 0);
                }

                &:focus {
                  font-weight: 400;
                  color: $interactive;
                  text-decoration: none;
                  background: rgba($black, 0);
                }
              }
            }
          }
        }
      }
    }

    > li.mega-menu-flyout {
      ul.mega-sub-menu {
        position: absolute;
        z-index: 999;
        width: 250px;
        max-width: none;
        padding: 0;
        background: $white;
        border: rfs-value(1px) solid $gray-100;
        @include border-radius($border-radius-lg);
        @include box-shadow($box-shadow-xxxl);

        li.mega-menu-item {
          a.mega-menu-link:not(.btn) {
            display: block;
            padding: 0 10px;
            font-family: $font-family1;
            font-size: 14px;
            font-weight: 400;
            line-height: 35px;
            color: $color1;
            text-decoration: none;
            text-transform: none;
            vertical-align: baseline;
            background: $white;

            &:hover {
              font-weight: 400;
              color: $interactive;
              text-decoration: none;
              background: $white;
            }

            &:focus {
              font-weight: 400;
              color: $interactive;
              text-decoration: none;
              background: $white;
            }
          }

          &:first-child {
            > a.mega-menu-link:not(.btn) {
              @include border-top-radius($border-radius-lg);
            }
          }

          &:last-child {
            > a.mega-menu-link:not(.btn) {
              @include border-bottom-radius($border-radius-lg);
            }
          }

          ul.mega-sub-menu {
            position: absolute;
            top: 0;
            left: 100%;
          }
        }
      }
    }

    li.mega-menu-item-has-children {
      > a.mega-menu-link:not(.btn) {
        cursor: pointer;

        > span.mega-indicator {
          &::after {
            @extend %fa-icon;
            @extend .fa-regular;
            position: relative;
            top: 0;
            right: auto;
            display: inline-block;
            width: auto;
            height: auto;
            margin-left: rfs-value(10px);
            font-size: rfs-value(18px);
            line-height: inherit;
            color: $interactive;
            content: fa-content($fa-var-chevron-down);
            background: transparent;
            transform: rotateX(0);
            @include transition(top .15s ease-in-out, transform .15s ease-in-out);
            @include media-breakpoint-up(lg) {
              font-size: rfs-value(15px);
            }
          }

          position: relative;
          left: auto;

          display: inline-block;
          width: auto;
          min-width: auto;
          height: auto;
          padding: 0;
          margin: 0 0 0 rfs-value($spacer * .375);
          font-size: inherit;
          line-height: inherit;
          pointer-events: auto;
          background: transparent;
        }
      }

      li.mega-menu-item-has-children {
        > a.mega-menu-link:not(.btn) {
          > span.mega-indicator {
            float: right;
          }
        }
      }
    }

    li.mega-menu-item-has-children.mega-toggle-on {
      > a.mega-menu-link:not(.btn) {
        > span.mega-indicator {
          &::after {
            top: rfs-value(2px);
            transform: rotateX(-180deg);
          }
        }
      }
    }

    li.mega-menu-item-has-children.mega-hide-arrow {
      > a.mega-menu-link:not(.btn) {
        > span.mega-indicator {
          display: none;
        }
      }
    }

    li[class^="mega-lang-item"] {
      > a.mega-menu-link:not(.btn) {
        > img {
          display: inline;
        }
      }
    }

    li.mega-menu-item.mega-show-when-sticky {
      display: none;
    }

    li.mega-partner {
      > a.mega-menu-link:not(.btn) {
        &::before {
          width: 24px;
          height: 24px;
          font-family: $fa-style-family;
          font-size: rfs-value(22px);
          font-weight: $font-weight-normal;
          line-height: 24px;
          color: $interactive;
          text-align: center;
          vertical-align: text-top;
          content: fa-content($fa-var-landmark);
          @extend %fa-icon;
          @extend .fa-regular;
        }
      }
    }

    li.mega-sales {
      > a.mega-menu-link:not(.btn) {
        &::before {
          width: 24px;
          height: 24px;
          font-family: $fa-style-family;
          font-size: rfs-value(22px);
          font-weight: $font-weight-normal;
          line-height: 24px;
          color: $interactive;
          text-align: center;
          vertical-align: text-top;
          content: fa-content($fa-var-chart-column);
          @extend %fa-icon;
          @extend .fa-regular;
        }
      }
    }

    li.mega-benji {
      > a.mega-menu-link:not(.btn) {
        &::before {
          width: 24px;
          height: 24px;
          font-family: $fa-style-family;
          font-size: rfs-value(22px);
          font-weight: $font-weight-normal;
          line-height: 24px;
          color: $interactive;
          text-align: center;
          vertical-align: text-top;
          content: fa-content($fa-var-circle-dollar);
          @extend %fa-icon;
          @extend .fa-regular;
        }
      }
    }

    li.mega-mobile {
      > a.mega-menu-link:not(.btn) {
        &::before {
          width: 24px;
          height: 24px;
          font-family: $fa-style-family;
          font-size: rfs-value(22px);
          font-weight: $font-weight-normal;
          line-height: 24px;
          color: $interactive;
          text-align: center;
          vertical-align: text-top;
          content: fa-content($fa-var-mobile-button);
          @extend %fa-icon;
          @extend .fa-regular;
        }
      }
    }

    li.mega-register {
      > a.mega-menu-link:not(.btn) {
        &::before {
          display: block;
          width: 48px;
          height: 48px;
          margin-right: auto;
          margin-left: auto;
          font-family: $fa-style-family;
          font-size: rfs-value(4 $spacer * .375);
          font-weight: $font-weight-light;
          line-height: 48px;
          color: $jade-500;
          text-align: center;
          vertical-align: text-top;
          content: fa-content($fa-var-truck-fast);
          @extend %fa-icon;
          @extend .fa-light;
          @include margin-bottom($spacer * 2);

        }
      }
    }

    li.mega-works {
      > a.mega-menu-link:not(.btn) {
        &::before {
          width: 24px;
          height: 24px;
          font-family: $fa-style-family;
          font-size: rfs-value(22px);
          font-weight: $font-weight-normal;
          line-height: 24px;
          color: $interactive;
          text-align: center;
          vertical-align: text-top;
          content: fa-content($fa-var-bolt);
          @extend %fa-icon;
          @extend .fa-regular;
        }
      }
    }

    li.mega-eligibility {
      > a.mega-menu-link:not(.btn) {
        &::before {
          width: 24px;
          height: 24px;
          font-family: $fa-style-family;
          font-size: rfs-value(22px);
          font-weight: $font-weight-normal;
          line-height: 24px;
          color: $interactive;
          text-align: center;
          vertical-align: text-top;
          content: fa-content($fa-var-shield-check);
          @extend %fa-icon;
          @extend .fa-regular;
        }
      }
    }

    li.mega-improvements {
      > a.mega-menu-link:not(.btn) {
        &::before {
          width: 24px;
          height: 24px;
          font-family: $fa-style-family;
          font-size: rfs-value(22px);
          font-weight: $font-weight-normal;
          line-height: 24px;
          color: $interactive;
          text-align: center;
          vertical-align: text-top;
          content: fa-content($fa-var-house);
          @extend %fa-icon;
          @extend .fa-regular;
        }
      }
    }

    li.mega-find {
      > a.mega-menu-link:not(.btn) {
        &::before {
          width: 24px;
          height: 24px;
          font-family: $fa-style-family;
          font-size: rfs-value(22px);
          font-weight: $font-weight-normal;
          line-height: 24px;
          color: $interactive;
          text-align: center;
          vertical-align: text-top;
          content: fa-content($fa-var-magnifying-glass);
          @extend %fa-icon;
          @extend .fa-regular;
        }
      }
    }

    li.mega-contractor {
      > a.mega-menu-link:not(.btn) {
        &::before {
          display: block;
          width: 48px;
          height: 48px;
          margin-right: auto;
          margin-left: auto;
          font-family: $fa-style-family;
          font-size: rfs-value(4 $spacer * .375);
          font-weight: $font-weight-light;
          line-height: 48px;
          color: $tangerine-500;
          text-align: center;
          vertical-align: text-top;
          content: fa-content($fa-var-screwdriver-wrench);
          @extend %fa-icon;
          @extend .fa-light;
          @include margin-bottom($spacer * 2);
        }
      }
    }

    li.mega-support {
      > a.mega-menu-link:not(.btn) {
        &::before {
          width: 24px;
          height: 24px;
          font-family: $fa-style-family;
          font-size: rfs-value(22px);
          font-weight: $font-weight-normal;
          line-height: 24px;
          color: $interactive;
          text-align: center;
          vertical-align: text-top;
          content: fa-content($fa-var-circle-question);
          @extend %fa-icon;
          @extend .fa-regular;
        }
      }
    }

    li.mega-blog {
      > a.mega-menu-link:not(.btn) {
        &::before {
          width: 24px;
          height: 24px;
          font-family: $fa-style-family;
          font-size: rfs-value(22px);
          font-weight: $font-weight-normal;
          line-height: 24px;
          color: $interactive;
          text-align: center;
          vertical-align: text-top;
          content: fa-content($fa-var-pen-to-square);
          @extend %fa-icon;
          @extend .fa-regular;
        }
      }
    }

    li.mega-philanthropy {
      > a.mega-menu-link:not(.btn) {
        &::before {
          width: 24px;
          height: 24px;
          font-family: $fa-style-family;
          font-size: rfs-value(22px);
          font-weight: $font-weight-normal;
          line-height: 24px;
          color: $interactive;
          text-align: center;
          vertical-align: text-top;
          content: fa-content($fa-var-box-heart);
          @extend %fa-icon;
          @extend .fa-regular;
        }
      }
    }

    li.mega-about {
      > a.mega-menu-link:not(.btn) {
        &::before {
          width: 24px;
          height: 24px;
          font-family: $fa-style-family;
          font-size: rfs-value(22px);
          font-weight: $font-weight-normal;
          line-height: 24px;
          color: $interactive;
          text-align: center;
          vertical-align: text-top;
          content: fa-content($fa-var-landmark);
          @extend %fa-icon;
          @extend .fa-regular;
        }
      }
    }

    li.mega-careers {
      > a.mega-menu-link:not(.btn) {
        &::before {
          width: 24px;
          height: 24px;
          font-family: $fa-style-family;
          font-size: rfs-value(22px);
          font-weight: $font-weight-normal;
          line-height: 24px;
          color: $interactive;
          text-align: center;
          vertical-align: text-top;
          content: fa-content($fa-var-pen-to-square);
          @extend %fa-icon;
          @extend .fa-regular;
        }
      }
    }

    li.mega-contact-us {
      > a.mega-menu-link:not(.btn) {
        &::before {
          width: 24px;
          height: 24px;
          font-family: $fa-style-family;
          font-size: rfs-value(22px);
          font-weight: $font-weight-normal;
          line-height: 24px;
          color: $interactive;
          text-align: center;
          vertical-align: text-top;
          content: fa-content($fa-var-envelope);
          @extend %fa-icon;
          @extend .fa-regular;
        }
      }
    }

    li.mega-support-contact {
      > a.mega-menu-link:not(.btn) {
        &::before {
          display: block;
          width: 48px;
          height: 48px;
          margin-right: auto;
          margin-left: auto;
          font-family: $fa-style-family;
          font-size: rfs-value(4 $spacer * .375);
          font-weight: $font-weight-light;
          line-height: 48px;
          color: $electric-500;
          text-align: center;
          vertical-align: text-top;
          content: fa-content($fa-var-messages-question);
          @extend %fa-icon;
          @extend .fa-light;
          @include margin-bottom($spacer * 2);
        }
      }
    }

    li.mega-solutions {
      > a.mega-menu-link:not(.btn) {
        &::before {
          width: 24px;
          height: 24px;
          font-family: $fa-style-family;
          font-size: rfs-value(22px);
          font-weight: $font-weight-normal;
          line-height: 24px;
          color: $interactive;
          vertical-align: text-top;
          content: fa-content($fa-var-money-check-dollar-pen);
          @extend %fa-icon;
          @extend .fa-regular;
        }
      }
    }

    li.mega-education {
      > a.mega-menu-link:not(.btn) {
        &::before {
          width: 24px;
          height: 24px;
          font-family: $fa-style-family;
          font-size: rfs-value(22px);
          font-weight: $font-weight-normal;
          line-height: 24px;
          color: $interactive;
          vertical-align: text-top;
          content: fa-content($fa-var-book-blank);
          @extend %fa-icon;
          @extend .fa-regular;
        }
      }
    }

    li.mega-stories {
      > a.mega-menu-link:not(.btn) {
        &::before {
          width: 24px;
          height: 24px;
          font-family: $fa-style-family;
          font-size: rfs-value(22px);
          font-weight: $font-weight-normal;
          line-height: 24px;
          color: $interactive;
          vertical-align: text-top;
          content: fa-content($fa-var-house-user);
          @extend %fa-icon;
          @extend .fa-regular;
        }
      }
    }

    li.mega-calculator {
      > a.mega-menu-link:not(.btn) {
        &::before {
          width: 24px;
          height: 24px;
          font-family: $fa-style-family;
          font-size: rfs-value(22px);
          font-weight: $font-weight-normal;
          line-height: 24px;
          color: $interactive;
          vertical-align: text-top;
          content: fa-content($fa-var-calculator);
          @extend %fa-icon;
          @extend .fa-regular;
        }
      }
    }

    li.mega-hecm {
      > a.mega-menu-link:not(.btn) {
        &::before {
          width: 24px;
          height: 24px;
          line-height: 24px;
          vertical-align: text-top;
          content: "";
          background-image: url($hecm);
          background-repeat: no-repeat;
          background-size: 24px 24px;
        }
      }
    }

    li.mega-homesafe {
      > a.mega-menu-link:not(.btn) {
        &::before {
          width: 24px;
          height: 24px;
          line-height: 24px;
          vertical-align: text-top;
          content: "";
          background-image: url($homesafe);
          background-repeat: no-repeat;
          background-size: 24px 24px;
        }
      }
    }

    li.mega-homesafe-second {
      > a.mega-menu-link:not(.btn) {
        &::before {
          width: 24px;
          height: 24px;
          line-height: 24px;
          vertical-align: text-top;
          content: "";
          background-image: url($homesafe);
          background-repeat: no-repeat;
          background-size: 24px 24px;
        }
      }
    }

    li.mega-equity {
      > a.mega-menu-link:not(.btn) {
        &::before {
          width: 24px;
          height: 24px;
          line-height: 24px;
          vertical-align: text-top;
          content: "";
          background-image: url($equity);
          background-repeat: no-repeat;
          background-size: 24px 24px;
        }
      }
    }

    li.mega-new-customer-login {
      > a.mega-menu-link:not(.btn) {
        &::before {
          width: 24px;
          height: 24px;
          font-family: $fa-style-family;
          font-size: rfs-value(22px);
          font-weight: $font-weight-normal;
          line-height: 24px;
          color: $interactive;
          vertical-align: text-top;
          content: fa-content($fa-var-clipboard-list-check);
          @extend %fa-icon;
          @extend .fa-regular;
        }
      }
    }

    li.mega-current-customer-login {
      @include padding-bottom($spacer * 1 !important);
      > a.mega-menu-link:not(.btn) {
        &::before {
          width: 24px;
          height: 24px;
          font-family: $fa-style-family;
          font-size: rfs-value(22px);
          font-weight: $font-weight-normal;
          line-height: 24px;
          color: $interactive;
          vertical-align: text-top;
          content: fa-content($fa-var-circle-dollar);
          @extend %fa-icon;
          @extend .fa-regular;
        }
      }
    }

    li.mega-partner-login {
      @include padding-top($spacer * 1 !important);
      > a.mega-menu-link:not(.btn) {
        &::before {
          width: 24px;
          height: 24px;
          font-family: $fa-style-family;
          font-size: rfs-value(22px);
          font-weight: $font-weight-normal;
          line-height: 24px;
          color: $interactive;
          vertical-align: text-top;
          content: fa-content($fa-var-chart-line-up);
          @extend %fa-icon;
          @extend .fa-regular;
        }
      }
    }

    li.mega-bp-profile-nav {
      img.avatar {
        position: relative;
        top: -1px;
        display: inline-block;
        vertical-align: middle;
      }
    }

    //stylelint-disable-next-line selector-class-pattern
    img.mmm_gravatar {
      position: relative;
      top: -1px;
      display: inline-block;
      vertical-align: middle;
    }

    img.avatar {
      position: relative;
      top: -1px;
      vertical-align: middle;
    }

    > li.mega-menu-tabbed {
      > ul.mega-sub-menu {
        padding: 0;

        > li.mega-menu-item {
          position: static;
          width: 100%;
          padding: 0;

          > a.mega-menu-link:not(.btn) {
            float: left;
            width: 20%;
            padding: 0 10px;
            margin: 0;
            font-family: $font-family1;
            font-size: 14px;
            font-weight: 400;
            line-height: 35px;
            color: $color5;
            text-decoration: none;
            text-transform: none;
            vertical-align: baseline;
            background: #f1f1f1;
            border: 0;

            &:hover {
              font-weight: 400;
              color: $color5;
              text-decoration: none;
              background: #ddd;
            }

            &:focus {
              font-weight: 400;
              color: $color5;
              text-decoration: none;
              background: #ddd;
            }
          }

          > ul.mega-sub-menu {
            position: absolute;
            top: 0;
            left: 20%;
            float: left;
            width: 80%;
            min-height: 100%;
            padding: 0;
            clear: none;
            visibility: hidden;
            border-left: 1px solid #e1e1e1;

            .mega-menu-item-has-children {
              > a.mega-menu-link:not(.btn) {
                &::after {
                  display: none;
                }
              }
            }

            > li.mega-menu-item {
              float: left;
              padding: 35px 32px;

              > a.mega-menu-link:not(.btn) {
                display: block;
                padding: 0;
                margin: 0;
                font-family: $font-family1;
                font-size: 1 $spacer * .375;
                font-weight: 700;
                color: $color1;
                text-decoration: none;
                text-transform: uppercase;
                vertical-align: top;
                border-top: 0 solid rgba($black, 0);
                border-right: 0 solid rgba($black, 0);
                border-bottom: 0 solid rgba($black, 0);
                border-left: 0 solid rgba($black, 0);

                &:hover {
                  font-weight: 700;
                  color: $interactive;
                  text-decoration: none;
                  background: rgba($black, 0);

                  > span.mega-title-below {
                    text-decoration: none;
                  }
                }

                &:focus {
                  font-weight: 700;
                  color: $interactive;
                  text-decoration: none;
                  background: rgba($black, 0);

                  > span.mega-title-below {
                    text-decoration: none;
                  }
                }
              }

              li.mega-menu-item {
                > a.mega-menu-link:not(.btn) {
                  display: block;
                  padding: 0;
                  margin: 0;
                  font-family: $font-family1;
                  font-size: 14px;
                  font-weight: 400;
                  color: $color1;
                  text-decoration: none;
                  text-transform: none;
                  vertical-align: top;

                  &:hover {
                    font-weight: 400;
                    color: $interactive;
                    text-decoration: none;
                    background: rgba($black, 0);
                  }

                  &:focus {
                    font-weight: 400;
                    color: $interactive;
                    text-decoration: none;
                    background: rgba($black, 0);
                  }
                }
              }
            }

            > li.mega-menu-item.mega-menu-clear {
              clear: left;
            }
          }

          ul.mega-sub-menu {
            li.mega-menu-item {
              ul.mega-sub-menu {
                margin-left: 0;

                li.mega-menu-item {
                  ul.mega-sub-menu {
                    margin-left: 10px;
                  }
                }
              }
            }
          }
        }

        > li.mega-menu-item.mega-toggle-on {
          > a.mega-menu-link:not(.btn) {
            font-weight: 400;
            color: $color5;
            text-decoration: none;
            background: #ddd;
          }

          > ul.mega-sub-menu {
            visibility: visible;
          }
        }

        > li.mega-menu-item.mega-menu-item-has-children {
          > a.mega-menu-link:not(.btn) {
            > span.mega-indicator {
              &::after {
                content: "\f345";
              }
            }
          }
        }

        > li.mega-menu-item.mega-no-headers {
          > ul.mega-sub-menu {
            > li.mega-menu-item {
              > a.mega-menu-link:not(.btn) {
                display: block;
                padding: 0;
                margin: 0;
                font-family: $font-family1;
                font-size: 14px;
                font-weight: 400;
                color: $color1;
                text-decoration: none;
                text-transform: none;
                vertical-align: top;
                border-top: 0;
                border-right: 0;
                border-bottom: 0;
                border-left: 0;

                &:hover {
                  font-weight: 400;
                  color: $interactive;
                  text-decoration: none;
                  background: rgba($black, 0);
                }

                &:focus {
                  font-weight: 400;
                  color: $interactive;
                  text-decoration: none;
                  background: rgba($black, 0);
                }
              }
            }
          }
        }
      }
    }

    li.mega-menu-item.mega-icon-left {
      > a[class^="fas"] {
        &::before {
          width: 1.25em;
          text-align: center;
        }
      }

      > a[class^="fab"] {
        &::before {
          width: 1.25em;
          text-align: center;
        }
      }

      > a[class^="far"] {
        &::before {
          width: 1.25em;
          text-align: center;
        }
      }

      > a[class^="fal"] {
        &::before {
          width: 1.25em;
          text-align: center;
        }
      }
    }
  }

  &::before {
    display: none;
  }

  &::after {
    display: none;
  }

  #mega-menu-primary.mega-no-js {
    li.mega-menu-item {
      &:hover {
        > ul.mega-sub-menu {
          visibility: visible;
        }
      }

      &:focus {
        > ul.mega-sub-menu {
          visibility: visible;
        }
      }
    }
  }

  .mega-menu-toggle {
    z-index: 1;
    display: none;
    height: 40px;
    line-height: 40px;
    text-align: left;
    white-space: nowrap;
    cursor: pointer;
    user-select: none;
    outline: 0;
    -webkit-tap-highlight-color: transparent;

    img {
      max-width: 100%;
      padding: 0;
    }

    .mega-toggle-blocks-left {
      display: -webkit-box;
      display: flex;
      -ms-flex-preferred-size: 33.33%;
      -webkit-box-flex: 1;
      flex: 1;
      -webkit-box-pack: start;
      -ms-flex-pack: start;
      justify-content: flex-start;

      .mega-toggle-block {
        margin-left: $spacer * .25;

        &:only-child {
          margin-right: $spacer * .25;
        }
      }
    }

    .mega-toggle-blocks-center {
      display: -webkit-box;
      display: flex;
      -ms-flex-preferred-size: 33.33%;
      flex-basis: 33.33%;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;

      .mega-toggle-block {
        margin-right: 3px;
        margin-left: 3px;
      }
    }

    .mega-toggle-blocks-right {
      display: -webkit-box;
      display: flex;
      flex: 1;
      flex-basis: 33.33%;
      justify-content: flex-end;
      -ms-flex-preferred-size: 33.33%;
      -webkit-box-flex: 1;
      -webkit-box-pack: end;
      -ms-flex-pack: end;

      .mega-toggle-block {
        margin-right: $spacer * .375;

        &:only-child {
          margin-left: $spacer * .375;
        }
      }
    }

    .mega-toggle-block {
      display: -webkit-box;
      display: flex;
      flex-shrink: 0;
      align-self: center;
      height: 100%;
      outline: 0;
      -ms-flex-item-align: center;
      -ms-flex-negative: 0;
    }

    .mega-toggle-block-1 {
      cursor: pointer;

      .mega-toggle-animated {
        display: -webkit-box;
        display: flex;
        align-self: center;
        padding: 0;
        margin: 0;
        overflow: visible;
        font: inherit;
        text-transform: none;
        cursor: pointer;
        background: $primary;
        background: none;
        border: 0;
        outline: 0;
        transition-timing-function: linear;
        transition-duration: .15s;
        transition-property: opacity, filter;
        transform: scale(.8);
      }

      .mega-toggle-animated-box {
        position: relative;
        display: inline-block;
        width: 30px;
        height: 20px;
        outline: 0;
      }

      .mega-toggle-animated-inner {
        position: absolute;
        top: 0;
        display: block;
        width: 30px;
        height: 0;
        margin-top: rfs-value(-2px);
        background-color: $primary;
        border: rfs-value(2px) solid $primary;
        @include border-radius(rfs-value(4px));
        @include transition(transform, .15s ease-in-out);

        &::before {
          position: absolute;
          top: 8px;
          right: -2px;
          display: block;
          width: 25px;
          height: 0;
          content: "";
          background-color: $primary;
          border: rfs-value(2px) solid $primary;
          opacity: 1;
          @include border-radius(rfs-value(4px));
          @include transition(transform, .15s ease-in-out, opacity .15s ease-in-out);
        }

        &::after {
          position: absolute;
          top: 18px;
          right: -2px;
          display: block;
          width: 30px;
          height: 0;
          content: "";
          background-color: $primary;
          border: rfs-value(2px) solid $primary;
          @include border-radius(rfs-value(4px));
          @include transition(transform, .15s ease-in-out);
        }
      }
    }
  }

  .mega-menu-toggle.mega-menu-open {
    .mega-toggle-block-1 {
      .mega-toggle-animated-slider {
        .mega-toggle-animated-inner {
          transform: translate3d(0, 10px, 0) rotate(45deg);

          &::before {
            opacity: 0;
            transform: translate3d(0, 10px, 0) rotate(45deg);
          }

          &::after {
            transform: translate3d(0, -20px, 0) rotate(-90deg);
          }
        }
      }
    }
  }

  #mega-menu-primary.mega-menu-vertical {
    > li.mega-menu-item {
      display: list-item;
      width: 100%;
      margin: 0;
      clear: both;

      > a.mega-menu-link:not(.btn) {
        height: auto;
      }
    }

    > li.mega-menu-item.mega-icon-top {
      > a.mega-menu-link:not(.btn) {
        display: list-item;
      }
    }

    > li.mega-menu-megamenu.mega-menu-item {
      position: relative;
    }

    > li.mega-menu-flyout.mega-menu-item {
      position: relative;
    }

    li.mega-menu-megamenu.mega-align-bottom-right {
      > ul.mega-sub-menu {
        top: 0;
        right: auto;
        left: 100%;
      }
    }

    li.mega-menu-flyout.mega-align-bottom-right {
      ul.mega-sub-menu {
        top: 0;
        right: auto;
        left: 100%;

        li.mega-menu-item {
          ul.mega-sub-menu {
            top: 0;
            right: auto;
            left: 100%;
          }
        }

        li.mega-menu-item.mega-icon-left {
          > a.mega-menu-link:not(.btn) {
            &::before {
              float: left;
              margin: 0 $spacer * .375 0 0;
            }
          }
        }
      }

      li.mega-menu-item {
        ul.mega-sub-menu {
          top: 0;
          right: auto;
          left: 100%;
        }
      }

      li.mega-menu-item.mega-icon-left {
        > a.mega-menu-link:not(.btn) {
          &::before {
            float: left;
            margin: 0 $spacer * .375 0 0;
          }
        }
      }
    }

    li.mega-menu-flyout.mega-align-bottom-right.mega-icon-left {
      > a.mega-menu-link:not(.btn) {
        &::before {
          float: left;
          margin: 0 $spacer * .375 0 0;
        }
      }
    }

    li.mega-menu-megamenu.mega-align-bottom-left {
      > ul.mega-sub-menu {
        top: 0;
        right: 100%;
        left: auto;
      }
    }

    li.mega-menu-flyout.mega-align-bottom-left {
      > ul.mega-sub-menu {
        top: 0;
        right: 100%;
        left: auto;
      }

      li.mega-menu-item {
        > ul.mega-sub-menu {
          top: 0;
          right: 100%;
          left: auto;
        }

        ul.mega-sub-menu {
          li.mega-menu-item {
            > ul.mega-sub-menu {
              top: 0;
              right: 100%;
              left: auto;
            }
          }
        }
      }
    }
  }

  #mega-menu-primary.mega-menu-accordion {
    > li.mega-menu-item {
      display: list-item;
      width: 100%;
      margin: 0;
      clear: both;

      > a.mega-menu-link:not(.btn) {
        height: auto;
      }
    }

    > li.mega-menu-item.mega-icon-top {
      > a.mega-menu-link:not(.btn) {
        display: list-item;
      }
    }

    > li.mega-menu-megamenu {
      > ul.mega-sub-menu {
        position: static;
        width: 100% !important;
      }
    }

    > li.mega-menu-flyout {
      > ul.mega-sub-menu {
        position: static;
        width: 100% !important;
        padding: 0;

        li.mega-menu-item.mega-current-menu-item {
          > a.mega-menu-link:not(.btn) {
            font-weight: 400;
            color: $interactive;
            text-decoration: none;
            background: $white;
          }
        }

        li.mega-menu-item {
          ul.mega-sub-menu {
            position: static;
            left: 0;
            width: 100%;
            border: 0;
            box-shadow: none;

            a.mega-menu-link:not(.btn) {
              padding-left: 20px;
            }

            li.mega-menu-item {
              ul.mega-sub-menu {
                a.mega-menu-link:not(.btn) {
                  padding-left: 30px;
                }

                li.mega-menu-item {
                  ul.mega-sub-menu {
                    a.mega-menu-link:not(.btn) {
                      padding-left: 40px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    li.mega-menu-item {
      > ul.mega-sub-menu {
        display: none;
        visibility: visible;
        opacity: 1;
      }

      li.mega-menu-item-has-children {
        > a.mega-menu-link:not(.btn) {
          > span.mega-indicator {
            &::after {
              content: "\f347";
            }
          }
        }
      }

      li.mega-menu-item-has-children.mega-toggle-on {
        > a.mega-menu-link:not(.btn) {
          > span.mega-indicator {
            &::after {
              content: "\f343";
            }
          }
        }
      }
    }

    li.mega-menu-item.mega-toggle-on {
      > ul.mega-sub-menu {
        display: block;
      }
    }

    li.mega-menu-item.mega-menu-megamenu.mega-toggle-on {
      ul.mega-sub-menu {
        display: block;
      }
    }

    li.mega-menu-item-has-children.mega-toggle-on {
      > a.mega-menu-link:not(.btn) {
        > span.mega-indicator {
          &::after {
            content: "\f343";
          }
        }
      }
    }
  }

  > li.mega-menu-item.mega-current-menu-item {
    > a.mega-menu-logo {
      background: inherit;
    }
  }

  > li.mega-menu-item.mega-current-menu-ancestor {
    > a.mega-menu-logo {
      background: inherit;
    }
  }

  li.mega-menu-item {
    img.mega-menu-logo {
      display: inline;
      max-height: none;
      vertical-align: middle;
    }
  }
}

#mega-menu-wrap-utility {
  position: relative;

  #mega-menu-utility {
    padding-left: 0;
    margin: 0;
    list-style-type: none;

    ul.mega-sub-menu {
      list-style: none;

      &::before {
        display: none;
      }

      &::after {
        display: none;
      }
    }

    li.mega-menu-item {
      display: inline-block;

      &.login::before {
        width: 24px;
        height: 24px;
        margin-right: ($spacer * .5);
        font-family: $fa-style-family;
        font-size: rfs-value(22px);
        font-weight: $font-weight-normal;
        line-height: 28px;
        color: $interactive;
        text-align: center;
        vertical-align: top;
        content: fa-content($fa-var-circle-user);
        @extend %fa-icon;
        @extend .fa-regular;
        @include transition(color .15s ease-in-out);
      }

      &::before {
        display: none;
      }

      &::after {
        display: none;
      }

      .mega-menu-row::marker,
      .mega-menu-column::marker {
        content: none;
      }

      .mega-sub-menu {
        padding-left: 0;
      }

      > ul.mega-sub-menu {
        position: absolute;
        left: 0;
        z-index: 999;
        display: block;
        width: 50%;
        max-width: none;
        padding: $spacer * 1.5 $spacer * 2.5;
        margin-top: $spacer * 1.875;
        pointer-events: auto;
        visibility: hidden;
        background: $white;
        border: rfs-value(1px) solid $gray-100;
        opacity: 1;
        @include border-radius($border-radius);
        @include box-shadow($box-shadow);

        .mega-menu-item {
          float: left;
          clear: none;
          color: $primary;

          vertical-align: top;

          &:last-child {
            border-right: none;
          }

          > a.mega-menu-link:not(.btn) {
            @include padding-top(35px);
            @include padding-bottom(35px);
            @include padding-right(62px);
            @include padding-left(62px);
          }
        }
      }

      a.mega-menu-link:not(.btn) {
        font-weight: $font-weight-medium;
        vertical-align: middle;
      }

      &.get-info {
        a.mega-menu-link {
          color: $white;
        }
      }
    }

    a.mega-menu-link:not(.btn) {
      text-decoration: none;

      &::before {
        display: none;
      }

      &::after {
        display: none;
      }

      .mega-description-group {
        display: inline-block;
        vertical-align: middle;
        @include transition(none);

        .mega-menu-title {
          display: block;
          text-transform: initial;
          @include transition(none);
        }

        .mega-menu-description {
          display: block;
          font-size: $font-size-sm;
          font-style: normal;
          font-weight: $font-weight-normal;
          text-transform: none;
          @include transition(none);
        }
      }

      > img.wpml-ls-flag {
        display: inline;
        margin-right: 8px;
      }

      > img.iclflag {
        display: inline;
        margin-right: 8px;
      }
    }

    li.mega-menu-megamenu {
      li.mega-menu-item.mega-icon-left.mega-has-description.mega-has-icon {
        > a.mega-menu-link:not(.btn) {
          display: flex;
          align-items: center;

          &::before {
            flex: 0 0 auto;
            align-self: flex-start;
          }
        }
      }

      li.mega-disable-link {
        > a.mega-menu-link:not(.btn) {
          cursor: inherit;
        }
      }

      > li.mega-menu-item-has-children.mega-disable-link {
        > a.mega-menu-link:not(.btn) {
          cursor: pointer;
        }
      }

      > ul.mega-sub-menu {
        @include media-breakpoint-down(lg) {
          padding: 0;
        }

        > li.mega-menu-row {
          float: left;
          width: 100%;

          .mega-menu-column {
            @include media-breakpoint-down(lg) {
              &:first-child {
                > ul.mega-sub-menu {
                  > li.mega-menu-item {
                    &:first-child {
                      > a.mega-menu-link:not(.btn) {
                        padding-top: 0;
                      }
                    }
                  }
                }
              }
            }

            &:not(:last-child) {
              border-right: rfs-value(1px) solid $dove-400;
              @include media-breakpoint-down(lg) {
                border-right: 0;
              }
            }

            float: left;
            min-height: rfs-value(1px);

            &.cta {
              display: flex;
              align-items: center;
              justify-content: center;
              margin: 0 $spacer * 1.5;
              @include border-radius($border-radius-sm);
              @include media-breakpoint-down(lg) {
                @include border-radius(0);
                padding: $spacer * 2 0;
                margin: 0;
                background: none !important;
                border-bottom: rfs-value(1px) solid $dove-400;
              }

              > ul.mega-sub-menu {
                @include media-breakpoint-down(lg) {
                  @include border-radius($border-radius-sm);
                }

                > li.mega-menu-item {
                  border-bottom: 0;

                  > a.mega-menu-link:not(.btn) {
                    display: inherit;
                    text-align: center;
                    @include padding-top($spacer * 2);
                    @include padding-bottom($spacer * 2);
                    @include padding-right($spacer * 2.5);
                    @include padding-left($spacer * 2.5);

                    .mega-menu-title {
                      @extend .h4;
                      color: $white;
                      @include margin-bottom($spacer * 1.5);

                      &:hover {
                        color: $white;
                      }
                    }

                    .mega-menu-description {
                      @extend .btn;
                      @extend .btn-outline-white;
                      @extend .btn-sm;
                      //color: $interactive;
                      //font-weight: $font-weight-medium;
                      &::after {
                        margin-left: $spacer * .375;
                        font-family: $fa-style-family;
                        font-weight: $font-weight-normal;
                        color: $white;
                        text-align: center;
                        content: fa-content($fa-var-arrow-right);
                        @extend %fa-icon;
                        @extend .fa-regular;
                        @extend .fs-4;
                      }
                    }
                  }
                }
              }
            }

            > ul.mega-sub-menu {
              > li.mega-menu-item {
                @include media-breakpoint-down(lg) {
                  width: 100%;
                  border-bottom: rfs-value(1px) solid $dove-400;
                }

                > a.mega-menu-link:not(.btn) {
                  display: flex;
                  align-items: center;
                  @include padding-top($spacer * 2);
                  @include padding-bottom($spacer * 2);
                  @include media-breakpoint-down(lg) {
                    @include padding($spacer * 1.25 0);
                  }
                }
              }
            }
          }
        }
      }

      &:not(.mega-menu-tabbed) {
        li.mega-menu-item-has-children {
          &:not(.mega-collapse-children) {
            > a.mega-menu-link:not(.btn) {
              > span.mega-indicator {
                display: none;
              }
            }
          }
        }
      }
    }

    li.mega-menu-tabbed.mega-menu-megamenu {
      > ul.mega-sub-menu {
        > li.mega-menu-item.mega-icon-left.mega-has-description.mega-has-icon {
          > a.mega-menu-link:not(.btn) {
            display: block;
          }
        }
      }
    }

    li.mega-menu-item.mega-icon-top {
      > a.mega-menu-link:not(.btn) {
        display: table-cell;
        line-height: initial;
        vertical-align: middle;

        &::before {
          display: block;
          margin: 0 0 $spacer * .375;
          text-align: center;
        }

        > span.mega-title-below {
          display: inline-block;
          @include transition(none);
        }
      }
    }

    li.mega-menu-item.mega-icon-right {
      > a.mega-menu-link:not(.btn) {
        &::before {
          float: right;
          margin: 0 0 0 $spacer * .375;
        }
      }
    }

    > li.mega-animating {
      > ul.mega-sub-menu {
        pointer-events: none;
      }
    }

    li.mega-disable-link {
      > a.mega-menu-link:not(.btn) {
        cursor: inherit;
      }
    }

    li.mega-menu-item-has-children.mega-disable-link {
      > a.mega-menu-link:not(.btn) {
        cursor: pointer;
      }
    }

    p {
      margin-bottom: 10px;
    }

    input {
      max-width: 100%;
    }

    img {
      max-width: 100%;
    }

    li.mega-menu-item.mega-menu-megamenu {
      ul.mega-sub-menu {
        li.mega-collapse-children {
          > ul.mega-sub-menu {
            display: none;
          }
        }

        li.mega-collapse-children.mega-toggle-on {
          > ul.mega-sub-menu {
            display: block;
          }
        }

        ul.mega-sub-menu {
          display: block;
          visibility: inherit;
          opacity: 1;
        }

        li.mega-1-columns {
          > ul.mega-sub-menu {
            > li.mega-menu-item {
              float: left;
              width: 100%;
            }
          }
        }

        li.mega-2-columns {
          > ul.mega-sub-menu {
            > li.mega-menu-item {
              float: left;
              width: 50%;
            }
          }
        }

        li.mega-3-columns {
          > ul.mega-sub-menu {
            > li.mega-menu-item {
              float: left;
              width: 33.33333%;
            }
          }
        }

        li.mega-4-columns {
          > ul.mega-sub-menu {
            > li.mega-menu-item {
              float: left;
              width: 25%;
            }
          }
        }

        li.mega-5-columns {
          > ul.mega-sub-menu {
            > li.mega-menu-item {
              float: left;
              width: 20%;
            }
          }
        }

        li.mega-6-columns {
          > ul.mega-sub-menu {
            > li.mega-menu-item {
              float: left;
              width: 16.66667%;
            }
          }
        }
      }
    }

    li.mega-menu-item.mega-toggle-on {
      > ul.mega-sub-menu {
        left: -310px !important;
        width: 430px !important;
        visibility: visible;
      }
    }

    li.mega-menu-item.mega-hide-text {
      a.mega-menu-link:not(.btn) {
        &::before {
          margin: 0;
        }
      }

      li.mega-menu-item {
        a.mega-menu-link:not(.btn) {
          &::before {
            margin: 0 $spacer * .375 0 0;
          }
        }
      }
    }

    li.mega-align-bottom-left.mega-toggle-on {
      > a.mega-menu-link:not(.btn) {
        @include border-radius(0);
      }
    }

    li.mega-align-bottom-right {
      > ul.mega-sub-menu {
        right: 0;
      }
    }

    li.mega-align-bottom-right.mega-toggle-on {
      > a.mega-menu-link:not(.btn) {
        @include border-radius(0);
      }
    }

    > li.mega-menu-megamenu.mega-menu-item {
      position: static;
    }

    > li.mega-menu-item.mega-item-align-right {
      float: right;
    }

    > li.mega-menu-item.mega-toggle-on {
      background-color: $interactive-600;

      &::before {
        color: $white;
      }

      > a.mega-menu-link:not(.btn) {
        color: $white;
        text-decoration: none;
      }
    }

    > li.mega-menu-item.mega-current-menu-ancestor {
      > a.mega-menu-link:not(.btn) {
        color: $interactive;
        text-decoration: none;
      }
    }

    > li.mega-menu-item.mega-current-page-ancestor {
      > a.mega-menu-link:not(.btn) {
        color: $interactive;
        text-decoration: none;
      }
    }

    > li.mega-menu-megamenu {
      > ul.mega-sub-menu {

        .mega-description-group {
          .mega-menu-description {
            margin: 5px 0;
          }
        }

        > li.mega-menu-item {
          ul.mega-sub-menu {
            clear: both;

            li.mega-menu-item {
              ul.mega-sub-menu {
                margin-left: 10px;
              }
            }
          }

          h4.mega-block-title {
            display: block;
            padding: 0 0 5px;
            margin: 0;
            font-family: $font-family1;
            font-size: 1 $spacer * .375;
            font-weight: $font-weight-bold;
            color: $color1;
            text-align: left;
            text-decoration: none;
            text-transform: uppercase;
            vertical-align: top;
            visibility: inherit;
            border: 0;

            &:hover {
              border-color: $border-color2;
            }
          }

          > a.mega-menu-link:not(.btn) {
            font-weight: $font-weight-bold;
            color: $primary;
            text-align: left;
            text-decoration: none;
            text-transform: initial;
            vertical-align: top;

            &:hover {
              font-weight: $font-weight-bold;
              color: $interactive;
              text-decoration: none;
              border-color: $border-color2;

              > span.mega-title-below {
                text-decoration: none;
              }
            }

            &:focus {
              font-weight: $font-weight-bold;
              color: $interactive;
              text-decoration: none;
              background: rgba($black, 0);

              > span.mega-title-below {
                text-decoration: none;
              }
            }
          }

          li.mega-menu-item {
            > a.mega-menu-link:not(.btn) {
              display: block;
              padding: 0;
              margin: 0;
              font-family: $font-family1;
              font-size: 14px;
              color: $denim;
              text-align: left;
              text-decoration: none;
              text-transform: none;
              vertical-align: top;
              border: 0;

              &:hover {
                color: $interactive;
                text-decoration: none;
                background: rgba($black, 0);
                border-color: $border-color2;
              }

              &:focus {
                color: $interactive;
                text-decoration: none;
                background: rgba($black, 0);
              }
            }
          }

          li.mega-menu-item.mega-icon-left.mega-has-description.mega-has-icon {
            > a.mega-menu-link:not(.btn) {
              display: flex;
            }
          }
        }

        li.mega-menu-column {
          > ul.mega-sub-menu {
            ul.mega-sub-menu {
              ul.mega-sub-menu {
                margin-left: 10px;
              }
            }

            > li.mega-menu-item {
              display: block;
              float: left;
              padding: 0;
              clear: none;
              font-family: $font-family1;
              font-size: 14px;
              color: $color1;
              vertical-align: top;

              h4.mega-block-title {
                display: block;
                padding: 0 0 5px;
                margin: 0;
                font-family: $font-family1;
                font-size: 1 $spacer * .375;
                font-weight: 700;
                color: $color1;
                text-align: left;
                text-decoration: none;
                text-transform: uppercase;
                vertical-align: top;
                visibility: inherit;
                border: 0;

                &:hover {
                  border-color: $border-color2;
                }
              }

              > a.mega-menu-link:not(.btn) {
                display: block;
                padding: 0;
                margin: 0;
                font-family: $font-family1;
                font-size: $font-size-base;
                font-weight: 700;
                color: $color1;
                text-align: left;
                text-decoration: none;
                text-transform: uppercase;
                vertical-align: top;
                border: 0;

                &:hover {
                  font-weight: 700;
                  color: $interactive;
                  text-decoration: none;
                  background: rgba($black, 0);
                  border-color: $border-color2;

                  > span.mega-title-below {
                    text-decoration: none;
                  }
                }

                &:focus {
                  font-weight: 700;
                  color: $interactive;
                  text-decoration: none;
                  background: rgba($black, 0);

                  > span.mega-title-below {
                    text-decoration: none;
                  }
                }
              }

              li.mega-menu-item {
                > a.mega-menu-link:not(.btn) {
                  display: block;
                  padding: 0;
                  margin: 0;
                  font-family: $font-family1;
                  font-size: 14px;
                  font-weight: 400;
                  color: $color1;
                  text-align: left;
                  text-decoration: none;
                  text-transform: none;
                  vertical-align: top;
                  border: 0;

                  &:hover {
                    font-weight: 400;
                    color: $interactive;
                    text-decoration: none;
                    background: rgba($black, 0);
                    border-color: $border-color2;
                  }

                  &:focus {
                    font-weight: 400;
                    color: $interactive;
                    text-decoration: none;
                    background: rgba($black, 0);
                  }
                }
              }

              li.mega-menu-item.mega-icon-left.mega-has-description.mega-has-icon {
                > a.mega-menu-link:not(.btn) {
                  display: flex;
                }
              }
            }

            > li.mega-menu-item.mega-menu-clear {
              clear: left;
            }
          }
        }

        > li.mega-menu-item.mega-menu-clear {
          clear: left;
        }
      }
    }

    > li.mega-menu-megamenu.mega-no-headers {
      > ul.mega-sub-menu {
        > li.mega-menu-item {
          > a.mega-menu-link:not(.btn) {
            display: block;
            padding: 0;
            margin: 0;
            font-family: $font-family1;
            font-size: 14px;
            font-weight: 400;
            color: $color1;
            text-decoration: none;
            text-transform: none;
            vertical-align: top;
            border: 0;

            &:hover {
              font-weight: 400;
              color: $interactive;
              text-decoration: none;
              background: rgba($black, 0);
            }

            &:focus {
              font-weight: 400;
              color: $interactive;
              text-decoration: none;
              background: rgba($black, 0);
            }
          }
        }

        li.mega-menu-column {
          > ul.mega-sub-menu {
            > li.mega-menu-item {
              > a.mega-menu-link:not(.btn) {
                display: block;
                padding: 0;
                margin: 0;
                font-family: $font-family1;
                font-size: 14px;
                font-weight: 400;
                color: $color1;
                text-decoration: none;
                text-transform: none;
                vertical-align: top;
                border: 0;

                &:hover {
                  font-weight: 400;
                  color: $interactive;
                  text-decoration: none;
                  background: rgba($black, 0);
                }

                &:focus {
                  font-weight: 400;
                  color: $interactive;
                  text-decoration: none;
                  background: rgba($black, 0);
                }
              }
            }
          }
        }
      }
    }

    > li.mega-menu-flyout {
      ul.mega-sub-menu {
        position: absolute;
        z-index: 999;
        width: 250px;
        max-width: none;
        padding: 0;
        background: $white;
        border: rfs-value(1px) solid $gray-100;
        @include border-radius($border-radius-lg);
        @include box-shadow($box-shadow-xxxl);

        li.mega-menu-item {
          a.mega-menu-link:not(.btn) {
            display: block;
            padding: 0 10px;
            font-family: $font-family1;
            font-size: 14px;
            font-weight: 400;
            line-height: 35px;
            color: $color1;
            text-decoration: none;
            text-transform: none;
            vertical-align: baseline;
            background: $white;

            &:hover {
              font-weight: 400;
              color: $interactive;
              text-decoration: none;
              background: $white;
            }

            &:focus {
              font-weight: 400;
              color: $interactive;
              text-decoration: none;
              background: $white;
            }
          }

          &:first-child {
            > a.mega-menu-link:not(.btn) {
              @include border-top-radius($border-radius-lg);
            }
          }

          &:last-child {
            > a.mega-menu-link:not(.btn) {
              @include border-bottom-radius($border-radius-lg);
            }
          }

          ul.mega-sub-menu {
            position: absolute;
            top: 0;
            left: 100%;
          }
        }
      }
    }

    li.mega-menu-item-has-children {
      > a.mega-menu-link:not(.btn) {
        cursor: pointer;

        > span.mega-indicator {
          position: absolute;
          top: 0;
          left: 0;
          display: block;
          width: $spacer * 7.5;
          height: $spacer * 3.125;
          @include padding($spacer * .625);
        }
      }

      li.mega-menu-item-has-children {
        > a.mega-menu-link:not(.btn) {
          > span.mega-indicator {
            float: right;
          }
        }
      }
    }

    li.mega-menu-item-has-children.mega-toggle-on {
      > a.mega-menu-link:not(.btn) {
        > span.mega-indicator {
          &::after {
            top: rfs-value(2px);
            transform: rotateX(-180deg);
          }
        }
      }
    }

    li.mega-menu-item-has-children.mega-hide-arrow {
      > a.mega-menu-link:not(.btn) {
        > span.mega-indicator {
          display: none;
        }
      }
    }

    li[class^="mega-lang-item"] {
      > a.mega-menu-link:not(.btn) {
        > img {
          display: inline;
        }
      }
    }

    li.mega-menu-item.mega-show-when-sticky {
      display: none;
    }

    li.mega-partner {
      > a.mega-menu-link:not(.btn) {
        &::before {
          width: 24px;
          height: 24px;
          font-family: $fa-style-family;
          font-size: rfs-value(22px);
          font-weight: $font-weight-normal;
          line-height: 24px;
          color: $interactive;
          text-align: center;
          vertical-align: text-top;
          content: fa-content($fa-var-landmark);
          @extend %fa-icon;
          @extend .fa-regular;
        }
      }
    }

    li.mega-sales {
      > a.mega-menu-link:not(.btn) {
        &::before {
          width: 24px;
          height: 24px;
          font-family: $fa-style-family;
          font-size: rfs-value(22px);
          font-weight: $font-weight-normal;
          line-height: 24px;
          color: $interactive;
          text-align: center;
          vertical-align: text-top;
          content: fa-content($fa-var-chart-column);
          @extend %fa-icon;
          @extend .fa-regular;
        }
      }
    }

    li.mega-benji {
      > a.mega-menu-link:not(.btn) {
        &::before {
          width: 24px;
          height: 24px;
          font-family: $fa-style-family;
          font-size: rfs-value(22px);
          font-weight: $font-weight-normal;
          line-height: 24px;
          color: $interactive;
          text-align: center;
          vertical-align: text-top;
          content: fa-content($fa-var-circle-dollar);
          @extend %fa-icon;
          @extend .fa-regular;
        }
      }
    }

    li.mega-mobile {
      > a.mega-menu-link:not(.btn) {
        &::before {
          width: 24px;
          height: 24px;
          font-family: $fa-style-family;
          font-size: rfs-value(22px);
          font-weight: $font-weight-normal;
          line-height: 24px;
          color: $interactive;
          text-align: center;
          vertical-align: text-top;
          content: fa-content($fa-var-mobile-button);
          @extend %fa-icon;
          @extend .fa-regular;
        }
      }
    }

    li.mega-register {
      > a.mega-menu-link:not(.btn) {
        &::before {
          display: block;
          width: 48px;
          height: 48px;
          margin-right: auto;
          margin-left: auto;
          font-family: $fa-style-family;
          font-size: rfs-value(4 $spacer * .375);
          font-weight: $font-weight-light;
          line-height: 48px;
          color: $jade-500;
          text-align: center;
          vertical-align: text-top;
          content: fa-content($fa-var-truck-fast);
          @extend %fa-icon;
          @extend .fa-light;
          @include margin-bottom($spacer * 2);

        }
      }
    }

    li.mega-works {
      > a.mega-menu-link:not(.btn) {
        &::before {
          width: 24px;
          height: 24px;
          font-family: $fa-style-family;
          font-size: rfs-value(22px);
          font-weight: $font-weight-normal;
          line-height: 24px;
          color: $interactive;
          text-align: center;
          vertical-align: text-top;
          content: fa-content($fa-var-bolt);
          @extend %fa-icon;
          @extend .fa-regular;
        }
      }
    }

    li.mega-eligibility {
      > a.mega-menu-link:not(.btn) {
        &::before {
          width: 24px;
          height: 24px;
          font-family: $fa-style-family;
          font-size: rfs-value(22px);
          font-weight: $font-weight-normal;
          line-height: 24px;
          color: $interactive;
          text-align: center;
          vertical-align: text-top;
          content: fa-content($fa-var-shield-check);
          @extend %fa-icon;
          @extend .fa-regular;
        }
      }
    }

    li.mega-improvements {
      > a.mega-menu-link:not(.btn) {
        &::before {
          width: 24px;
          height: 24px;
          font-family: $fa-style-family;
          font-size: rfs-value(22px);
          font-weight: $font-weight-normal;
          line-height: 24px;
          color: $interactive;
          text-align: center;
          vertical-align: text-top;
          content: fa-content($fa-var-house);
          @extend %fa-icon;
          @extend .fa-regular;
        }
      }
    }

    li.mega-find {
      > a.mega-menu-link:not(.btn) {
        &::before {
          width: 24px;
          height: 24px;
          font-family: $fa-style-family;
          font-size: rfs-value(22px);
          font-weight: $font-weight-normal;
          line-height: 24px;
          color: $interactive;
          text-align: center;
          vertical-align: text-top;
          content: fa-content($fa-var-magnifying-glass);
          @extend %fa-icon;
          @extend .fa-regular;
        }
      }
    }

    li.mega-contractor {
      > a.mega-menu-link:not(.btn) {
        &::before {
          display: block;
          width: 48px;
          height: 48px;
          margin-right: auto;
          margin-left: auto;
          font-family: $fa-style-family;
          font-size: rfs-value(4 $spacer * .375);
          font-weight: $font-weight-light;
          line-height: 48px;
          color: $tangerine-500;
          text-align: center;
          vertical-align: text-top;
          content: fa-content($fa-var-screwdriver-wrench);
          @extend %fa-icon;
          @extend .fa-light;
          @include margin-bottom($spacer * 2);
        }
      }
    }

    li.mega-support {
      > a.mega-menu-link:not(.btn) {
        &::before {
          width: 24px;
          height: 24px;
          font-family: $fa-style-family;
          font-size: rfs-value(22px);
          font-weight: $font-weight-normal;
          line-height: 24px;
          color: $interactive;
          text-align: center;
          vertical-align: text-top;
          content: fa-content($fa-var-circle-question);
          @extend %fa-icon;
          @extend .fa-regular;
        }
      }
    }

    li.mega-blog {
      > a.mega-menu-link:not(.btn) {
        &::before {
          width: 24px;
          height: 24px;
          font-family: $fa-style-family;
          font-size: rfs-value(22px);
          font-weight: $font-weight-normal;
          line-height: 24px;
          color: $interactive;
          text-align: center;
          vertical-align: text-top;
          content: fa-content($fa-var-pen-to-square);
          @extend %fa-icon;
          @extend .fa-regular;
        }
      }
    }

    li.mega-philanthropy {
      > a.mega-menu-link:not(.btn) {
        &::before {
          width: 24px;
          height: 24px;
          font-family: $fa-style-family;
          font-size: rfs-value(22px);
          font-weight: $font-weight-normal;
          line-height: 24px;
          color: $interactive;
          text-align: center;
          vertical-align: text-top;
          content: fa-content($fa-var-box-heart);
          @extend %fa-icon;
          @extend .fa-regular;
        }
      }
    }

    li.mega-about {
      > a.mega-menu-link:not(.btn) {
        &::before {
          width: 24px;
          height: 24px;
          font-family: $fa-style-family;
          font-size: rfs-value(22px);
          font-weight: $font-weight-normal;
          line-height: 24px;
          color: $interactive;
          text-align: center;
          vertical-align: text-top;
          content: fa-content($fa-var-landmark);
          @extend %fa-icon;
          @extend .fa-regular;
        }
      }
    }

    li.mega-careers {
      > a.mega-menu-link:not(.btn) {
        &::before {
          width: 24px;
          height: 24px;
          font-family: $fa-style-family;
          font-size: rfs-value(22px);
          font-weight: $font-weight-normal;
          line-height: 24px;
          color: $interactive;
          text-align: center;
          vertical-align: text-top;
          content: fa-content($fa-var-pen-to-square);
          @extend %fa-icon;
          @extend .fa-regular;
        }
      }
    }

    li.mega-contact-us {
      > a.mega-menu-link:not(.btn) {
        &::before {
          width: 24px;
          height: 24px;
          font-family: $fa-style-family;
          font-size: rfs-value(22px);
          font-weight: $font-weight-normal;
          line-height: 24px;
          color: $interactive;
          text-align: center;
          vertical-align: text-top;
          content: fa-content($fa-var-envelope);
          @extend %fa-icon;
          @extend .fa-regular;
        }
      }
    }

    li.mega-support-contact {
      > a.mega-menu-link:not(.btn) {
        &::before {
          display: block;
          width: 48px;
          height: 48px;
          margin-right: auto;
          margin-left: auto;
          font-family: $fa-style-family;
          font-size: rfs-value(4 $spacer * .375);
          font-weight: $font-weight-light;
          line-height: 48px;
          color: $electric-500;
          text-align: center;
          vertical-align: text-top;
          content: fa-content($fa-var-messages-question);
          @extend %fa-icon;
          @extend .fa-light;
          @include margin-bottom($spacer * 2);
        }
      }
    }

    li.mega-new-customer-login {
      > a.mega-menu-link:not(.btn) {
        &::before {
          width: 24px;
          height: 24px;
          margin: 0 rfs-value(15px) 0 0;
          font-family: $fa-style-family;
          font-size: rfs-value(22px);
          font-weight: $font-weight-normal;
          line-height: 24px;
          color: $interactive;
          vertical-align: text-top;
          content: fa-content($fa-var-clipboard-list-check);
          @extend %fa-icon;
          @extend .fa-regular;
        }
      }
    }

    li.mega-current-customer-login {
      @include padding-bottom($spacer * 1.5 !important);
      > a.mega-menu-link:not(.btn) {
        &::before {
          width: 24px;
          height: 24px;
          margin: 0 rfs-value(15px) 0 0;
          font-family: $fa-style-family;
          font-size: rfs-value(22px);
          font-weight: $font-weight-normal;
          line-height: 24px;
          color: $interactive;
          vertical-align: text-top;
          content: fa-content($fa-var-circle-dollar);
          @extend %fa-icon;
          @extend .fa-regular;
        }
      }
    }

    li.mega-partner-login {
      border-top: rfs-value(1px) solid $dove-400;
      @include padding-top($spacer * 1.5 !important);
      > a.mega-menu-link:not(.btn) {
        &::before {
          width: 24px;
          height: 24px;
          margin: 0 rfs-value(15px) 0 0;
          font-family: $fa-style-family;
          font-size: rfs-value(22px);
          font-weight: $font-weight-normal;
          line-height: 24px;
          color: $interactive;
          vertical-align: text-top;
          content: fa-content($fa-var-chart-line-up);
          @extend %fa-icon;
          @extend .fa-regular;
        }
      }
    }

    > li.mega-menu-tabbed {
      > ul.mega-sub-menu {
        padding: 0;

        > li.mega-menu-item {
          position: static;
          width: 100%;
          padding: 0;

          > a.mega-menu-link:not(.btn) {
            float: left;
            width: 20%;
            padding: 0 10px;
            margin: 0;
            font-family: $font-family1;
            font-size: 14px;
            font-weight: 400;
            line-height: 35px;
            color: $color5;
            text-decoration: none;
            text-transform: none;
            vertical-align: baseline;
            background: #f1f1f1;
            border: 0;

            &:hover {
              font-weight: 400;
              color: $color5;
              text-decoration: none;
              background: #ddd;
            }

            &:focus {
              font-weight: 400;
              color: $color5;
              text-decoration: none;
              background: #ddd;
            }
          }

          > ul.mega-sub-menu {
            position: absolute;
            top: 0;
            left: 20%;
            float: left;
            width: 80%;
            min-height: 100%;
            padding: 0;
            clear: none;
            visibility: hidden;
            border-left: 1px solid #e1e1e1;

            .mega-menu-item-has-children {
              > a.mega-menu-link:not(.btn) {
                &::after {
                  display: none;
                }
              }
            }

            > li.mega-menu-item {
              float: left;
              padding: 35px 32px;

              > a.mega-menu-link:not(.btn) {
                display: block;
                padding: 0;
                margin: 0;
                font-family: $font-family1;
                font-size: 1 $spacer * .375;
                font-weight: 700;
                color: $color1;
                text-decoration: none;
                text-transform: uppercase;
                vertical-align: top;
                border-top: 0 solid rgba($black, 0);
                border-right: 0 solid rgba($black, 0);
                border-bottom: 0 solid rgba($black, 0);
                border-left: 0 solid rgba($black, 0);

                &:hover {
                  font-weight: 700;
                  color: $interactive;
                  text-decoration: none;
                  background: rgba($black, 0);

                  > span.mega-title-below {
                    text-decoration: none;
                  }
                }

                &:focus {
                  font-weight: 700;
                  color: $interactive;
                  text-decoration: none;
                  background: rgba($black, 0);

                  > span.mega-title-below {
                    text-decoration: none;
                  }
                }
              }

              li.mega-menu-item {
                > a.mega-menu-link:not(.btn) {
                  display: block;
                  padding: 0;
                  margin: 0;
                  font-family: $font-family1;
                  font-size: 14px;
                  font-weight: 400;
                  color: $color1;
                  text-decoration: none;
                  text-transform: none;
                  vertical-align: top;

                  &:hover {
                    font-weight: 400;
                    color: $interactive;
                    text-decoration: none;
                    background: rgba($black, 0);
                  }

                  &:focus {
                    font-weight: 400;
                    color: $interactive;
                    text-decoration: none;
                    background: rgba($black, 0);
                  }
                }
              }
            }

            > li.mega-menu-item.mega-menu-clear {
              clear: left;
            }
          }

          ul.mega-sub-menu {
            li.mega-menu-item {
              ul.mega-sub-menu {
                margin-left: 0;

                li.mega-menu-item {
                  ul.mega-sub-menu {
                    margin-left: 10px;
                  }
                }
              }
            }
          }
        }

        > li.mega-menu-item.mega-toggle-on {
          > a.mega-menu-link:not(.btn) {
            font-weight: 400;
            color: $color5;
            text-decoration: none;
            background: #ddd;
          }

          > ul.mega-sub-menu {
            visibility: visible;
          }
        }

        > li.mega-menu-item.mega-menu-item-has-children {
          > a.mega-menu-link:not(.btn) {
            > span.mega-indicator {
              &::after {
                content: "\f345";
              }
            }
          }
        }

        > li.mega-menu-item.mega-no-headers {
          > ul.mega-sub-menu {
            > li.mega-menu-item {
              > a.mega-menu-link:not(.btn) {
                display: block;
                padding: 0;
                margin: 0;
                font-family: $font-family1;
                font-size: 14px;
                font-weight: 400;
                color: $color1;
                text-decoration: none;
                text-transform: none;
                vertical-align: top;
                border-top: 0;
                border-right: 0;
                border-bottom: 0;
                border-left: 0;

                &:hover {
                  font-weight: 400;
                  color: $interactive;
                  text-decoration: none;
                  background: rgba($black, 0);
                }

                &:focus {
                  font-weight: 400;
                  color: $interactive;
                  text-decoration: none;
                  background: rgba($black, 0);
                }
              }
            }
          }
        }
      }
    }

    li.mega-menu-item.mega-icon-left {
      > a[class^="fas"] {
        &::before {
          width: 1.25em;
          text-align: center;
        }
      }

      > a[class^="fab"] {
        &::before {
          width: 1.25em;
          text-align: center;
        }
      }

      > a[class^="far"] {
        &::before {
          width: 1.25em;
          text-align: center;
        }
      }

      > a[class^="fal"] {
        &::before {
          width: 1.25em;
          text-align: center;
        }
      }
    }
  }

  li.mega-login {
    @include margin-right($spacer * 1);
    @include padding($spacer * .5 $spacer * 1);
    @include font-size($spacer * 1.125);
  }

  &::before {
    display: none;
  }

  &::after {
    display: none;
  }

  #mega-menu-utility.mega-no-js {
    li.mega-menu-item {
      &:hover {
        > ul.mega-sub-menu {
          visibility: visible;
        }
      }

      &:focus {
        > ul.mega-sub-menu {
          visibility: visible;
        }
      }
    }
  }

  .mega-menu-toggle {
    z-index: 1;
    display: none;
    height: 40px;
    line-height: 40px;
    text-align: left;
    white-space: nowrap;
    cursor: pointer;
    user-select: none;
    outline: 0;
    -webkit-tap-highlight-color: transparent;

    img {
      max-width: 100%;
      padding: 0;
    }

    .mega-toggle-blocks-left {
      display: -webkit-box;
      display: flex;
      -ms-flex-preferred-size: 33.33%;
      -webkit-box-flex: 1;
      flex: 1;
      -webkit-box-pack: start;
      -ms-flex-pack: start;
      justify-content: flex-start;

      .mega-toggle-block {
        margin-left: $spacer * .25;

        &:only-child {
          margin-right: $spacer * .25;
        }
      }
    }

    .mega-toggle-blocks-center {
      display: -webkit-box;
      display: flex;
      -ms-flex-preferred-size: 33.33%;
      flex-basis: 33.33%;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;

      .mega-toggle-block {
        margin-right: 3px;
        margin-left: 3px;
      }
    }

    .mega-toggle-blocks-right {
      display: -webkit-box;
      display: flex;
      flex: 1;
      flex-basis: 33.33%;
      justify-content: flex-end;
      -ms-flex-preferred-size: 33.33%;
      -webkit-box-flex: 1;
      -webkit-box-pack: end;
      -ms-flex-pack: end;

      .mega-toggle-block {
        margin-right: $spacer * .375;

        &:only-child {
          margin-left: $spacer * .375;
        }
      }
    }

    .mega-toggle-block {
      display: -webkit-box;
      display: flex;
      flex-shrink: 0;
      align-self: center;
      height: 100%;
      outline: 0;
      -ms-flex-item-align: center;
      -ms-flex-negative: 0;
    }

    .mega-toggle-block-1 {
      cursor: pointer;

      .mega-toggle-animated {
        display: -webkit-box;
        display: flex;
        align-self: center;
        padding: 0;
        margin: 0;
        overflow: visible;
        font: inherit;
        text-transform: none;
        cursor: pointer;
        background: $primary;
        background: none;
        border: 0;
        outline: 0;
        transition-timing-function: linear;
        transition-duration: .15s;
        transition-property: opacity, filter;
        transform: scale(.8);
      }

      .mega-toggle-animated-box {
        position: relative;
        display: inline-block;
        width: 30px;
        height: 20px;
        outline: 0;
      }

      .mega-toggle-animated-inner {
        position: absolute;
        top: 0;
        display: block;
        width: 30px;
        height: 0;
        margin-top: rfs-value(-2px);
        background-color: $primary;
        border: rfs-value(2px) solid $primary;
        @include border-radius(rfs-value(4px));
        @include transition(transform, .15s ease-in-out);

        &::before {
          position: absolute;
          top: 8px;
          right: -2px;
          display: block;
          width: 25px;
          height: 0;
          content: "";
          background-color: $primary;
          border: rfs-value(2px) solid $primary;
          opacity: 1;
          @include border-radius(rfs-value(4px));
          @include transition(transform, .15s ease-in-out, opacity .15s ease-in-out);
        }

        &::after {
          position: absolute;
          top: 18px;
          right: -2px;
          display: block;
          width: 30px;
          height: 0;
          content: "";
          background-color: $primary;
          border: rfs-value(2px) solid $primary;
          @include border-radius(rfs-value(4px));
          @include transition(transform, .15s ease-in-out);
        }
      }
    }
  }

  .mega-menu-toggle.mega-menu-open {
    .mega-toggle-block-1 {
      .mega-toggle-animated-slider {
        .mega-toggle-animated-inner {
          transform: translate3d(0, 10px, 0) rotate(45deg);

          &::before {
            opacity: 0;
            transform: translate3d(0, 10px, 0) rotate(45deg);
          }

          &::after {
            transform: translate3d(0, -20px, 0) rotate(-90deg);
          }
        }
      }
    }
  }

  #mega-menu-utility.mega-menu-vertical {
    > li.mega-menu-item {
      display: list-item;
      width: 100%;
      margin: 0;
      clear: both;

      > a.mega-menu-link:not(.btn) {
        height: auto;
      }
    }

    > li.mega-menu-item.mega-icon-top {
      > a.mega-menu-link:not(.btn) {
        display: list-item;
      }
    }

    > li.mega-menu-megamenu.mega-menu-item {
      position: relative;
    }

    > li.mega-menu-flyout.mega-menu-item {
      position: relative;
    }

    li.mega-menu-megamenu.mega-align-bottom-right {
      > ul.mega-sub-menu {
        top: 0;
        right: auto;
        left: 100%;
      }
    }

    li.mega-menu-flyout.mega-align-bottom-right {
      ul.mega-sub-menu {
        top: 0;
        right: auto;
        left: 100%;

        li.mega-menu-item {
          ul.mega-sub-menu {
            top: 0;
            right: auto;
            left: 100%;
          }
        }

        li.mega-menu-item.mega-icon-left {
          > a.mega-menu-link:not(.btn) {
            &::before {
              float: left;
              margin: 0 $spacer * .375 0 0;
            }
          }
        }
      }

      li.mega-menu-item {
        ul.mega-sub-menu {
          top: 0;
          right: auto;
          left: 100%;
        }
      }

      li.mega-menu-item.mega-icon-left {
        > a.mega-menu-link:not(.btn) {
          &::before {
            float: left;
            margin: 0 $spacer * .375 0 0;
          }
        }
      }
    }

    li.mega-menu-flyout.mega-align-bottom-right.mega-icon-left {
      > a.mega-menu-link:not(.btn) {
        &::before {
          float: left;
          margin: 0 $spacer * .375 0 0;
        }
      }
    }

    li.mega-menu-megamenu.mega-align-bottom-left {
      > ul.mega-sub-menu {
        top: 0;
        right: 100%;
        left: auto;
      }
    }

    li.mega-menu-flyout.mega-align-bottom-left {
      > ul.mega-sub-menu {
        top: 0;
        right: 100%;
        left: auto;
      }

      li.mega-menu-item {
        > ul.mega-sub-menu {
          top: 0;
          right: 100%;
          left: auto;
        }

        ul.mega-sub-menu {
          li.mega-menu-item {
            > ul.mega-sub-menu {
              top: 0;
              right: 100%;
              left: auto;
            }
          }
        }
      }
    }
  }

  #mega-menu-utility.mega-menu-accordion {
    > li.mega-menu-item {
      display: list-item;
      width: 100%;
      margin: 0;
      clear: both;

      > a.mega-menu-link:not(.btn) {
        height: auto;
      }
    }

    > li.mega-menu-item.mega-icon-top {
      > a.mega-menu-link:not(.btn) {
        display: list-item;
      }
    }

    > li.mega-menu-megamenu {
      > ul.mega-sub-menu {
        position: static;
        width: 100% !important;
      }
    }

    > li.mega-menu-flyout {
      > ul.mega-sub-menu {
        position: static;
        width: 100% !important;
        padding: 0;

        li.mega-menu-item.mega-current-menu-item {
          > a.mega-menu-link:not(.btn) {
            font-weight: 400;
            color: $interactive;
            text-decoration: none;
            background: $white;
          }
        }

        li.mega-menu-item {
          ul.mega-sub-menu {
            position: static;
            left: 0;
            width: 100%;
            border: 0;
            box-shadow: none;

            a.mega-menu-link:not(.btn) {
              padding-left: 20px;
            }

            li.mega-menu-item {
              ul.mega-sub-menu {
                a.mega-menu-link:not(.btn) {
                  padding-left: 30px;
                }

                li.mega-menu-item {
                  ul.mega-sub-menu {
                    a.mega-menu-link:not(.btn) {
                      padding-left: 40px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    li.mega-menu-item {
      > ul.mega-sub-menu {
        display: none;
        visibility: visible;
        opacity: 1;
      }

      li.mega-menu-item-has-children {
        > a.mega-menu-link:not(.btn) {
          > span.mega-indicator {
            &::after {
              content: "\f347";
            }
          }
        }
      }

      li.mega-menu-item-has-children.mega-toggle-on {
        > a.mega-menu-link:not(.btn) {
          > span.mega-indicator {
            &::after {
              content: "\f343";
            }
          }
        }
      }
    }

    li.mega-menu-item.mega-toggle-on {
      > ul.mega-sub-menu {
        display: block;
      }
    }

    li.mega-menu-item.mega-menu-megamenu.mega-toggle-on {
      ul.mega-sub-menu {
        display: block;
      }
    }

    li.mega-menu-item-has-children.mega-toggle-on {
      > a.mega-menu-link:not(.btn) {
        > span.mega-indicator {
          &::after {
            content: "\f343";
          }
        }
      }
    }
  }

  > li.mega-menu-item.mega-current-menu-item {
    > a.mega-menu-logo {
      background: inherit;
    }
  }

  > li.mega-menu-item.mega-current-menu-ancestor {
    > a.mega-menu-logo {
      background: inherit;
    }
  }

  li.mega-menu-item {
    img.mega-menu-logo {
      display: inline;
      max-height: none;
      vertical-align: middle;
    }
  }
}

#mega-menu-wrap-primary.mega-keyboard-navigation {
  .mega-menu-toggle {
    &:focus {
      outline: 3px solid #109cde;
      outline-offset: -3px;
    }
  }

  .mega-toggle-block {
    &:focus {
      outline: 3px solid #109cde;
      outline-offset: -3px;
    }

    a {
      &:focus {
        outline: 3px solid #109cde;
        outline-offset: -3px;
      }
    }

    .mega-search {
      input[type="text"] {
        &:focus {
          outline: 3px solid #109cde;
          outline-offset: -3px;
        }
      }
    }

    button.mega-toggle-animated {
      &:focus {
        outline: 3px solid #109cde;
        outline-offset: 2px;
      }
    }
  }

  #mega-menu-primary {
    a {
      &:focus {
        outline: 3px solid #109cde;
        outline-offset: -3px;
      }
    }

    span {
      &:focus {
        outline: 3px solid #109cde;
        outline-offset: -3px;
      }
    }

    input {
      &:focus {
        outline: 3px solid #109cde;
        outline-offset: -3px;
      }
    }

    li.mega-menu-item {
      a.mega-menu-link:not(.btn) {
        &:focus {
          outline: 3px solid #109cde;
          outline-offset: -3px;
        }
      }
    }
  }

  > li.mega-menu-item {
    > a.mega-menu-link:not(.btn) {
      &:focus {
        color: $interactive;
        text-decoration: none;
      }
    }
  }
}

#mega-menu-wrap-utility.mega-keyboard-navigation {
  .mega-menu-toggle {
    &:focus {
      outline: 3px solid #109cde;
      outline-offset: -3px;
    }
  }

  .mega-toggle-block {
    &:focus {
      outline: 3px solid #109cde;
      outline-offset: -3px;
    }

    a {
      &:focus {
        outline: 3px solid #109cde;
        outline-offset: -3px;
      }
    }

    .mega-search {
      input[type="text"] {
        &:focus {
          outline: 3px solid #109cde;
          outline-offset: -3px;
        }
      }
    }

    button.mega-toggle-animated {
      &:focus {
        outline: 3px solid #109cde;
        outline-offset: 2px;
      }
    }
  }

  #mega-menu-utility {
    a {
      &:focus {
        outline: 3px solid #109cde;
        outline-offset: -3px;
      }
    }

    span {
      &:focus {
        outline: 3px solid #109cde;
        outline-offset: -3px;
      }
    }

    input {
      &:focus {
        outline: 3px solid #109cde;
        outline-offset: -3px;
      }
    }

    li.mega-menu-item {
      a.mega-menu-link:not(.btn) {
        &:focus {
          outline: 3px solid #109cde;
          outline-offset: -3px;
        }
      }
    }
  }

  > li.mega-menu-item {
    > a.mega-menu-link:not(.btn) {
      &:focus {
        color: $interactive;
        text-decoration: none;
      }
    }
  }
}

#mega-menu-wrap-primary.mega-sticky {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 99998;
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  background: transparent;
  opacity: 1;

  #mega-menu-primary {
    background: $white;

    li.mega-menu-item.mega-hide-when-sticky {
      display: none;
    }

    li.mega-menu-item.mega-show-when-sticky {
      display: inline-block;
    }
  }
}

#mega-menu-wrap-utility.mega-sticky {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 99998;
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  background: transparent;
  opacity: 1;

  #mega-menu-utility {
    background: $white;

    li.mega-menu-item.mega-hide-when-sticky {
      display: none;
    }

    li.mega-menu-item.mega-show-when-sticky {
      display: inline-block;
    }
  }
}

#mega-menu-wrap-primary.mega-stuck {
  @include transition(transform .25s ease-in-out);
}

#mega-menu-wrap-utility.mega-stuck {
  @include transition(transform .25s ease-in-out);
}

#mega-menu-wrap-primary.mega-stuck.mega-hide {
  transform: translateY(-100%);
}

#mega-menu-wrap-utility.mega-stuck.mega-hide {
  transform: translateY(-100%);
}

@media only screen and (min-width: 992px) {
  #mega-menu-wrap-primary {
    background: $white;

    #mega-menu-primary[data-effect="fade"] {
      li.mega-menu-item {
        > ul.mega-sub-menu {
          opacity: 0;
          @include transition(opacity 200ms ease-in, visibility 200ms ease-in, margin-top .15s ease-in-out);
        }
      }

      li.mega-menu-item.mega-toggle-on {
        > ul.mega-sub-menu {
          opacity: 1;
        }
      }

      li.mega-menu-item.mega-menu-megamenu.mega-toggle-on {
        ul.mega-sub-menu {
          opacity: 1;
        }
      }
    }

    #mega-menu-primary[data-effect="fade"].mega-no-js {
      li.mega-menu-item {
        &:hover {
          > ul.mega-sub-menu {
            opacity: 1;
          }
        }

        &:focus {
          > ul.mega-sub-menu {
            opacity: 1;
          }
        }
      }
    }

    #mega-menu-primary[data-effect="fade_up"] {
      li.mega-menu-item.mega-menu-megamenu {
        > ul.mega-sub-menu {
          opacity: 0;
          @include transition(opacity 200ms ease-in, transform 200ms ease-in, visibility 200ms ease-in);
          transform: translate(0, 10px);
        }
      }

      li.mega-menu-item.mega-menu-flyout {
        ul.mega-sub-menu {
          opacity: 0;
          @include transition(opacity 200ms ease-in, transform 200ms ease-in, visibility 200ms ease-in);
          transform: translate(0, 10px);
        }
      }

      li.mega-menu-item.mega-toggle-on {
        > ul.mega-sub-menu {
          opacity: 1;
          transform: translate(0, 0);
        }
      }

      li.mega-menu-item.mega-menu-megamenu.mega-toggle-on {
        ul.mega-sub-menu {
          opacity: 1;
          transform: translate(0, 0);
        }
      }
    }

    #mega-menu-primary[data-effect="fade_up"].mega-no-js {
      li.mega-menu-item {
        &:hover {
          > ul.mega-sub-menu {
            opacity: 1;
            transform: translate(0, 0);
          }
        }

        &:focus {
          > ul.mega-sub-menu {
            opacity: 1;
            transform: translate(0, 0);
          }
        }
      }
    }

    #mega-menu-primary[data-effect="slide_up"] {
      li.mega-menu-item.mega-menu-megamenu {
        > ul.mega-sub-menu {
          @include transition(transform 200ms ease-in, visibility 200ms ease-in);
          transform: translate(0, 10px);
        }
      }

      li.mega-menu-item.mega-menu-flyout {
        ul.mega-sub-menu {
          @include transition(transform 200ms ease-in, visibility 200ms ease-in);
          transform: translate(0, 10px);
        }
      }

      li.mega-menu-item.mega-toggle-on {
        > ul.mega-sub-menu {
          transform: translate(0, 0);
        }
      }

      li.mega-menu-item.mega-menu-megamenu.mega-toggle-on {
        ul.mega-sub-menu {
          transform: translate(0, 0);
        }
      }
    }

    #mega-menu-primary[data-effect="slide_up"].mega-no-js {
      li.mega-menu-item {
        &:hover {
          > ul.mega-sub-menu {
            transform: translate(0, 0);
          }
        }

        &:focus {
          > ul.mega-sub-menu {
            transform: translate(0, 0);
          }
        }
      }
    }

    #mega-menu-primary {
      > li.mega-menu-item.mega-item-align-right {
        margin: 0;
      }

      > li.mega-menu-item.mega-item-align-float-left {
        float: left;
      }

      > li.mega-menu-item {
        > a.mega-menu-link:not(.btn) {
          &:hover {
            font-weight: $font-weight-medium;
            color: $interactive;
            text-decoration: none;
          }
        }
      }

      > li.mega-menu-item.mega-multi-line {
        > a.mega-menu-link:not(.btn) {
          display: table-cell;
          line-height: inherit;
          vertical-align: middle;
        }
      }

      li.mega-menu-megamenu {
        > ul.mega-sub-menu {
          > li.mega-menu-row {
            > ul.mega-sub-menu {
              display: flex;

              > li.mega-menu-columns-1-of-1 {
                width: 100%;
              }

              > li.mega-menu-columns-1-of-2 {
                width: 50%;
              }

              > li.mega-menu-columns-2-of-2 {
                width: 100%;
              }

              > li.mega-menu-columns-1-of-3 {
                width: 33.33333%;
              }

              > li.mega-menu-columns-2-of-3 {
                width: 66.66667%;
              }

              > li.mega-menu-columns-3-of-3 {
                width: 100%;
              }

              > li.mega-menu-columns-1-of-4 {
                width: 25%;
              }

              > li.mega-menu-columns-2-of-4 {
                width: 50%;
              }

              > li.mega-menu-columns-3-of-4 {
                width: 75%;
              }

              > li.mega-menu-columns-4-of-4 {
                width: 100%;
              }

              > li.mega-menu-columns-1-of-5 {
                width: 20%;
              }

              > li.mega-menu-columns-2-of-5 {
                width: 40%;
              }

              > li.mega-menu-columns-3-of-5 {
                width: 60%;
              }

              > li.mega-menu-columns-4-of-5 {
                width: 80%;
              }

              > li.mega-menu-columns-5-of-5 {
                width: 100%;
              }

              > li.mega-menu-columns-1-of-6 {
                width: 16.66667%;
              }

              > li.mega-menu-columns-2-of-6 {
                width: 33.33333%;
              }

              > li.mega-menu-columns-3-of-6 {
                width: 50%;
              }

              > li.mega-menu-columns-4-of-6 {
                width: 66.66667%;
              }

              > li.mega-menu-columns-5-of-6 {
                width: 83.33333%;
              }

              > li.mega-menu-columns-6-of-6 {
                width: 100%;
              }

              > li.mega-menu-columns-1-of-7 {
                width: 14.28571%;
              }

              > li.mega-menu-columns-2-of-7 {
                width: 28.57143%;
              }

              > li.mega-menu-columns-3-of-7 {
                width: 42.85714%;
              }

              > li.mega-menu-columns-4-of-7 {
                width: 57.14286%;
              }

              > li.mega-menu-columns-5-of-7 {
                width: 71.42857%;
              }

              > li.mega-menu-columns-6-of-7 {
                width: 85.71429%;
              }

              > li.mega-menu-columns-7-of-7 {
                width: 100%;
              }

              > li.mega-menu-columns-1-of-8 {
                width: 12.5%;
              }

              > li.mega-menu-columns-2-of-8 {
                width: 25%;
              }

              > li.mega-menu-columns-3-of-8 {
                width: 37.5%;
              }

              > li.mega-menu-columns-4-of-8 {
                width: 50%;
              }

              > li.mega-menu-columns-5-of-8 {
                width: 62.5%;
              }

              > li.mega-menu-columns-6-of-8 {
                width: 75%;
              }

              > li.mega-menu-columns-7-of-8 {
                width: 87.5%;
              }

              > li.mega-menu-columns-8-of-8 {
                width: 100%;
              }

              > li.mega-menu-columns-1-of-9 {
                width: 11.11111%;
              }

              > li.mega-menu-columns-2-of-9 {
                width: 22.22222%;
              }

              > li.mega-menu-columns-3-of-9 {
                width: 33.33333%;
              }

              > li.mega-menu-columns-4-of-9 {
                width: 44.44444%;
              }

              > li.mega-menu-columns-5-of-9 {
                width: 55.55556%;
              }

              > li.mega-menu-columns-6-of-9 {
                width: 66.66667%;
              }

              > li.mega-menu-columns-7-of-9 {
                width: 77.77778%;
              }

              > li.mega-menu-columns-8-of-9 {
                width: 88.88889%;
              }

              > li.mega-menu-columns-9-of-9 {
                width: 100%;
              }

              > li.mega-menu-columns-1-of-10 {
                width: 10%;
              }

              > li.mega-menu-columns-2-of-10 {
                width: 20%;
              }

              > li.mega-menu-columns-3-of-10 {
                width: 30%;
              }

              > li.mega-menu-columns-4-of-10 {
                width: 40%;
              }

              > li.mega-menu-columns-5-of-10 {
                width: 50%;
              }

              > li.mega-menu-columns-6-of-10 {
                width: 60%;
              }

              > li.mega-menu-columns-7-of-10 {
                width: 70%;
              }

              > li.mega-menu-columns-8-of-10 {
                width: 80%;
              }

              > li.mega-menu-columns-9-of-10 {
                width: 90%;
              }

              > li.mega-menu-columns-10-of-10 {
                width: 100%;
              }

              > li.mega-menu-columns-1-of-11 {
                width: 9.09091%;
              }

              > li.mega-menu-columns-2-of-11 {
                width: 18.18182%;
              }

              > li.mega-menu-columns-3-of-11 {
                width: 27.27273%;
              }

              > li.mega-menu-columns-4-of-11 {
                width: 36.36364%;
              }

              > li.mega-menu-columns-5-of-11 {
                width: 45.45455%;
              }

              > li.mega-menu-columns-6-of-11 {
                width: 54.54545%;
              }

              > li.mega-menu-columns-7-of-11 {
                width: 63.63636%;
              }

              > li.mega-menu-columns-8-of-11 {
                width: 72.72727%;
              }

              > li.mega-menu-columns-9-of-11 {
                width: 81.81818%;
              }

              > li.mega-menu-columns-10-of-11 {
                width: 90.90909%;
              }

              > li.mega-menu-columns-11-of-11 {
                width: 100%;
              }

              > li.mega-menu-columns-1-of-12 {
                width: 8.33333%;
              }

              > li.mega-menu-columns-2-of-12 {
                width: 16.66667%;
              }

              > li.mega-menu-columns-3-of-12 {
                width: 25%;
              }

              > li.mega-menu-columns-4-of-12 {
                width: 33.33333%;
              }

              > li.mega-menu-columns-5-of-12 {
                width: 41.66667%;
              }

              > li.mega-menu-columns-6-of-12 {
                width: 50%;
              }

              > li.mega-menu-columns-7-of-12 {
                width: 58.33333%;
              }

              > li.mega-menu-columns-8-of-12 {
                width: 66.66667%;
              }

              > li.mega-menu-columns-9-of-12 {
                width: 75%;
              }

              > li.mega-menu-columns-10-of-12 {
                width: 83.33333%;
              }

              > li.mega-menu-columns-11-of-12 {
                width: 91.66667%;
              }

              > li.mega-menu-columns-12-of-12 {
                width: 100%;
              }
            }
          }
        }
      }

      > li.mega-menu-megamenu {
        > ul.mega-sub-menu {
          li.mega-menu-columns-1-of-1 {
            width: 100%;
          }

          li.mega-menu-columns-1-of-2 {
            width: 50%;
          }

          li.mega-menu-columns-2-of-2 {
            width: 100%;
          }

          li.mega-menu-columns-1-of-3 {
            width: 33.33333%;
          }

          li.mega-menu-columns-2-of-3 {
            width: 66.66667%;
          }

          li.mega-menu-columns-3-of-3 {
            width: 100%;
          }

          li.mega-menu-columns-1-of-4 {
            width: 25%;
          }

          li.mega-menu-columns-2-of-4 {
            width: 50%;
          }

          li.mega-menu-columns-3-of-4 {
            width: 75%;
          }

          li.mega-menu-columns-4-of-4 {
            width: 100%;
          }

          li.mega-menu-columns-1-of-5 {
            width: 20%;
          }

          li.mega-menu-columns-2-of-5 {
            width: 40%;
          }

          li.mega-menu-columns-3-of-5 {
            width: 60%;
          }

          li.mega-menu-columns-4-of-5 {
            width: 80%;
          }

          li.mega-menu-columns-5-of-5 {
            width: 100%;
          }

          li.mega-menu-columns-1-of-6 {
            width: 16.66667%;
          }

          li.mega-menu-columns-2-of-6 {
            width: 33.33333%;
          }

          li.mega-menu-columns-3-of-6 {
            width: 50%;
          }

          li.mega-menu-columns-4-of-6 {
            width: 66.66667%;
          }

          li.mega-menu-columns-5-of-6 {
            width: 83.33333%;
          }

          li.mega-menu-columns-6-of-6 {
            width: 100%;
          }

          li.mega-menu-columns-1-of-7 {
            width: 14.28571%;
          }

          li.mega-menu-columns-2-of-7 {
            width: 28.57143%;
          }

          li.mega-menu-columns-3-of-7 {
            width: 42.85714%;
          }

          li.mega-menu-columns-4-of-7 {
            width: 57.14286%;
          }

          li.mega-menu-columns-5-of-7 {
            width: 71.42857%;
          }

          li.mega-menu-columns-6-of-7 {
            width: 85.71429%;
          }

          li.mega-menu-columns-7-of-7 {
            width: 100%;
          }

          li.mega-menu-columns-1-of-8 {
            width: 12.5%;
          }

          li.mega-menu-columns-2-of-8 {
            width: 25%;
            @include media-breakpoint-down(xl) {
              width: 50%;
            }
          }

          li.mega-menu-columns-3-of-8 {
            width: 37.5%;
          }

          li.mega-menu-columns-4-of-8 {
            width: 50%;
          }

          li.mega-menu-columns-5-of-8 {
            width: 62.5%;
          }

          li.mega-menu-columns-6-of-8 {
            width: 75%;
          }

          li.mega-menu-columns-7-of-8 {
            width: 87.5%;
          }

          li.mega-menu-columns-8-of-8 {
            width: 100%;
          }

          li.mega-menu-columns-1-of-9 {
            width: 11.11111%;
          }

          li.mega-menu-columns-2-of-9 {
            width: 22.22222%;
          }

          li.mega-menu-columns-3-of-9 {
            width: 33.33333%;
          }

          li.mega-menu-columns-4-of-9 {
            width: 44.44444%;
          }

          li.mega-menu-columns-5-of-9 {
            width: 55.55556%;
          }

          li.mega-menu-columns-6-of-9 {
            width: 66.66667%;
          }

          li.mega-menu-columns-7-of-9 {
            width: 77.77778%;
          }

          li.mega-menu-columns-8-of-9 {
            width: 88.88889%;
          }

          li.mega-menu-columns-9-of-9 {
            width: 100%;
          }

          li.mega-menu-columns-1-of-10 {
            width: 10%;
          }

          li.mega-menu-columns-2-of-10 {
            width: 20%;
          }

          li.mega-menu-columns-3-of-10 {
            width: 30%;
          }

          li.mega-menu-columns-4-of-10 {
            width: 40%;
          }

          li.mega-menu-columns-5-of-10 {
            width: 50%;
          }

          li.mega-menu-columns-6-of-10 {
            width: 60%;
          }

          li.mega-menu-columns-7-of-10 {
            width: 70%;
          }

          li.mega-menu-columns-8-of-10 {
            width: 80%;
          }

          li.mega-menu-columns-9-of-10 {
            width: 90%;
          }

          li.mega-menu-columns-10-of-10 {
            width: 100%;
          }

          li.mega-menu-columns-1-of-11 {
            width: 9.09091%;
          }

          li.mega-menu-columns-2-of-11 {
            width: 18.18182%;
          }

          li.mega-menu-columns-3-of-11 {
            width: 27.27273%;
          }

          li.mega-menu-columns-4-of-11 {
            width: 36.36364%;
          }

          li.mega-menu-columns-5-of-11 {
            width: 45.45455%;
          }

          li.mega-menu-columns-6-of-11 {
            width: 54.54545%;
          }

          li.mega-menu-columns-7-of-11 {
            width: 63.63636%;
          }

          li.mega-menu-columns-8-of-11 {
            width: 72.72727%;
          }

          li.mega-menu-columns-9-of-11 {
            width: 81.81818%;
          }

          li.mega-menu-columns-10-of-11 {
            width: 90.90909%;
          }

          li.mega-menu-columns-11-of-11 {
            width: 100%;
          }

          li.mega-menu-columns-1-of-12 {
            width: 8.33333%;
          }

          li.mega-menu-columns-2-of-12 {
            width: 16.66667%;
          }

          li.mega-menu-columns-3-of-12 {
            width: 25%;
          }

          li.mega-menu-columns-4-of-12 {
            width: 33.33333%;
          }

          li.mega-menu-columns-5-of-12 {
            width: 41.66667%;
          }

          li.mega-menu-columns-6-of-12 {
            width: 50%;
          }

          li.mega-menu-columns-7-of-12 {
            width: 58.33333%;
          }

          li.mega-menu-columns-8-of-12 {
            width: 66.66667%;
          }

          li.mega-menu-columns-9-of-12 {
            width: 75%;
          }

          li.mega-menu-columns-10-of-12 {
            width: 83.33333%;
          }

          li.mega-menu-columns-11-of-12 {
            width: 91.66667%;
          }

          li.mega-menu-columns-12-of-12 {
            width: 100%;
          }

          > li.mega-hide-on-desktop {
            display: none;
          }

          li.mega-menu-column {
            > ul.mega-sub-menu {
              > li.mega-menu-item.mega-hide-on-desktop {
                display: none;
              }
            }
          }
        }
      }

      li.mega-menu-flyout {
        li.mega-menu-item {
          a.mega-menu-link:not(.btn) {
            > span.mega-indicator {
              &::after {
                content: "\f345";
              }
            }
          }
        }
      }

      li.mega-menu-flyout.mega-align-bottom-right {
        li.mega-menu-item {
          a.mega-menu-link:not(.btn) {
            text-align: right;

            > span.mega-indicator {
              float: left;

              &::after {
                margin: 0 $spacer * .375 0 0;
                content: "\f341";
              }
            }

            &::before {
              float: right;
              margin: 0 0 0 $spacer * .375;
            }
          }
        }

        ul.mega-sub-menu {
          li.mega-menu-item {
            ul.mega-sub-menu {
              top: 0;
              left: -100%;
            }
          }
        }
      }

      li.mega-hide-on-desktop {
        display: none;
      }

      li#mega-menu-item-111.mega-icon-top {
        > a.mega-menu-link:not(.btn) {
          &::before {
            width: 100%;
            min-width: 24px;
            background-position-x: center;
          }
        }
      }

      li#mega-menu-item-120.mega-icon-top {
        > a.mega-menu-link:not(.btn) {
          &::before {
            width: 100%;
            min-width: 24px;
            background-position-x: center;
          }
        }
      }

      li#mega-menu-item-119.mega-icon-top {
        > a.mega-menu-link:not(.btn) {
          &::before {
            width: 100%;
            min-width: 24px;
            background-position-x: center;
          }
        }
      }

      li#mega-menu-item-112.mega-icon-top {
        > a.mega-menu-link:not(.btn) {
          &::before {
            width: 100%;
            min-width: 24px;
            background-position-x: center;
          }
        }
      }

      span.mega-menu-badge.mega-hide-on-desktop {
        display: none;
      }
    }

    #mega-menu-primary.mega-menu-accordion {
      > li.mega-menu-item-has-children {
        > a.mega-menu-link:not(.btn) {
          > span.mega-indicator {
            float: right;
          }
        }
      }

      > li.mega-menu-item.mega-hide-on-desktop {
        display: none;
      }

      li.mega-menu-item-has-children {
        > a.mega-menu-link:not(.btn) {
          > span.mega-indicator {
            float: right;
          }
        }
      }
    }

    #mega-menu-primary.mega-menu-vertical {
      > li.mega-menu-item.mega-hide-on-desktop {
        display: none;
      }

      > li.mega-menu-megamenu {
        > ul.mega-sub-menu {
          width: 600px;
        }
      }

      li.mega-align-bottom-right.mega-menu-item-has-children {
        li.mega-menu-item {
          a.mega-menu-link:not(.btn) {
            text-align: left;
          }
        }

        > a.mega-menu-link:not(.btn) {
          > span.mega-indicator {
            float: right;

            &::after {
              margin: 0;
              content: "\f345";
            }
          }
        }
      }

      li.mega-align-bottom-right.mega-menu-flyout {
        li.mega-menu-item-has-children {
          li.mega-menu-item {
            a.mega-menu-link:not(.btn) {
              text-align: left;
            }
          }

          > a.mega-menu-link:not(.btn) {
            > span.mega-indicator {
              float: right;

              &::after {
                margin: 0;
                content: "\f345";
              }
            }
          }
        }
      }

      li.mega-align-bottom-left.mega-menu-item-has-children {
        > a.mega-menu-link:not(.btn) {
          text-align: right;

          > span.mega-indicator {
            float: left;

            &::after {
              margin: 0;
              content: "\f341";
            }
          }
        }
      }

      li.mega-align-bottom-left.mega-menu-flyout {
        li.mega-menu-item-has-children {
          > a.mega-menu-link:not(.btn) {
            text-align: right;

            > span.mega-indicator {
              float: left;

              &::after {
                margin: 0;
                content: "\f341";
              }
            }
          }
        }

        li.mega-menu-item-has-children.mega-icon-left {
          > a.mega-menu-link:not(.btn) {
            &::before {
              float: right;
              margin: 0 0 0 $spacer * .375;
            }
          }
        }
      }

      li.mega-align-bottom-left.mega-menu-item-has-children.mega-icon-left {
        > a.mega-menu-link:not(.btn) {
          &::before {
            float: right;
            margin: 0 0 0 $spacer * .375;
          }
        }
      }
    }
  }

  #mega-menu-wrap-utility {
    background: $white;

    #mega-menu-utility[data-effect="fade"] {
      li.mega-menu-item {
        > ul.mega-sub-menu {
          opacity: 0;
          @include transition(opacity 200ms ease-in, visibility 200ms ease-in, margin-top .15s ease-in-out);
        }
      }

      li.mega-menu-item.mega-toggle-on {
        > ul.mega-sub-menu {
          opacity: 1;
        }
      }

      li.mega-menu-item.mega-menu-megamenu.mega-toggle-on {
        ul.mega-sub-menu {
          opacity: 1;
        }
      }
    }

    #mega-menu-utility[data-effect="fade"].mega-no-js {
      li.mega-menu-item {
        &:hover {
          > ul.mega-sub-menu {
            opacity: 1;
          }
        }

        &:focus {
          > ul.mega-sub-menu {
            opacity: 1;
          }
        }
      }
    }

    #mega-menu-utility[data-effect="fade_up"] {
      li.mega-menu-item.mega-menu-megamenu {
        > ul.mega-sub-menu {
          opacity: 0;
          @include transition(opacity 200ms ease-in, transform 200ms ease-in, visibility 200ms ease-in);
          transform: translate(0, 10px);
        }
      }

      li.mega-menu-item.mega-menu-flyout {
        ul.mega-sub-menu {
          opacity: 0;
          @include transition(opacity 200ms ease-in, transform 200ms ease-in, visibility 200ms ease-in);
          transform: translate(0, 10px);
        }
      }

      li.mega-menu-item.mega-toggle-on {
        > ul.mega-sub-menu {
          opacity: 1;
          transform: translate(0, 0);
        }
      }

      li.mega-menu-item.mega-menu-megamenu.mega-toggle-on {
        ul.mega-sub-menu {
          opacity: 1;
          transform: translate(0, 0);
        }
      }
    }

    #mega-menu-utility[data-effect="fade_up"].mega-no-js {
      li.mega-menu-item {
        &:hover {
          > ul.mega-sub-menu {
            opacity: 1;
            transform: translate(0, 0);
          }
        }

        &:focus {
          > ul.mega-sub-menu {
            opacity: 1;
            transform: translate(0, 0);
          }
        }
      }
    }

    #mega-menu-utility[data-effect="slide_up"] {
      li.mega-menu-item.mega-menu-megamenu {
        > ul.mega-sub-menu {
          @include transition(transform 200ms ease-in, visibility 200ms ease-in);
          transform: translate(0, 10px);
        }
      }

      li.mega-menu-item.mega-menu-flyout {
        ul.mega-sub-menu {
          @include transition(transform 200ms ease-in, visibility 200ms ease-in);
          transform: translate(0, 10px);
        }
      }

      li.mega-menu-item.mega-toggle-on {
        > ul.mega-sub-menu {
          transform: translate(0, 0);
        }
      }

      li.mega-menu-item.mega-menu-megamenu.mega-toggle-on {
        ul.mega-sub-menu {
          transform: translate(0, 0);
        }
      }
    }

    #mega-menu-utility[data-effect="slide_up"].mega-no-js {
      li.mega-menu-item {
        &:hover {
          > ul.mega-sub-menu {
            transform: translate(0, 0);
          }
        }

        &:focus {
          > ul.mega-sub-menu {
            transform: translate(0, 0);
          }
        }
      }
    }

    #mega-menu-utility {
      > li.mega-menu-item.mega-item-align-right {
        margin: 0;
      }

      > li.mega-menu-item.mega-item-align-float-left {
        float: left;
      }

      > li.mega-menu-item.mega-multi-line {
        > a.mega-menu-link:not(.btn) {
          display: table-cell;
          line-height: inherit;
          vertical-align: middle;
        }
      }

      li.mega-menu-megamenu {
        > ul.mega-sub-menu {
          > li.mega-menu-row {
            > ul.mega-sub-menu {
              display: flex;

              > li.mega-menu-columns-1-of-1 {
                width: 100%;
              }

              > li.mega-menu-columns-1-of-2 {
                width: 50%;
              }

              > li.mega-menu-columns-2-of-2 {
                width: 100%;
              }

              > li.mega-menu-columns-1-of-3 {
                width: 33.33333%;
              }

              > li.mega-menu-columns-2-of-3 {
                width: 66.66667%;
              }

              > li.mega-menu-columns-3-of-3 {
                width: 100%;
              }

              > li.mega-menu-columns-1-of-4 {
                width: 25%;
              }

              > li.mega-menu-columns-2-of-4 {
                width: 50%;
              }

              > li.mega-menu-columns-3-of-4 {
                width: 75%;
              }

              > li.mega-menu-columns-4-of-4 {
                width: 100%;
              }

              > li.mega-menu-columns-1-of-5 {
                width: 20%;
              }

              > li.mega-menu-columns-2-of-5 {
                width: 40%;
              }

              > li.mega-menu-columns-3-of-5 {
                width: 60%;
              }

              > li.mega-menu-columns-4-of-5 {
                width: 80%;
              }

              > li.mega-menu-columns-5-of-5 {
                width: 100%;
              }

              > li.mega-menu-columns-1-of-6 {
                width: 16.66667%;
              }

              > li.mega-menu-columns-2-of-6 {
                width: 33.33333%;
              }

              > li.mega-menu-columns-3-of-6 {
                width: 50%;
              }

              > li.mega-menu-columns-4-of-6 {
                width: 66.66667%;
              }

              > li.mega-menu-columns-5-of-6 {
                width: 83.33333%;
              }

              > li.mega-menu-columns-6-of-6 {
                width: 100%;
              }

              > li.mega-menu-columns-1-of-7 {
                width: 14.28571%;
              }

              > li.mega-menu-columns-2-of-7 {
                width: 28.57143%;
              }

              > li.mega-menu-columns-3-of-7 {
                width: 42.85714%;
              }

              > li.mega-menu-columns-4-of-7 {
                width: 57.14286%;
              }

              > li.mega-menu-columns-5-of-7 {
                width: 71.42857%;
              }

              > li.mega-menu-columns-6-of-7 {
                width: 85.71429%;
              }

              > li.mega-menu-columns-7-of-7 {
                width: 100%;
              }

              > li.mega-menu-columns-1-of-8 {
                width: 12.5%;
              }

              > li.mega-menu-columns-2-of-8 {
                width: 25%;
              }

              > li.mega-menu-columns-3-of-8 {
                width: 37.5%;
              }

              > li.mega-menu-columns-4-of-8 {
                width: 50%;
              }

              > li.mega-menu-columns-5-of-8 {
                width: 62.5%;
              }

              > li.mega-menu-columns-6-of-8 {
                width: 75%;
              }

              > li.mega-menu-columns-7-of-8 {
                width: 87.5%;
              }

              > li.mega-menu-columns-8-of-8 {
                width: 100%;
              }

              > li.mega-menu-columns-1-of-9 {
                width: 11.11111%;
              }

              > li.mega-menu-columns-2-of-9 {
                width: 22.22222%;
              }

              > li.mega-menu-columns-3-of-9 {
                width: 33.33333%;
              }

              > li.mega-menu-columns-4-of-9 {
                width: 44.44444%;
              }

              > li.mega-menu-columns-5-of-9 {
                width: 55.55556%;
              }

              > li.mega-menu-columns-6-of-9 {
                width: 66.66667%;
              }

              > li.mega-menu-columns-7-of-9 {
                width: 77.77778%;
              }

              > li.mega-menu-columns-8-of-9 {
                width: 88.88889%;
              }

              > li.mega-menu-columns-9-of-9 {
                width: 100%;
              }

              > li.mega-menu-columns-1-of-10 {
                width: 10%;
              }

              > li.mega-menu-columns-2-of-10 {
                width: 20%;
              }

              > li.mega-menu-columns-3-of-10 {
                width: 30%;
              }

              > li.mega-menu-columns-4-of-10 {
                width: 40%;
              }

              > li.mega-menu-columns-5-of-10 {
                width: 50%;
              }

              > li.mega-menu-columns-6-of-10 {
                width: 60%;
              }

              > li.mega-menu-columns-7-of-10 {
                width: 70%;
              }

              > li.mega-menu-columns-8-of-10 {
                width: 80%;
              }

              > li.mega-menu-columns-9-of-10 {
                width: 90%;
              }

              > li.mega-menu-columns-10-of-10 {
                width: 100%;
              }

              > li.mega-menu-columns-1-of-11 {
                width: 9.09091%;
              }

              > li.mega-menu-columns-2-of-11 {
                width: 18.18182%;
              }

              > li.mega-menu-columns-3-of-11 {
                width: 27.27273%;
              }

              > li.mega-menu-columns-4-of-11 {
                width: 36.36364%;
              }

              > li.mega-menu-columns-5-of-11 {
                width: 45.45455%;
              }

              > li.mega-menu-columns-6-of-11 {
                width: 54.54545%;
              }

              > li.mega-menu-columns-7-of-11 {
                width: 63.63636%;
              }

              > li.mega-menu-columns-8-of-11 {
                width: 72.72727%;
              }

              > li.mega-menu-columns-9-of-11 {
                width: 81.81818%;
              }

              > li.mega-menu-columns-10-of-11 {
                width: 90.90909%;
              }

              > li.mega-menu-columns-11-of-11 {
                width: 100%;
              }

              > li.mega-menu-columns-1-of-12 {
                width: 8.33333%;
              }

              > li.mega-menu-columns-2-of-12 {
                width: 16.66667%;
              }

              > li.mega-menu-columns-3-of-12 {
                width: 25%;
              }

              > li.mega-menu-columns-4-of-12 {
                width: 33.33333%;
              }

              > li.mega-menu-columns-5-of-12 {
                width: 41.66667%;
              }

              > li.mega-menu-columns-6-of-12 {
                width: 50%;
              }

              > li.mega-menu-columns-7-of-12 {
                width: 58.33333%;
              }

              > li.mega-menu-columns-8-of-12 {
                width: 66.66667%;
              }

              > li.mega-menu-columns-9-of-12 {
                width: 75%;
              }

              > li.mega-menu-columns-10-of-12 {
                width: 83.33333%;
              }

              > li.mega-menu-columns-11-of-12 {
                width: 91.66667%;
              }

              > li.mega-menu-columns-12-of-12 {
                width: 100%;
              }
            }
          }
        }
      }

      > li.mega-menu-megamenu {
        > ul.mega-sub-menu {
          li.mega-menu-columns-1-of-1 {
            width: 100%;
          }

          li.mega-menu-columns-1-of-2 {
            width: 50%;
          }

          li.mega-menu-columns-2-of-2 {
            width: 100%;
          }

          li.mega-menu-columns-1-of-3 {
            width: 33.33333%;
          }

          li.mega-menu-columns-2-of-3 {
            width: 66.66667%;
          }

          li.mega-menu-columns-3-of-3 {
            width: 100%;
          }

          li.mega-menu-columns-1-of-4 {
            width: 25%;
          }

          li.mega-menu-columns-2-of-4 {
            width: 50%;
          }

          li.mega-menu-columns-3-of-4 {
            width: 75%;
          }

          li.mega-menu-columns-4-of-4 {
            width: 100%;
          }

          li.mega-menu-columns-1-of-5 {
            width: 20%;
          }

          li.mega-menu-columns-2-of-5 {
            width: 40%;
          }

          li.mega-menu-columns-3-of-5 {
            width: 60%;
          }

          li.mega-menu-columns-4-of-5 {
            width: 80%;
          }

          li.mega-menu-columns-5-of-5 {
            width: 100%;
          }

          li.mega-menu-columns-1-of-6 {
            width: 16.66667%;
          }

          li.mega-menu-columns-2-of-6 {
            width: 33.33333%;
          }

          li.mega-menu-columns-3-of-6 {
            width: 50%;
          }

          li.mega-menu-columns-4-of-6 {
            width: 66.66667%;
          }

          li.mega-menu-columns-5-of-6 {
            width: 83.33333%;
          }

          li.mega-menu-columns-6-of-6 {
            width: 100%;
          }

          li.mega-menu-columns-1-of-7 {
            width: 14.28571%;
          }

          li.mega-menu-columns-2-of-7 {
            width: 28.57143%;
          }

          li.mega-menu-columns-3-of-7 {
            width: 42.85714%;
          }

          li.mega-menu-columns-4-of-7 {
            width: 57.14286%;
          }

          li.mega-menu-columns-5-of-7 {
            width: 71.42857%;
          }

          li.mega-menu-columns-6-of-7 {
            width: 85.71429%;
          }

          li.mega-menu-columns-7-of-7 {
            width: 100%;
          }

          li.mega-menu-columns-1-of-8 {
            width: 12.5%;
          }

          li.mega-menu-columns-2-of-8 {
            width: 25%;
            @include media-breakpoint-down(xl) {
              width: 50%;
            }
          }

          li.mega-menu-columns-3-of-8 {
            width: 37.5%;
          }

          li.mega-menu-columns-4-of-8 {
            width: 50%;
          }

          li.mega-menu-columns-5-of-8 {
            width: 62.5%;
          }

          li.mega-menu-columns-6-of-8 {
            width: 75%;
          }

          li.mega-menu-columns-7-of-8 {
            width: 87.5%;
          }

          li.mega-menu-columns-8-of-8 {
            width: 100%;
          }

          li.mega-menu-columns-1-of-9 {
            width: 11.11111%;
          }

          li.mega-menu-columns-2-of-9 {
            width: 22.22222%;
          }

          li.mega-menu-columns-3-of-9 {
            width: 33.33333%;
          }

          li.mega-menu-columns-4-of-9 {
            width: 44.44444%;
          }

          li.mega-menu-columns-5-of-9 {
            width: 55.55556%;
          }

          li.mega-menu-columns-6-of-9 {
            width: 66.66667%;
          }

          li.mega-menu-columns-7-of-9 {
            width: 77.77778%;
          }

          li.mega-menu-columns-8-of-9 {
            width: 88.88889%;
          }

          li.mega-menu-columns-9-of-9 {
            width: 100%;
          }

          li.mega-menu-columns-1-of-10 {
            width: 10%;
          }

          li.mega-menu-columns-2-of-10 {
            width: 20%;
          }

          li.mega-menu-columns-3-of-10 {
            width: 30%;
          }

          li.mega-menu-columns-4-of-10 {
            width: 40%;
          }

          li.mega-menu-columns-5-of-10 {
            width: 50%;
          }

          li.mega-menu-columns-6-of-10 {
            width: 60%;
          }

          li.mega-menu-columns-7-of-10 {
            width: 70%;
          }

          li.mega-menu-columns-8-of-10 {
            width: 80%;
          }

          li.mega-menu-columns-9-of-10 {
            width: 90%;
          }

          li.mega-menu-columns-10-of-10 {
            width: 100%;
          }

          li.mega-menu-columns-1-of-11 {
            width: 9.09091%;
          }

          li.mega-menu-columns-2-of-11 {
            width: 18.18182%;
          }

          li.mega-menu-columns-3-of-11 {
            width: 27.27273%;
          }

          li.mega-menu-columns-4-of-11 {
            width: 36.36364%;
          }

          li.mega-menu-columns-5-of-11 {
            width: 45.45455%;
          }

          li.mega-menu-columns-6-of-11 {
            width: 54.54545%;
          }

          li.mega-menu-columns-7-of-11 {
            width: 63.63636%;
          }

          li.mega-menu-columns-8-of-11 {
            width: 72.72727%;
          }

          li.mega-menu-columns-9-of-11 {
            width: 81.81818%;
          }

          li.mega-menu-columns-10-of-11 {
            width: 90.90909%;
          }

          li.mega-menu-columns-11-of-11 {
            width: 100%;
          }

          li.mega-menu-columns-1-of-12 {
            width: 8.33333%;
          }

          li.mega-menu-columns-2-of-12 {
            width: 16.66667%;
          }

          li.mega-menu-columns-3-of-12 {
            width: 25%;
          }

          li.mega-menu-columns-4-of-12 {
            width: 33.33333%;
          }

          li.mega-menu-columns-5-of-12 {
            width: 41.66667%;
          }

          li.mega-menu-columns-6-of-12 {
            width: 50%;
          }

          li.mega-menu-columns-7-of-12 {
            width: 58.33333%;
          }

          li.mega-menu-columns-8-of-12 {
            width: 66.66667%;
          }

          li.mega-menu-columns-9-of-12 {
            width: 75%;
          }

          li.mega-menu-columns-10-of-12 {
            width: 83.33333%;
          }

          li.mega-menu-columns-11-of-12 {
            width: 91.66667%;
          }

          li.mega-menu-columns-12-of-12 {
            width: 100%;
          }

          > li.mega-hide-on-desktop {
            display: none;
          }

          li.mega-menu-column {
            > ul.mega-sub-menu {
              > li.mega-menu-item.mega-hide-on-desktop {
                display: none;
              }
            }
          }
        }
      }

      li.mega-menu-flyout {
        li.mega-menu-item {
          a.mega-menu-link:not(.btn) {
            > span.mega-indicator {
              &::after {
                content: "\f345";
              }
            }
          }
        }
      }

      li.mega-menu-flyout.mega-align-bottom-right {
        li.mega-menu-item {
          a.mega-menu-link:not(.btn) {
            text-align: right;

            > span.mega-indicator {
              float: left;

              &::after {
                margin: 0 $spacer * .375 0 0;
                content: "\f341";
              }
            }

            &::before {
              float: right;
              margin: 0 0 0 $spacer * .375;
            }
          }
        }

        ul.mega-sub-menu {
          li.mega-menu-item {
            ul.mega-sub-menu {
              top: 0;
              left: -100%;
            }
          }
        }
      }

      li.mega-hide-on-desktop {
        display: none;
      }

      li#mega-menu-item-111.mega-icon-top {
        > a.mega-menu-link:not(.btn) {
          &::before {
            width: 100%;
            min-width: 24px;
            background-position-x: center;
          }
        }
      }

      li#mega-menu-item-120.mega-icon-top {
        > a.mega-menu-link:not(.btn) {
          &::before {
            width: 100%;
            min-width: 24px;
            background-position-x: center;
          }
        }
      }

      li#mega-menu-item-119.mega-icon-top {
        > a.mega-menu-link:not(.btn) {
          &::before {
            width: 100%;
            min-width: 24px;
            background-position-x: center;
          }
        }
      }

      li#mega-menu-item-112.mega-icon-top {
        > a.mega-menu-link:not(.btn) {
          &::before {
            width: 100%;
            min-width: 24px;
            background-position-x: center;
          }
        }
      }

      span.mega-menu-badge.mega-hide-on-desktop {
        display: none;
      }
    }

    #mega-menu-utility.mega-menu-accordion {
      > li.mega-menu-item-has-children {
        > a.mega-menu-link:not(.btn) {
          > span.mega-indicator {
            float: right;
          }
        }
      }

      > li.mega-menu-item.mega-hide-on-desktop {
        display: none;
      }

      li.mega-menu-item-has-children {
        > a.mega-menu-link:not(.btn) {
          > span.mega-indicator {
            float: right;
          }
        }
      }
    }

    #mega-menu-utility.mega-menu-vertical {
      > li.mega-menu-item.mega-hide-on-desktop {
        display: none;
      }

      > li.mega-menu-megamenu {
        > ul.mega-sub-menu {
          width: 600px;
        }
      }

      li.mega-align-bottom-right.mega-menu-item-has-children {
        li.mega-menu-item {
          a.mega-menu-link:not(.btn) {
            text-align: left;
          }
        }

        > a.mega-menu-link:not(.btn) {
          > span.mega-indicator {
            float: right;

            &::after {
              margin: 0;
              content: "\f345";
            }
          }
        }
      }

      li.mega-align-bottom-right.mega-menu-flyout {
        li.mega-menu-item-has-children {
          li.mega-menu-item {
            a.mega-menu-link:not(.btn) {
              text-align: left;
            }
          }

          > a.mega-menu-link:not(.btn) {
            > span.mega-indicator {
              float: right;

              &::after {
                margin: 0;
                content: "\f345";
              }
            }
          }
        }
      }

      li.mega-align-bottom-left.mega-menu-item-has-children {
        > a.mega-menu-link:not(.btn) {
          text-align: right;

          > span.mega-indicator {
            float: left;

            &::after {
              margin: 0;
              content: "\f341";
            }
          }
        }
      }

      li.mega-align-bottom-left.mega-menu-flyout {
        li.mega-menu-item-has-children {
          > a.mega-menu-link:not(.btn) {
            text-align: right;

            > span.mega-indicator {
              float: left;

              &::after {
                margin: 0;
                content: "\f341";
              }
            }
          }
        }

        li.mega-menu-item-has-children.mega-icon-left {
          > a.mega-menu-link:not(.btn) {
            &::before {
              float: right;
              margin: 0 0 0 $spacer * .375;
            }
          }
        }
      }

      li.mega-align-bottom-left.mega-menu-item-has-children.mega-icon-left {
        > a.mega-menu-link:not(.btn) {
          &::before {
            float: right;
            margin: 0 0 0 $spacer * .375;
          }
        }
      }
    }
  }
}

@include media-breakpoint-down(lg) {
  #mega-menu-wrap-primary.mega-keyboard-navigation {
    > li.mega-menu-item {
      > a.mega-menu-link:not(.btn) {
        &:focus {
          color: $white;
          background: #333;
        }
      }
    }
  }

  #mega-menu-wrap-primary {
    #mega-menu-primary {
      > li.mega-menu-item.mega-icon-top {
        > a.mega-menu-link:not(.btn) {
          display: block;
          line-height: 40px;

          &::before {
            display: inline-block;
            margin: 0 $spacer * .375 0 0;
            text-align: left;
          }
        }
      }

      li.mega-menu-item {
        > ul.mega-sub-menu {
          display: none;
          visibility: visible;
          opacity: 1;

          .mega-menu-item {
            &:first-child {
              > a.mega-menu-link:not(.btn) {
                padding-top: 0;
              }
            }

            > a.mega-menu-link:not(.btn) {
              padding: rfs-value(20px) 0;
            }
          }
        }
      }

      li.mega-login-button {
        > ul.mega-sub-menu {
          .mega-menu-item {
            border-bottom: 0 !important;

            &:last-child {
              border-top: rfs-value(1px) solid $dove-400;
            }

            > a.mega-menu-link:not(.btn) {
              display: block;
            }
          }
        }
      }

      li.mega-menu-item.mega-toggle-on {
        > ul.mega-sub-menu {
          display: block;
        }
      }

      li.mega-menu-megamenu.mega-menu-item.mega-toggle-on {
        ul.mega-sub-menu {
          display: block;
          margin-top: 0;
          box-shadow: none;
        }

        li.mega-hide-sub-menu-on-mobile {
          > ul.mega-sub-menu {
            display: none;
          }
        }
      }

      li.mega-hide-sub-menu-on-mobile {
        > ul.mega-sub-menu {
          display: none;
        }
      }

      > li.mega-menu-item.mega-toggle-on {
        > a.mega-menu-link:not(.btn) {
          color: $interactive;
          @include transition(color .15s ease-in-out);
        }
      }

      > li.mega-menu-item.mega-current-menu-item {
        > a.mega-menu-link:not(.btn) {
          color: $interactive;
          @include transition(color .15s ease-in-out);
        }
      }

      > li.mega-menu-item.mega-current-menu-ancestor {
        > a.mega-menu-link:not(.btn) {
          color: $interactive;
          @include transition(color .15s ease-in-out);
        }
      }

      > li.mega-menu-item.mega-multi-line {
        > a.mega-menu-link:not(.btn) {
          br {
            display: none;
          }
        }
      }

      > li.mega-menu-item {
        &:not(.btn) {
          display: list-item;
          margin: 0;
          clear: both;
          border: 0;

          > a.mega-menu-link:not(.btn) {
            @include border-radius(0);
            display: block;
            width: 100%;
            margin: 0;
            font-size: rfs-value(24px);
            line-height: 1.33em;
            color: $primary;
            text-align: left;
            background: transparent;
            border: 0;
            @include padding($spacer * 1.875);
            @include transition(all .15s ease-in-out);

            &:hover {
              color: $interactive;
            }
          }
        }

        &.mega-btn {
          display: flex;
          @include margin($spacer * .625 $spacer * 1.5 $spacer * 1.5 $spacer * 1.5);
          &:last-child {
            margin-top: 0;
            margin-bottom: $spacer * 3;
          }

          a {
            display: block;
            width: 100%;
            padding: 0 !important;
            color: unset !important;
            text-align: center;
          }
        }
      }

      > li.mega-menu-item.mega-item-align-right {
        float: none;
      }

      li.mega-menu-megamenu {
        > ul.mega-sub-menu {
          > li.mega-menu-row {
            > ul.mega-sub-menu {
              > li.mega-menu-column {
                width: 100%;
                clear: both;
              }
            }
          }
        }
      }

      > li.mega-menu-megamenu {
        > ul.mega-sub-menu {
          position: static;
          float: left;
          width: 100%;
          border: 0;
          @include padding($spacer * .625 $spacer * 1.875);
          @include border-radius(0);

          > li.mega-menu-item {
            width: 100%;
            clear: both;
            border-right: 0 !important;
            border-bottom: rfs-value(1px) solid $dove-400;

            &:last-child {
              border-bottom: none;
            }
          }

          > li.mega-hide-on-mobile {
            display: none;
          }

          li.mega-menu-column {
            > ul.mega-sub-menu {
              > li.mega-menu-item.mega-hide-on-mobile {
                display: none;
              }
            }
          }
        }
      }

      > li.mega-menu-flyout {
        ul.mega-sub-menu {
          position: static;
          float: left;
          width: 100%;
          padding: 0;
          border: 0;
          @include border-radius(0);

          li.mega-menu-item {
            clear: both;

            &:first-child {
              > a.mega-menu-link:not(.btn) {
                @include border-top-radius(0);
              }
            }

            &:last-child {
              > a.mega-menu-link:not(.btn) {
                @include border-end-radius(0);
              }
            }

            ul.mega-sub-menu {
              position: static;
              left: 0;
              width: 100%;

              a.mega-menu-link:not(.btn) {
                padding-left: 20px;
              }

              ul.mega-sub-menu {
                a.mega-menu-link:not(.btn) {
                  padding-left: 30px;
                }
              }
            }
          }
        }
      }

      li.mega-menu-item-has-children {
        > a.mega-menu-link:not(.btn) {
          > span.mega-indicator {
            float: right;
          }
        }
      }

      li.mega-menu-item-has-children.mega-hide-sub-menu-on-mobile {
        > a.mega-menu-link:not(.btn) {
          > span.mega-indicator {
            display: none;
          }
        }
      }

      li.mega-hide-on-mobile {
        display: none;
      }

      .mega-sub-menu {
        li#mega-menu-item-111.mega-icon-top {
          > a.mega-menu-link:not(.btn) {
            &::before {
              width: 100%;
              min-width: 24px;
              background-position-x: center;
            }
          }
        }

        li#mega-menu-item-120.mega-icon-top {
          > a.mega-menu-link:not(.btn) {
            &::before {
              width: 100%;
              min-width: 24px;
              background-position-x: center;
            }
          }
        }

        li#mega-menu-item-119.mega-icon-top {
          > a.mega-menu-link:not(.btn) {
            &::before {
              width: 100%;
              min-width: 24px;
              background-position-x: center;
            }
          }
        }

        li#mega-menu-item-112.mega-icon-top {
          > a.mega-menu-link:not(.btn) {
            &::before {
              width: 100%;
              min-width: 24px;
              background-position-x: center;
            }
          }
        }
      }

      li#mega-menu-item-111 {
        > a.mega-menu-link:not(.btn) {
          &::before {
            width: 24px;
            height: 24px;
            line-height: 24px;
            background-size: 24px 24px;
          }
        }
      }

      li#mega-menu-item-120 {
        > a.mega-menu-link:not(.btn) {
          &::before {
            width: 24px;
            height: 24px;
            line-height: 24px;
            background-size: 24px 24px;
          }
        }
      }

      li#mega-menu-item-119 {
        > a.mega-menu-link:not(.btn) {
          &::before {
            width: 24px;
            height: 24px;
            line-height: 24px;
            background-size: 24px 24px;
          }
        }
      }

      li#mega-menu-item-112 {
        > a.mega-menu-link:not(.btn) {
          &::before {
            width: 24px;
            height: 24px;
            line-height: 24px;
            background-size: 24px 24px;
          }
        }
      }

      li.mega-bp-profile-nav {
        img.avatar {
          width: auto;
          max-height: 90%;
        }
      }

      //stylelint-disable-next-line selector-class-pattern
      img.mmm_gravatar {
        width: auto;
        max-height: 90%;
      }

      li.mega-menu-item.mega-menu-megamenu.mega-menu-tabbed {
        ul.mega-sub-menu {
          ul.mega-sub-menu {
            display: none;

            li.mega-collapse-children {
              > ul.mega-sub-menu {
                display: none;
              }
            }

            ul.mega-sub-menu {
              display: block;
            }

            li.mega-collapse-children.mega-toggle-on {
              > ul.mega-sub-menu {
                display: block;
              }
            }
          }
        }

        li.mega-menu-item.mega-toggle-on {
          > ul.mega-sub-menu {
            display: block;

            ul.mega-sub-menu {
              display: block;
            }
          }
        }
      }

      li.mega-menu-item.mega-menu-megamenu.mega-menu-tabbed.mega-toggle-on {
        > ul.mega-sub-menu {
          display: block;
        }
      }

      > li.mega-menu-tabbed {
        > ul.mega-sub-menu {
          padding: 0;

          > li.mega-menu-item {
            > ul.mega-sub-menu {
              > li.mega-menu-item {
                width: 100%;
                clear: both;
              }

              position: relative;
              top: 0;
              left: auto;

              width: 100%;
              padding: 10px;
              border-left: 0;
            }

            position: static;

            width: 100%;
            padding: 0;

            > a.mega-menu-link:not(.btn) {
              float: left;
              width: 100%;
              padding: 0 10px;
              font-family: $font-family1;
              font-size: 14px;
              font-weight: 400;
              line-height: 35px;
              color: $color1;
              text-decoration: none;
              text-transform: none;
              background: $white;
            }

            ul.mega-sub-menu {
              li.mega-menu-item {
                ul.mega-sub-menu {
                  li.mega-menu-item {
                    ul.mega-sub-menu {
                      margin-left: 0;
                    }
                  }
                }
              }
            }
          }

          > li.mega-menu-item.mega-menu-item-has-children {
            > a.mega-menu-link:not(.btn) {
              > span.mega-indicator {
                &::after {
                  content: "\f347";
                }
              }
            }
          }

          > li.mega-menu-item.mega-menu-item-has-children.mega-toggle-on {
            > a.mega-menu-link:not(.btn) {
              > span.mega-indicator {
                &::after {
                  content: "\f343";
                }
              }
            }
          }
        }
      }

      span.mega-menu-badge.mega-hide-on-mobile {
        display: none;
      }
    }

    &::after {
      display: table;
      clear: both;
      content: "";
    }

    .mega-menu-toggle {
      display: -webkit-box;
      display: flex;

      + {
        #mega-menu-primary {
          position: absolute;
          z-index: -1;
          display: none;
          width: 100%;
          max-height: 700px;
          padding: 0;
          margin-top: $spacer * .5;
          overflow: auto;
          background: $white;
          @include transition(margin-top .15s ease-in-out);
          @include box-shadow($box-shadow-sm);
        }
      }
    }

    .mega-menu-toggle.mega-menu-open {
      + {
        #mega-menu-primary {
          display: block;
        }
      }
    }

    #mega-menu-primary.mega-menu-vertical {
      > li.mega-menu-item.mega-hide-on-mobile {
        display: none;
      }
    }

    #mega-menu-primary.mega-menu-accordion {
      > li.mega-menu-item.mega-hide-on-mobile {
        display: none;
      }
    }

    li.mega-menu-item {
      img.mega-menu-logo {
        width: auto;
        max-height: 90%;
      }
    }

    #mega-menu-primary[data-effect-mobile^="slide_"] {
      li.mega-menu-item {
        a.mega-menu-link.mega-menu-logo {
          height: auto;
          padding-top: 10px;
          padding-bottom: 10px;
          text-align: center;
        }
      }
    }
  }

  #mega-menu-wrap-utility {
    display: none;
  }

  #mega-menu-wrap-primary.mega-sticky {
    #mega-menu-primary {
      background: #222;

      &:not([data-effect-mobile^="slide_"]) {
        //stylelint-disable-next-line function-disallowed-list
        max-height: calc(100vh - 40px);
        overflow: auto;
      }

      li.mega-menu-item.mega-show-when-sticky.mega-hide-on-mobile {
        display: none;
      }
    }
  }

  body.admin-bar {
    #mega-menu-wrap-primary.mega-sticky {
      #mega-menu-primary {
        &:not([data-effect-mobile^="slide_"]) {
          //stylelint-disable-next-line function-disallowed-list
          max-height: calc(100vh - 40px - 42px);
        }
      }
    }

    #mega-menu-wrap-utility.mega-sticky {
      #mega-menu-utility {
        &:not([data-effect-mobile^="slide_"]) {
          //stylelint-disable-next-line function-disallowed-list
          max-height: calc(100vh - 40px - 42px);
        }
      }
    }
  }
}

/* Apply Hover Styling to active Mega Menu - Second Level Links */
#mega-menu-wrap-primary #mega-menu-primary > li.mega-menu-megamenu > ul.mega-sub-menu > li.mega-menu-item.mega-current-menu-item > a.mega-menu-link:not(.btn),
#mega-menu-wrap-primary #mega-menu-primary > li.mega-menu-megamenu > ul.mega-sub-menu li.mega-menu-column > ul.mega-sub-menu > li.mega-menu-item.mega-current-menu-item > a.mega-menu-link:not(.btn) {
  color: $interactive;
  //font-weight: $panel_second_level_font_weight_hover;
  //text-decoration: $panel_second_level_text_decoration_hover;
  //@include background($panel_second_level_background_hover_from, $panel_second_level_background_hover_to);
}

/* Apply Hover Styling to active Mega Menu - Third Level Links */
#mega-menu-wrap-primary #mega-menu-primary > li.mega-menu-megamenu > ul.mega-sub-menu > li.mega-menu-item li.mega-menu-item.mega-current-menu-item > a.mega-menu-link:not(.btn),
#mega-menu-wrap-primary #mega-menu-primary > li.mega-menu-megamenu > ul.mega-sub-menu li.mega-menu-column > ul.mega-sub-menu > li.mega-menu-item li.mega-menu-item.mega-current-menu-item > a.mega-menu-link:not(.btn) {
  color: $interactive;
  //font-weight: $panel_third_level_font_weight_hover;
  //text-decoration: $panel_third_level_text_decoration_hover;
  //@include background($panel_third_level_background_hover_from, $panel_third_level_background_hover_to);
}

/* Apply Hover Styling to active Flyout Links and ancestors */
#mega-menu-wrap-primary #mega-menu-primary li.mega-menu-flyout ul.mega-sub-menu li.mega-menu-item.mega-current-menu-item > a.mega-menu-link:not(.btn),
#mega-menu-wrap-primary #mega-menu-primary li.mega-menu-flyout ul.mega-sub-menu li.mega-menu-item.mega-current-menu-ancestor > a.mega-menu-link:not(.btn),
#mega-menu-wrap-primary #mega-menu-primary li.mega-menu-flyout ul.mega-sub-menu li.mega-menu-item.mega-current-page-ancestor > a.mega-menu-link:not(.btn) {
  //@include background($flyout_background_hover_from, $flyout_background_hover_to);
  //font-weight: $flyout_link_weight_hover;
  //text-decoration: $flyout_link_text_decoration_hover;
  color: $interactive;
}
.mega-pre-nav {
  position: relative !important;
  background-color: $denim;
  a {
    width: fit-content !important;
    padding: $spacer * .875 0 !important;
    margin: 0 $spacer * 1.5 !important;
    font-size: $font-size-base !important;
    color: $white !important;
    border-bottom: rfs-value(6px) solid $electric !important;
    .mega-indicator {
      position: absolute !important;
      right: 24px;
      &::after {
        color: $white !important;
      }
    }
  }
  ul {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    background-color: $denim !important;
    li {
      border-bottom: 0 !important;
      a {
        display: block;
        width: fit-content !important;
        padding: $spacer * .75 0 !important;
        margin: 0 !important;
        font-weight: $font-weight-normal !important;
        border-color: transparent !important;
        @include transition(border-color .15s ease-in-out);
        &:hover {
          border-color: $electric !important;
        }
      }
    }
  }
}
#utility-menu {
  display: flex;
  align-items: center;
  .login {
    margin-right: $spacer;

    button {
      @include transition(all .15s ease-in-out);
      &::before {
        width: 24px;
        height: 24px;
        margin-right: ($spacer * .5);
        font-family: $fa-style-family;
        font-size: rfs-value(22px);
        font-weight: $font-weight-normal;
        line-height: 24px;
        color: $interactive;
        text-align: center;
        vertical-align: top;
        content: fa-content($fa-var-circle-user);
        @extend %fa-icon;
        @extend .fa-regular;
        @include transition(color .15s ease-in-out);
      }

      &::after {
        display: none;
      }

      &.show {
        &::before {
          color: $white;
        }
      }
    }

    .dropdown-menu {
      width: max-content;
      padding: $spacer * .5;
      border: 1px solid $gray-100;
      @include border-radius($border-radius-lg);
      @include box-shadow($box-shadow-sm);
      li {
        a {
          @extend .fs-4;
          display: block;
          padding: $spacer * .625 $spacer;
          color: $denim;
          text-align: left;
          text-decoration: none;
          @include transition(color .15s ease-in-out, background-color .15s ease-in-out);
          @include border-radius($border-radius-sm);

          &:hover {
            color: $interactive;
            background-color: $interactive-50;
          }
        }

        &.border-top {
          border-color: $gray-100;
        }

        &.separate {
          padding-top: $spacer * .5;
          margin-top: $spacer * .5;
        }
      }
    }
    .menu-item {
      a.btn {
        @include transition(all .15s ease-in-out);
      }
    }
  }
}

#pre-nav {
  padding: 0;
  ul {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0;
    margin: 0;
    color: $white;
    list-style: none;
    li {
      display: inline-block;
      @include margin-right($spacer * 2.5);
      &:last-child {
        margin-right: 0;
      }
      &.active {
        a {
          font-weight: $font-weight-bold;
          border-bottom: rfs-value(6px) solid $electric;
        }
      }
      a {
        display: block;
        padding: $spacer * .875 0 $spacer * .5;
        font-size: $sub-sup-font-size;
        color: $white;
        text-decoration: none;
        border-bottom: rfs-value(6px) solid transparent;
        @include transition(border-color .15s ease-in-out);
        &:hover {
          border-color: $electric;
        }
      }
      &.phone {
        position: absolute;
        right: 0;
        a {
          padding: 0 !important;
          font-size: $font-size-base !important;
          font-weight: $font-weight-normal !important;
          border: 0 !important;
          span {
            font-weight: $font-weight-medium !important;
            @extend .fs-2;
            color: $electric-400;
          }
        }
      }
    }
  }
}

// MEGA-2256: mobile slider nav
#mega-menu-item-96 {
  position: relative;
  z-index: 2;
  height: 3.375rem;
  height: 54px !important;
  margin-bottom: 1rem !important;
  overflow-y: hidden;
  scroll-behavior: smooth;
}
#mega-menu-wrap-primary #mega-menu-primary #mega-menu-item-96 ul.mega-sub-menu {
  display: flex;
  flex-wrap: nowrap;
  float: none;
  height: 60px;
  padding-right: 1.5rem;
  padding-left: 1.5rem;
  margin-block: 0;
  margin-top: 0 !important;
  overflow-x: auto;
  text-align: center;
  white-space: nowrap;
  list-style: none;
}
#mega-menu-wrap-primary #mega-menu-primary li.mega-menu-item > ul.mega-sub-menu > li.mega-menu-item {
  width: auto;
  margin-right: 1.5rem;
}
#mega-menu-item-96 ul.mega-sub-menu li:first-child a {
  border-bottom: rfs-value(6px) solid $electric !important;
}
li#mega-menu-item-96 ul.mega-sub-menu a.mega-menu-link:not(.btn):hover {
  border-bottom: none !important;
}
@include media-breakpoint-down(lg) {
  #mega-menu-wrap-primary #mega-menu-primary > li.mega-menu-item:not(.btn) > a.mega-menu-link:not(.btn) {
    padding: 1rem 24.39px;
  }
  #mega-menu-wrap-primary #mega-menu-primary > li.mega-menu-item:not(.btn) {
    height: 64px;
  }
  #mega-menu-wrap-primary #mega-menu-primary > li.mega-menu-item.mega-btn {
    margin-top: 16px;
  }
}
