//stylelint-disable selector-max-id
#search-form {
  position: relative;
  width: 100%;
  input {
    width: 100%;
    padding: $spacer $spacer * 4 $spacer $spacer;
    margin-top: 0;
    color: $gray-700;
    background-color: $white;
    border: rfs-value(2px) solid $gray-300;
    @include border-radius($border-radius);

    &::placeholder {
      color: $gray-600;
    }
  }
  //stylelint-disable-next-line selector-no-qualifying-type
  button[type="submit"] {
    position: absolute;
    //stylelint-disable-next-line function-disallowed-list
    top: calc(50% - 8px);
    right: 24px;
    padding: 0;
    line-height: 1;
    background-color: transparent;
    border: 0;
    i {
      font-weight: $font-weight-bold;
      color: $gray-600;
      pointer-events: none;
    }
  }
}
