//stylelint-disable selector-no-qualifying-type
body.page-contact-us {
  .toc-mobile {
    display: none;

    @include media-breakpoint-down(lg) {
      display: block;
      overflow: hidden;
      background-color: $white;
    }

    &.toc-active {
      .contact-table-of-contents {
        opacity: 1;
      }

      .toc-mobile-trigger {
        &::after {
          transform: translateY(-50%) rotate(180deg);
        }
      }
    }

    .toc-mobile-trigger {
      position: relative;
      width: 100%;
      padding: $spacer * 1.25 $spacer * 1.5;
      font-weight: 500;
      line-height: 1;
      color: $black;
      text-align: left;
      text-transform: uppercase;
      background: $white;
      border: 0;
      @extend .fs-3;

      &::after {
        position: absolute;
        top: 50%;
        right: 20px;
        font-family: $fa-style-family;
        @extend .fs-4;
        font-weight: $font-weight-bold;
        color: #8b8b8b;
        content: "\f078";
        @include transition(all .2s ease-in-out);
        transform: translateY(-50%);
      }
    }

    .contact-table-of-contents {
      padding: 0 $spacer * 1.5;

      .toc-headline {
        display: none;
      }

      .toc-menu {
        margin-bottom: $spacer * 1.5;
      }
    }
  }

  .toc-lock {
    //stylelint-disable-next-line declaration-no-important
    overflow: hidden !important;
    touch-action: none;
  }

  .single-post .toc-mobile {
    //stylelint-disable-next-line declaration-no-important
    overflow: hidden !important;
  }

  .toc-desktop {
    height: auto;

    @include media-breakpoint-down(lg) {
      position: fixed;
      top: 999999px;
      left: 999999px;
      visibility: hidden;
    }
  }
  .contact-table-of-contents {
    position: sticky;
    top: 150px;
    width: 100%;
    @include media-breakpoint-down(lg) {
      top: 0;
      padding-bottom: $spacer * 2.5;
    }

    .toc-headline {
      margin-bottom: $spacer * 1.25;
      @extend .fs-2;
      font-weight: $font-weight-bold;
      text-transform: uppercase;
    }

    .toc-menu {
      position: relative;
      padding-left: $spacer * 1.375;
      margin-bottom: 0;
      list-style-type: none;

      &::after {
        position: absolute;
        top: 15px;
        left: 10px;
        z-index: 0;
        width: 2px;
        height: 4px;
        content: "";
        background: $electric;
        @include transition(all .4s ease);
      }

      &.last-elem-active {
        &::after {
          //stylelint-disable-next-line function-disallowed-list
          height: calc(100% + -30px);
        }
      }

      &::before {
        position: absolute;
        top: 50%;
        left: 10px;
        width: 2px;
        //stylelint-disable-next-line function-disallowed-list
        height: calc(100% - 30px);
        content: "";
        background: $gray-100;
        transform: translateY(-50%);
      }

      .progress-line {
        position: absolute;
        top: 15px;
        left: 10px;
        z-index: 1;
        width: 2px;
        height: 0;
        background: $electric;
        @include transition(all .25s ease);

        &::before {
          position: absolute;
          bottom: -10px;
          left: -4px;
          width: 10px;
          height: 30px;
          content: "";
          background-color: $gray-50;
          background-image: url("../images/toc-icon.svg");
          background-repeat: no-repeat;
          background-position: center;
          background-size: contain;
        }
      }

      li {
        position: relative;
        padding: 15px 0;
        line-height: 1;

        &::before {
          position: absolute;
          top: 0;
          left: -10px;
          width: 2px;
          height: 0;
          content: "";
          background: $electric;
          @include transition(all .25s ease);
        }
      }

      a {
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        color: #202020;
        text-decoration: none;
      }
    }
  }
}
