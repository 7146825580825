.newsletter-form {
  padding: $spacer * 1.5 0;

  .newsletter-content {
    padding: $spacer * 3.125 $spacer * 1.5;
    background-color: #d8ecf4;
    @include border-radius($border-radius-sm);

    @include media-breakpoint-down(sm) {
      padding: $spacer * 1.5;
    }

    .header {
      padding-bottom: $spacer * 2.125;
      text-align: center;

      @include media-breakpoint-down(sm) {
        order: 2;
      }

      h2 {
        margin-bottom: $spacer * .875;
        @include font-size(40px);

        @include media-breakpoint-down(sm) {
          @include font-size(32px);
        }
      }

      p {
        margin: 0;
      }

      a {
        width: 175px;
        //stylelint-disable-next-line declaration-no-important
        padding-right: $spacer * .875 !important;
        //stylelint-disable-next-line declaration-no-important
        padding-left: $spacer * .875 !important;
        margin-top: $spacer * .875;
        //stylelint-disable-next-line declaration-no-important
        color: $white !important;
      }
    }

    .form {
      //stylelint-disable-next-line selector-class-pattern
      .gform_wrapper {
        margin: 0;

        //stylelint-disable-next-line selector-max-compound-selectors
        form {
          display: flex;
          gap: $spacer * .875;
          align-items: center;
          justify-content: center;

          @include media-breakpoint-down(sm) {
            flex-direction: column;
            gap: $spacer * .625;
          }

          //stylelint-disable-next-line
          .gform_body {
            width: 80%;

            @include media-breakpoint-down(sm) {
              width: 100%;
            }

            //stylelint-disable-next-line
            .gform_fields {
              display: block;
              //stylelint-disable-next-line declaration-no-important
              margin: 0 !important;

              //stylelint-disable-next-line
              .gfield {
                margin: 0;

                //stylelint-disable-next-line
                input {
                  height: auto;
                  //stylelint-disable-next-line declaration-no-important
                  padding: $spacer * .875 !important;

                  @include media-breakpoint-down(sm) {
                    text-align: center;
                  }
                }

                //stylelint-disable-next-line
                label {
                  display: none;
                }
              }
            }
          }

          //stylelint-disable-next-line
          .gform_footer {
            width: 20%;
            padding: 0;

            @include media-breakpoint-down(sm) {
              width: 100%;
            }

            //stylelint-disable-next-line
            button[type="submit"] {
              width: 100%;
              //stylelint-disable-next-line declaration-no-important
              padding: $spacer * .875 !important;
              //stylelint-disable-next-line declaration-no-important
              color: $white !important;
            }
          }
        }
      }
    }
  }
}
