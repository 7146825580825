.social-holder {
  @include margin-top($spacer * 4.5);
  @include padding-bottom($spacer * 3);
  @include margin-bottom($spacer * 3.5);
  border-bottom: rfs-value(1px) solid $gray-200;
  p {
    display: inline-block;
  }
  .social-links {
    display: inline-block;
    padding-left: $spacer;
    list-style: none;
    li {
      display: inline-block;
      &:not(:last-child) {
        margin-right: $spacer * .5;
      }
      a {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 48px;
        height: 48px;
        text-align: center;
        text-decoration: none;
        background-color: $white;
        @include border-radius($border-radius-pill);
        //stylelint-disable-next-line selector-max-type, selector-max-compound-selectors
        i {
          width: 24px;
          height: 24px;
          font-size: rfs-value(18px);
          line-height: 1.25;
          color: $interactive;
        }
      }
    }
  }
}
