.customer-stories {
  .image-content {
    &.spacer-half {
      @include padding-top($spacer * 3.75);
      @include padding-bottom($spacer * 3.75);
    }
    &.overlap {
      .image-container {
        min-height: 600px;
        max-height: 600px;
        @include media-breakpoint-down(md){
          min-height: 400px;
          max-height: 400px;
        }
      }
    }
  }
}

.article-single {
  @include media-breakpoint-down(lg) {
    @include margin-bottom($spacer * 1.25);
  }

  img {
    width: 100%;
    height: 280px;
    object-fit: cover;
    @include border-top-radius($border-radius-xxl);

    @include media-breakpoint-down(lg) {
      margin-bottom: 0;
    }

    @include media-breakpoint-down(sm) {
      max-height: 150px;
    }
  }
  .post-excerpt {
    @include margin-bottom($spacer * 1.5);
  }
}

.single-stories {
  .pagination {
    @include margin-bottom($spacer * 8);
  }
}


.post-disclaimer {
  .disclaimer {
    width: 100%;
    text-align: center;
    @extend .legal;
    @include media-breakpoint-up(lg) {
      width: 80%;
    }
  }
}

