.multi-col-row {
  &.has-overlap {
    position: relative;
    //stylelint-disable-next-line declaration-no-important
    padding-top: 0 !important;
    margin-top: -$spacer * 1.5;
  }

  .cols {
    flex-direction: row;
    margin-right: 0;
    margin-left: 0;
    background-color: $white;
    @include border-radius($border-radius-xxxl);
    @include padding($spacer * 3 $spacer * 2.5);
    @include box-shadow($box-shadow-xxl);

    .content-col {
      display: flex;
      justify-content: center;
      text-align: center;
      &:not(:last-child) {
        border-right: rfs-value(1px) solid $gray-100;
        @include media-breakpoint-down(lg) {
          border-right: none;
        }
        @include media-breakpoint-down(md) {
          border-bottom: rfs-value(1px) solid $gray-100;
        }
      }
      &:nth-child(odd) {
        @include media-breakpoint-between(lg, xl) {
          border-right: rfs-value(1px) solid $gray-100;
        }
      }
      &:nth-child(even) {
        @include media-breakpoint-between(lg, xl) {
          border-right: none;
        }
      }
      &:nth-child(1),
      &:nth-child(2), {
        @include media-breakpoint-between(md,lg) {
          .content-holder {
            margin-top: 0;
          }
        }
      }
      &:nth-child(3),
      &:nth-child(4) {
        @include media-breakpoint-between(md,lg) {
          border-top: rfs-value(1px) solid $gray-100;
          .content-holder {
            margin-bottom: 0;
          }
        }
      }
      &:first-child {
        @include media-breakpoint-down(md) {
          .content-holder {
            margin-top: 0;
          }
        }
      }
      &:last-child {
        @include media-breakpoint-down(md) {
          .content-holder {
            margin-bottom: 0;
          }
        }
      }
      .content-holder {
        align-items: center;
        @include margin(0 $spacer * 4);
        @include media-breakpoint-down(xxl) {
          @include margin(0 $spacer * 2);
        }
        @include media-breakpoint-down(xl) {
          @include margin($spacer * 2 0);
        }
      }
      .title {
        display: flex;
        align-items: center;
        @include margin-bottom($spacer);
      }
      .icon-container {
        //stylelint-disable-next-line selector-max-compound-selectors
        i {
          font-size: rfs-value(48px);
        }

        //stylelint-disable-next-line selector-max-compound-selectors
        img {
          display: block;
          max-height: 24px;
          margin: 0 auto;
        }
      }

      h4 {
        width: 100%;
        margin-bottom: 0;
        color: $interactive;
        text-align: center;
      }

      p {
        margin-bottom: 0;
        @extend .fs-4;
        font-weight: $font-weight-medium;
      }
    }
  }

  .disclaimer {
    @include padding-top($spacer * 3);
    text-align: center;
    p {
      margin-bottom: 0;
      color: $dove-700;
    }
  }
}
