.custom-pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  .page-link {
    @include border-radius($border-radius-pill);
    padding: rfs-value(3px) rfs-value(8px);
    color: $interactive;
    background-color: transparent;
    @include transition(background-color .2s ease-in-out);
    &:hover {
      background-color: $interactive-100;
    }
    &.next {
      margin-left: $spacer * 1;
    }
    &.prev {
      margin-right: $spacer * 1;
    }
  }
}
