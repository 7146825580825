.get-started-form {
  position: relative;
  //stylelint-disable-next-line declaration-no-important
  padding-top: 0 !important;

  .form-title {
    @include padding($spacer * 3 0 $spacer * 5);
    color: $white;

    h1 {
      color: $white;
      @include margin-bottom($spacer * 1.5);
    }

    p {
      margin-bottom: 0;
    }
  }

  .form-holder {
    position: relative;
    @extend .elevation-1;
    background: $white;
    @include border-radius($border-radius-xxxl);
    @include margin-top($spacer * -1.5);

    //stylelint-disable-next-line selector-class-pattern
    .gform_wrapper,
    .gform-body, {
      margin-bottom: 0;
    }

    //stylelint-disable-next-line selector-class-pattern
    .gform_wrapper {
      @include padding($spacer * 2.5);
    }

    //stylelint-disable-next-line selector-class-pattern
    .gform_heading {
      text-align: center;
      @include padding-bottom($spacer * .5);
    }

    &.confirmation {
      @include margin-top($spacer * 3);
    }

    //stylelint-disable-next-line selector-class-pattern
    .gform_confirmation_wrapper {
      background-color: transparent;

      i {
        padding: $spacer * .75 $spacer * .625 $spacer * .75 $spacer * .875;
        margin: 0;
        font-size: 32px;
        line-height: 1.25;
        @include border-radius($border-radius-pill);
        @include margin-bottom($spacer * 1.5);
      }

      h3 {
        @include margin-bottom($spacer * 1.5);
      }

      p {
        color: $body-color;
      }

      .content {
        width: 83.33333333%;
        margin-right: auto;
        margin-left: auto;
      }

      .modal-link {
        @extend .fs-4;
        color: $body-color;
        @include margin-top($spacer * .5);
        @include margin-bottom($spacer * 1.5);
      }

      .legal {
        margin-bottom: 0;
      }
    }

    //stylelint-disable-next-line selector-class-pattern
    .gform_ajax_spinner {
      position: absolute;
      //stylelint-disable function-disallowed-list
      top: calc(50% - 24px);
      left: calc(50% - 24px);
      //stylelint-enable function-disallowed-list
      margin: 0;
    }
  }

  .form-disclaimer {
    @include make-row();
    justify-content: center;

    label {
      @include make-col(6);
      @include media-breakpoint-down(lg) {
        @include make-col(8);
      }
      @include media-breakpoint-down(md) {
        @include make-col(10);
      }
      @include media-breakpoint-down(sm) {
        @include make-col(12);
      }
    }
    .legal-links {
      @include make-col(12);
    }
    .legal {
      text-align: center;
    }
  }

  //stylelint-disable selector-class-pattern
  .validation-button,
  .gform_validation_errors {
    margin-bottom: $spacer * 1.5;
  }
  //stylelint-enable selector-class-pattern

  //stylelint-disable-next-line selector-class-pattern
  .gform_fields {
    .gfield {
      @extend .mb-4;
    }
  }

  //stylelint-disable-next-line selector-class-pattern
  .gform_heading {
    h2 {
      @include font-size($h1-font-size);
      text-align: center;
      @include margin-bottom($spacer * 1.5);
    }

    //stylelint-disable-next-line selector-class-pattern
    .gform_description {
      @extend .fs-2;
      text-align: center;
      @include margin-bottom($spacer * 2.5);
    }
  }

  .gfield.after {
    @include margin-top($spacer * 2.25);
  }
}

.page-get-started {
  main {
    min-height: 90vh;
  }

  .get-started-form {
    height: 100%;
    //stylelint-disable-next-line declaration-no-important
    margin-top: 0 !important;
  }
}
